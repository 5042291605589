import { AlertColor } from "@mui/material";
import { useRef } from "react";
import MuiNotification, { IMuiNotification } from "../commons/MuiNotification";
import { getDisplayName } from "../ComponentHelper";

export interface IToast {
    openToast: (message: string, template: AlertColor) => void,
    closeToast: () => void
};

function withToast(WrappedComponent: any) {
    function WithToast(props: any) {
        // Refs
        const myToast = useRef<IMuiNotification>(null);
    
        // Handlers
        const toastIsReady = () => {
            return myToast !== null && myToast.current !== null;
        };
    
        const openToast = (message: string, template: AlertColor) => {
            if (toastIsReady()) myToast.current!.open(message, template);
        };
    
        const closeToast = () => {
            if (toastIsReady()) myToast.current!.close();
        };

        const render_toast = () => {
            return <MuiNotification ref={myToast}/>
        };
        
        return (
            <>
                <WrappedComponent {...props} openToast={openToast} closeToast={closeToast} />
                {render_toast()}
            </>
        );
    }
  
    WithToast.displayName = `withToast(${getDisplayName(WrappedComponent)})`;

    return WithToast;
}

export {withToast};