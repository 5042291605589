import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Branche } from "./Branche";
import { MetadonneeDepartement } from "./MetadonneeDepartement";
import { Personnel } from "./Personnel";

export interface IDepartement extends IBase {
    id: number;
    branche_id: number;
    departement_id: number;
    configuration_id: number;
    nom: string;

    branche: Branche;
    branche_name: string;
    pays_name: string;

    metadonnees: MetadonneeDepartement[];
    
    personnels: Personnel[];
    personnels_name: string;
}

export class Departement extends Base implements IDepartement {
    [key: string]: any;
    
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    branche_id: number = 0;
    
    @JsonProperty()
    departement_id: number = 0;
    
    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    branche!: Branche;
    branche_name: string = "";
    pays_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeDepartement[] = [];

    @JsonProperty()
    personnels: Personnel[] = [];
    personnels_name: string = "";

    public constructor(init?: Partial<IDepartement>) {
        super();
        Object.assign(this, init);
    }
}
