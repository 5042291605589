import { JsonProperty } from "@paddls/ts-serializer";

export interface IPaysOrganisationPivot {
    pays_id: number;
    tenant_id: number;
}

export class PaysOrganisationPivot implements IPaysOrganisationPivot {
    @JsonProperty()
    pays_id: number = 0;

    @JsonProperty()
    tenant_id: number = 0;

    public constructor(init?: Partial<IPaysOrganisationPivot>) {
        Object.assign(this, init);
    }
}
