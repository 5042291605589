import React from "react";
import MuiLoader, { IMuiLoader } from "../commons/MuiLoader";
import { getDisplayName } from "../ComponentHelper";

export interface ILoader {
    isLoading: () => boolean,
    openLoader: () => void,
    closeLoader: () => void
};

function withLoader<TProps>(WrappedComponent: React.ComponentType<TProps>) {
    function WithLoader(props: TProps) {
        // Refs
        const myLoader = React.useRef<IMuiLoader>(null);
    
        // Handlers
        const loaderIsReady = () => {
            // console.log("LoaderHoc->loaderIsReady");
            return myLoader !== null && myLoader.current !== null;
        };
    
        const isLoading = () => {
            // console.log("LoaderHoc->isLoading");
            return loaderIsReady() && myLoader.current!.isLoading();
        };
    
        const openLoader = () => {
            if (loaderIsReady()) {
                // console.log("LoaderHoc->openLoader");
                myLoader.current!.openLoader();
            }
        };
    
        const closeLoader = () => {
            if (loaderIsReady()) {
                // console.log("LoaderHoc->closeLoader");
                myLoader.current!.closeLoader();
            }
        };

        const render_loader = () => {
            return <MuiLoader ref={myLoader}/>
        };
        
        return (
            <>
                <WrappedComponent {...props} isLoading={isLoading} openLoader={openLoader} closeLoader={closeLoader} />
                {render_loader()}
            </>
        );
    }
  
    WithLoader.displayName = `withLoader(${getDisplayName(WrappedComponent)})`;

    return WithLoader;
}

export {withLoader};