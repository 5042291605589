import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from "./Base";

export interface IDashboard extends IBase {
    [key: string]: any;

    modules: number;
    tenants: number;
    pays: number;
    users: number;
    
    branches: number;
    configurations: number;
    departements: number;
    documents: number;
    dossiers: number;
    metadonnees: number;
    personnels: number;
    profils: number;
}

export class Dashboard extends Base implements IDashboard {
    [key: string]: any;

    @JsonProperty()
    modules: number = 0;

    @JsonProperty()
    tenants: number = 0;

    @JsonProperty()
    pays: number = 0;

    @JsonProperty()
    users: number = 0;
    
    @JsonProperty()
    branches: number = 0;

    @JsonProperty()
    configurations: number = 0;

    @JsonProperty()
    departements: number = 0;

    @JsonProperty()
    documents: number = 0;

    @JsonProperty()
    dossiers: number = 0;

    @JsonProperty()
    metadonnees: number = 0;

    @JsonProperty()
    personnels: number = 0;

    @JsonProperty()
    profils: number = 0;

    public constructor(init?: Partial<IDashboard>) {
        super();
        Object.assign(this, init);
    }
}