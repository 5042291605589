import { Metadonnee } from './Metadonnee';
import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IMetavaleur extends IBase {
    id: number;
    metadonnee_id: number;
    valeur: string;

    metadonnee: Metadonnee;
    metadonnee_name: string;
}

export class Metavaleur extends Base implements IMetavaleur {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    valeur: string = "";

    @JsonProperty()
    metadonnee!: Metadonnee;

    @JsonProperty()
    metadonnee_name: string = "";

    public constructor(init?: Partial<IMetavaleur>) {
        super();
        Object.assign(this, init);
    }
}
