import { JsonProperty } from "@paddls/ts-serializer";

export interface IRoleGroupePivot {
    groupe_id: number;
    role_id: number;
}

export class RoleGroupePivot implements IRoleGroupePivot {
    @JsonProperty()
    groupe_id: number = 0;

    @JsonProperty()
    role_id: number = 0;

    public constructor(init?: Partial<IRoleGroupePivot>) {
        Object.assign(this, init);
    }
}
