import { Denormalizer, Normalizer, Serializer } from "@paddls/ts-serializer";
import _ from "lodash";

import { getMetadonneeKey, metadonneeToModel } from "../components/ConfigurationHelper";

import periodes from "../datas/periodes.json";

import { LoginRequest } from "../models/auth/LoginRequest";
import { LoginResponse } from "../models/auth/LoginResponse";
import { Token } from "../models/auth/Token";

import { Alignement } from './../models/base/Alignement';
import { Civilite } from "../models/base/Civilite";
import { Entite } from "../models/base/Entite";
import { Exception } from "../models/base/Exception";
import { Operation } from "../models/base/Operation";
import { Type } from "../models/base/Type";

import { Breadcrumb } from "../models/Breadcrumb";

import { Action } from "../models/central/Action";
import { Confidentialite } from "../models/central/Confidentialite";
import { Domaine } from "../models/central/Domaine";
import { Metaelement } from "../models/central/Metaelement";
import { Module } from "../models/central/Module";
import { ModuleOrganisation } from "../models/central/ModuleOrganisation";
import { ModuleOrganisationPivot } from "../models/central/ModuleOrganisationPivot";
import { Organisation } from "../models/central/Organisation";
import { Pays } from "../models/central/Pays";
import { PaysOrganisation } from "../models/central/PaysOrganisation";
import { PaysOrganisationPivot } from "../models/central/PaysOrganisationPivot";
import { Ressource } from "../models/central/Ressource";

import { Dashboard } from "../models/Dashboard";
import { FileView } from "../models/FileView";
import { HeaderNotification } from '../models/HeaderNotification';
import { Journal } from "../models/Journal";
import { Notification } from "../models/Notification";
import { Password } from "../models/Password";
import { Recherche } from "../models/Recherche";
import { Utilisateur } from "../models/Utilisateur";

import { Approbation } from "../models/tenant/Approbation";
import { Branche } from "../models/tenant/Branche";
import { Configuration } from "../models/tenant/Configuration";
import { Departement } from "../models/tenant/Departement";
import { Document } from "../models/tenant/Document";
import { Dossier } from "../models/tenant/Dossier";
import { Extension } from "../models/tenant/Extension";
import { Fichier } from "../models/tenant/Fichier";
import { Groupe } from "../models/tenant/Groupe";
import { GroupePersonnel } from "../models/tenant/GroupePersonnel";
import { GroupePersonnelPivot } from "../models/tenant/GroupePersonnelPivot";
import { Metadonnee } from "../models/tenant/Metadonnee";
import { MetadonneeBranche } from "../models/tenant/MetadonneeBranche";
import { MetadonneeBranchePivot } from "../models/tenant/MetadonneeBranchePivot";
import { MetadonneeConfiguration } from "../models/tenant/MetadonneeConfiguration";
import { MetadonneeConfigurationPivot } from "../models/tenant/MetadonneeConfigurationPivot";
import { MetadonneeDepartement } from "../models/tenant/MetadonneeDepartement";
import { MetadonneeDepartementPivot } from "../models/tenant/MetadonneeDepartementPivot";
import { MetadonneeDocument } from "../models/tenant/MetadonneeDocument";
import { MetadonneeDocumentPivot } from "../models/tenant/MetadonneeDocumentPivot";
import { MetadonneeDossier } from "../models/tenant/MetadonneeDossier";
import { MetadonneeDossierPivot } from "../models/tenant/MetadonneeDossierPivot";
import { MetadonneePersonnel } from "../models/tenant/MetadonneePersonnel";
import { MetadonneePersonnelPivot } from "../models/tenant/MetadonneePersonnelPivot";
import { Metavaleur } from "../models/tenant/Metavaleur";
import { Partage } from "../models/tenant/Partage";
import { Perimetre } from "../models/tenant/Perimetre";
import { Personnel } from "../models/tenant/Personnel";
import { PersonnelDocument } from "../models/tenant/PersonnelDocument";
import { PersonnelDocumentPivot } from "../models/tenant/PersonnelDocumentPivot";
import { PersonnelDossier } from "../models/tenant/PersonnelDossier";
import { PersonnelDossierPivot } from "../models/tenant/PersonnelDossierPivot";
import { PersonnelWorkflow } from "../models/tenant/PersonnelWorkflow";
import { PersonnelWorkflowConfiguration } from "../models/tenant/PersonnelWorkflowConfiguration";
import { PersonnelWorkflowConfigurationPivot } from "../models/tenant/PersonnelWorkflowConfigurationPivot";
import { PersonnelWorkflowPivot } from "../models/tenant/PersonnelWorkflowPivot";
import { Profil } from "../models/tenant/Profil";
import { Role } from "../models/tenant/Role";
import { RoleGroupe } from "../models/tenant/RoleGroupe";
import { RoleGroupePivot } from "../models/tenant/RoleGroupePivot";
import { RoleProfil } from "../models/tenant/RoleProfil";
import { RoleProfilPivot } from "../models/tenant/RoleProfilPivot";
import { Serveur } from "../models/tenant/Serveur";
import { Terminologie } from "../models/tenant/Terminologie";
import { Version } from "../models/tenant/Version";
import { Workflow } from "../models/tenant/Workflow";
import { WorkflowActivite } from "../models/tenant/WorkflowActivite";
import { WorkflowConfiguration } from "../models/tenant/WorkflowConfiguration";
import { WorkflowType } from "../models/tenant/WorkflowType";

import Enumeration from "../utils/Enumeration";
import ModelName from "../utils/ModelName";
import { getAuthUser } from "./AuthorizationHelper";
import { dateToString, formatDateToDuration } from "./InputHelpers";
import { anyToBoolean, formatBytes, stringToNumber } from "./NumberHelper";
import { trans } from "./TransHelper";

type TActivite = PersonnelWorkflowPivot | WorkflowActivite;
type TPersonnel = Personnel | PersonnelDocument | PersonnelDossier | PersonnelWorkflow | PersonnelWorkflowConfiguration;

const serializer: Serializer = new Serializer(new Normalizer(), new Denormalizer());

function deserializeArray<T>(object: any[], name: string) {
    let result = new Array<T>();
    object.forEach(element => {
        result.push(createObject(element, name))
    });
    return result;
}

function deserializeActivite(activite: TActivite, now: Date) {
    activite.is_expired = now.getTime() > activite.delai.getTime();
            
    activite.delai_label = dateToString(activite.delai);

    if (activite.statut === Enumeration.STATUT_APPROUVE) {
        activite.is_approved = true;
        activite.statut_label = trans('approved');
    }
    else if (activite.statut === Enumeration.STATUT_ATTENTE) {
        activite.is_waiting = true;
        activite.statut_label = trans('waiting');
    }
    else if (activite.statut === Enumeration.STATUT_CONSULTE) {
        activite.is_consulted = true;
        activite.statut_label = trans('consulted');
    }
    else if (activite.statut === Enumeration.STATUT_REJETE) {
        activite.is_rejected = true;
        activite.statut_label = trans('rejected');
    }
    else {
        activite.statut_label = activite.is_expired ? trans('expired') : "";
    }

    if (activite.workflow) {
        activite.workflow = deserialize(activite.workflow, ModelName.WORKFLOW);
    }

    if (activite.personnel) {
        activite.personnel = deserialize(activite.personnel, ModelName.PERSONNEL);
    }
}

function deserializePersonnel(personnel: TPersonnel) {
    if (personnel.departement) {
        personnel.departement = deserialize(personnel.departement, ModelName.DEPARTEMENT);
        personnel.departement_name = personnel.departement.nom;
        personnel.branche_name = personnel.departement.branche_name;
        personnel.pays_name = personnel.departement.pays_name;
    }
    
    if (personnel.profil) {
        personnel.profil = deserialize(personnel.profil, ModelName.PROFIL);
        personnel.profil_name = personnel.profil.nom;
        personnel.profil_code = personnel.profil.code;
    }
    
    if (personnel.user) {
        personnel.user = deserialize(personnel.user, ModelName.UTILISATEUR);
        personnel.nom = personnel.user.nom;
        personnel.prenom = personnel.user.prenom;
        personnel.email = personnel.user.email;
        personnel.actif = personnel.user.actif;
        personnel.statut = personnel.user.statut;
        personnel.fullname = personnel.user.fullname;
    }

    if (personnel.groupes) {
        personnel.groupes = deserialize(personnel.groupes, ModelName.GROUPE);

        personnel.groupes_ids = [];
        if (personnel.groupes && personnel.groupes.length > 0) {
            personnel.groupes.forEach((groupe) => {
                personnel.groupes_ids.push(groupe.id);

                if (personnel.groupes_name) {
                    personnel.groupes_name = `${personnel.groupes_name} - ${groupe.nom}`;
                }
                else {
                    personnel.groupes_name = `${groupe.nom}`
                }
            })
        }
    }

    if (personnel.metadonnees) {
        personnel.metadonnees = deserialize(personnel.metadonnees, ModelName.METADONNEE_PERSONNEL);

        if (personnel.metadonnees && personnel.metadonnees.length > 0) { 
            personnel.metadonnees.forEach((metadonnee) => {
                const key = getMetadonneeKey(metadonnee);
                personnel[key] = "";
                if (metadonnee.metaelement) {
                    personnel[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                }
            });
        }
    }

    if (personnel.approbations) {
        personnel.approbations = deserialize(personnel.approbations, ModelName.APPROBATION);
    }

    personnel.is_admin = personnel.profil_code === Enumeration.PROFIL_ADMIN;
}

export function createObject(object: any, name: string) {
    const now = new Date();
    const authUser = getAuthUser();
    
    switch (name) {
        // Auth
        case ModelName.LOGIN_REQUEST:
            return serializer.deserialize<LoginRequest>(LoginRequest, object);
        case ModelName.LOGIN_RESPONSE:
            let loginResponse = serializer.deserialize<LoginResponse>(LoginResponse, object);

            if (loginResponse.token)
                loginResponse.token = deserialize(loginResponse.token, ModelName.TOKEN);

            if (loginResponse.user)
                loginResponse.user = deserialize(loginResponse.user, ModelName.UTILISATEUR);

            if (loginResponse.personnel)
                loginResponse.personnel = deserialize(loginResponse.personnel, ModelName.PERSONNEL);

            if (loginResponse.domains)
                loginResponse.domains = deserialize(loginResponse.domains, ModelName.DOMAINE);

            if (loginResponse.modules)
                loginResponse.modules = deserialize(loginResponse.modules, ModelName.MODULE);

            if (loginResponse.tenants)
                loginResponse.tenants = deserialize(loginResponse.tenants, ModelName.ORGANISATION);

            return loginResponse;
        case ModelName.TOKEN:
            return serializer.deserialize<Token>(Token, object);

        // Base
        case ModelName.ALIGNEMENT:
            return serializer.deserialize<Alignement>(Alignement, object);
        case ModelName.CIVILITE:
            return serializer.deserialize<Civilite>(Civilite, object);
        case ModelName.ENTITE:
            return serializer.deserialize<Entite>(Entite, object);
        case ModelName.EXCEPTION:
            return serializer.deserialize<Exception>(Exception, object);
        case ModelName.OPERATION:
            return serializer.deserialize<Operation>(Operation, object);
        case ModelName.TYPE:
            return serializer.deserialize<Type>(Type, object);

        // Central
        case ModelName.ACTION:
            return serializer.deserialize<Action>(Action, object);
        case ModelName.CONFIDENTIALITE:
            return serializer.deserialize<Confidentialite>(Confidentialite, object);
        case ModelName.DOMAINE:
            return serializer.deserialize<Domaine>(Domaine, object);
        case ModelName.METAELEMENT:
            return serializer.deserialize<Metaelement>(Metaelement, object);
        case ModelName.MODULE:
            return serializer.deserialize<Module>(Module, object);
        case ModelName.MODULE_ORGANISATION:
            let moduleOrganisation = serializer.deserialize<ModuleOrganisation>(ModuleOrganisation, object);

            if (moduleOrganisation.pivot)
                moduleOrganisation.pivot = deserialize(moduleOrganisation.pivot, ModelName.MODULE_ORGANISATION_PIVOT);
    
            return moduleOrganisation;
        case ModelName.MODULE_ORGANISATION_PIVOT:
            return serializer.deserialize<ModuleOrganisationPivot>(ModuleOrganisationPivot, object);
        case ModelName.ORGANISATION:
            let organisation = serializer.deserialize<Organisation>(Organisation, object);
            
            organisation.confidentiel = anyToBoolean(organisation.confidentiel);
        
            if (organisation.domains)
                organisation.domains = deserialize(organisation.domains, ModelName.DOMAINE);

            if (organisation.modules) {
                organisation.modules = deserialize(organisation.modules, ModelName.MODULE);

                organisation.modules_ids = [];
                if (organisation.modules && organisation.modules.length > 0) {
                    organisation.modules.forEach((module) => {
                        organisation.modules_ids.push(module.id);
                    });
                }
            }

            // if (organisation.pays) {
            //     organisation.pays = deserialize(organisation.pays, ModelName.PAYS);

            //     organisation.pays_ids = [];
            //     if (organisation.pays && organisation.pays.length > 0) { 
            //         organisation.pays.forEach((country) => {
            //             organisation.pays_ids.push(country.id);
            //         });
            //     }
            // }

            return organisation;
        case ModelName.PAYS:
            let pays = serializer.deserialize<Pays>(Pays, object);

            pays.actif = anyToBoolean(pays.actif);
            pays.statut = pays.actif ? trans("active") : trans("inactive");

            // if (pays.user)
            //     pays.user = deserialize(pays.user, ModelName.UTILISATEUR);
            
            return pays;
        case ModelName.PAYS_ORGANISATION:
            let paysOrganisation = serializer.deserialize<PaysOrganisation>(PaysOrganisation, object);

            if (paysOrganisation.pivot)
                paysOrganisation.pivot = deserialize(paysOrganisation.pivot, ModelName.PAYS_ORGANISATION_PIVOT);
    
            return paysOrganisation;
        case ModelName.PAYS_ORGANISATION_PIVOT:
            return serializer.deserialize<PaysOrganisationPivot>(PaysOrganisationPivot, object);
        case ModelName.RESSOURCE:
            let ressource = serializer.deserialize<Ressource>(Ressource, object);

            ressource.configurable = anyToBoolean(ressource.configurable);
    
            // if (ressource.user)
            //     ressource.user = deserialize(ressource.user, ModelName.UTILISATEUR);
    
            return ressource;

        // Root
        case ModelName.BREADCRUMB:
            return serializer.deserialize<Breadcrumb>(Breadcrumb, object);
        case ModelName.DASHBOARD:
            return serializer.deserialize<Dashboard>(Dashboard, object);
        case ModelName.HEADER_NOTIFICATION:
            return serializer.deserialize<HeaderNotification>(HeaderNotification, object);
        case ModelName.FILE_VIEW:
            let fileView = serializer.deserialize<FileView>(FileView, object);

            if (fileView.contents) {
                const datas = base64StringToBinary(fileView.contents, false);
                const options = { type: fileView.mimeType };
    
                fileView.file = new File([datas], fileView.nom, options);
                fileView.fileUrl = URL.createObjectURL(fileView.file);
            }
    
            return fileView;
        case ModelName.JOURNAL:
            let journal = serializer.deserialize<Journal>(Journal, object);
        
            if (journal.user) {
                journal.user = deserialize(journal.user, ModelName.UTILISATEUR);
                journal.user_nom = journal.user.nom;
                journal.user_prenom = journal.user.prenom;
                journal.user_fullname = journal.user.fullname;
                journal.user_email = journal.user.email;
            }
    
            return journal;
        case ModelName.NOTIFICATION:
            let notification = serializer.deserialize<Notification>(Notification, object);

            notification.actif = anyToBoolean(notification.actif);

            notification.statut = notification.actif ? trans("active") : trans("inactive");
        
            if (notification.user) {
                notification.user = deserialize(notification.user, ModelName.UTILISATEUR);
                notification.user_name = notification.user.name;
                notification.user_email = notification.user.email;
            }
    
            return notification;
        case ModelName.PASSWORD:
            return serializer.deserialize<Password>(Password, object);
        case ModelName.RECHERCHE:
            let recherche = serializer.deserialize<Recherche>(Recherche, object);

            recherche.infile = anyToBoolean(recherche.infile);
            recherche.interval = anyToBoolean(recherche.interval);
    
            return recherche;
        case ModelName.UTILISATEUR:
            let utilisateur = serializer.deserialize<Utilisateur>(Utilisateur, object);

            utilisateur.actif = anyToBoolean(utilisateur.actif);
            utilisateur.statut = utilisateur.actif ? trans("active") : trans("inactive");

            if (utilisateur.tenant)
                utilisateur.tenant = deserialize(utilisateur.tenant, ModelName.ORGANISATION);
    
            return utilisateur;

        // Tenant
        case ModelName.APPROBATION:
            let approbation = serializer.deserialize<Approbation>(Approbation, object);
    
            approbation.is_expired = now.getTime() > approbation.date_expiration.getTime();
            
            if (approbation.statut === Enumeration.STATUT_APPROUVE) {
                approbation.is_approved = true;
                approbation.statut_label = trans('approved');
            }
            else if (approbation.statut === Enumeration.STATUT_ATTENTE) {
                approbation.is_waiting = true;
                approbation.statut_label = trans('waiting');
            }
            else if (approbation.statut === Enumeration.STATUT_REJETE) {
                approbation.is_rejected = true;
                approbation.statut_label = trans('rejected');
            }
            else {
                approbation.statut_label = approbation.is_expired ? trans('expired') : "";
            }
    
            if (approbation.document) {
                approbation.document = deserialize(approbation.document, ModelName.DOCUMENT);
                approbation.document_name = approbation.document.nom;
            }
    
            if (approbation.personnel) {
                approbation.personnel = deserialize(approbation.personnel, ModelName.PERSONNEL);
                approbation.personnel_name = approbation.personnel.fullname;
            }
    
            if (approbation.roles) {
                approbation.roles = deserialize(approbation.roles, ModelName.ROLE);
    
                approbation.roles_ids = [];
                if (approbation.roles.length > 0) {
                    approbation.roles.forEach((role) => {
                        approbation.roles_ids.push(role.id);
                    });
                }
            }
    
            return approbation;
        case ModelName.BRANCHE:
            let branche = serializer.deserialize<Branche>(Branche, object);

            if (branche.pays) {
                branche.pays = deserialize(branche.pays, ModelName.PAYS);
                branche.pays_name = branche.pays.nom_fr;
            }

            if (branche.metadonnees) {
                branche.metadonnees = deserialize(branche.metadonnees as MetadonneeBranche[], ModelName.METADONNEE_BRANCHE);

                if (branche.metadonnees.length > 0) { 
                    branche.metadonnees.forEach((metadonnee) => {
                        const key = getMetadonneeKey(metadonnee);
                        branche[key] = "";
                        if (metadonnee.metaelement) {
                            branche[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                        }
                    });
                }
            }

            if (branche.departements) {
                branche.departements = deserialize(branche.departements, ModelName.DEPARTEMENT);
                if (branche.departements && branche.departements.length > 0) {
                    branche.departements.forEach((departement) => {
                        if (branche.departements_name) {
                            branche.departements_name = `${branche.departements_name} - ${departement.nom}`;
                        }
                        else {
                            branche.departements_name = `${departement.nom}`
                        }
                    })
                }
            }

            return branche;
        case ModelName.CONFIGURATION:
            let configuration = serializer.deserialize<Configuration>(Configuration, object);
    
            configuration.value = configuration.value ? configuration.value : 0;

            if (configuration.metadonnees) {
                configuration.metadonnees = deserialize(configuration.metadonnees as MetadonneeConfiguration[], ModelName.METADONNEE_CONFIGURATION);
            }
    
            if (configuration.ressource) {
                configuration.ressource = deserialize(configuration.ressource, ModelName.RESSOURCE);
                configuration.ressource_name = configuration.ressource.nom;
            }
    
            if (configuration.configuration) {
                configuration.configuration = deserialize(configuration.configuration, ModelName.CONFIGURATION);
                configuration.configuration_name = configuration.configuration.nom;
            }
    
            if (configuration.configurations) {
                configuration.configurations = deserialize(configuration.configurations, ModelName.CONFIGURATION);
            }
    
            return configuration;
        case ModelName.DEPARTEMENT:
            let departement = serializer.deserialize<Departement>(Departement, object);

            if (departement.branche) {
                departement.branche = deserialize(departement.branche, ModelName.BRANCHE);
                departement.branche_name = departement.branche.nom;
                departement.pays_name = departement.branche.pays_name;
            }
    
            if (departement.metadonnees) {
                departement.metadonnees = deserialize(departement.metadonnees as MetadonneeDepartement[], ModelName.METADONNEE_DEPARTEMENT);
    
                if (departement.metadonnees.length > 0) { 
                    departement.metadonnees.forEach((metadonnee) => {
                        const key = getMetadonneeKey(metadonnee);
                        departement[key] = "";
                        if (metadonnee.metaelement) {
                            departement[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                        }
                    });
                }
            }
    
            if (departement.personnels) {
                departement.personnels = deserialize(departement.personnels, ModelName.PERSONNEL);

                if (departement.personnels && departement.personnels.length > 0) {
                    departement.personnels.forEach((personnel) => {
                        if (departement.personnels_name) {
                            departement.personnels_name = `${departement.personnels_name} - ${personnel.fullname}`;
                        }
                        else {
                            departement.personnels_name = `${personnel.fullname}`
                        }
                    })
                }
            }
    
            return departement;
        case ModelName.DOCUMENT:
            let document = serializer.deserialize<Document>(Document, object);

            document.configuration_id = document.configuration_id ? document.configuration_id : 0;
    
            if (document.version) {
                document.version = deserialize(document.version, ModelName.VERSION);
                
                // document.hash_name = document.version.hash_name;
                // document.extension = document.version.extension;
                // document.size = document.version.size;
                // document.url = document.version.url;
                // document.contents = document.version.contents;

                // document.size_display = document.version.size_display;
            }
    
            if (document.user) {
                document.user = deserialize(document.user, ModelName.UTILISATEUR);
                document.user_name = document.user.fullname;
            }
    
            if (document.personnel) {
                document.personnel = deserialize(document.personnel, ModelName.PERSONNEL);
                document.personnel_name = document.personnel.fullname;
            }
    
            if (document.confidentialite) {
                document.confidentialite = deserialize(document.confidentialite, ModelName.CONFIDENTIALITE);
                document.confidentialite_name = document.confidentialite.nom;
                document.confidentialite_code = document.confidentialite.code;
            }
    
            if (document.dossier) {
                document.dossier = deserialize(document.dossier, ModelName.DOSSIER);
                document.path_id = `${document.dossier.path_id} ${document.dossier.id}`;
                document.path_name = `${document.dossier.path_name}/${document.nom}`;
                document.dossier_name = document.dossier.nom;
            }
    
            if (document.configuration) {
                document.configuration = deserialize(document.configuration, ModelName.CONFIGURATION);
                document.configuration_name = document.configuration.nom;
            }
    
            if (document.metadonnees) {
                document.metadonnees = deserialize(document.metadonnees as MetadonneeDocument[], ModelName.METADONNEE_DOCUMENT);
    
                if (document.metadonnees.length > 0) { 
                    document.metadonnees.forEach((metadonnee) => {
                        const key = getMetadonneeKey(metadonnee);
                        document[key] = "";
                        if (metadonnee.metaelement) {
                            document[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                        }
                    });
                }
            }
    
            if (document.personnels) {
                document.personnels = deserialize(document.personnels, ModelName.PERSONNEL);
    
                document.personnels_ids = [];
                if (document.personnels.length > 0) {
                    document.personnels.forEach((personnel) => {
                        document.personnels_ids.push(personnel.id);
                    });
                }
            }
    
            if (document.profils) {
                document.profils = deserialize(document.profils, ModelName.PROFIL);
    
                document.profils_ids = [];
                if (document.profils.length > 0) {
                    document.profils.forEach((profil) => {
                        document.profils_ids.push(profil.id);
                    });
                }
            }
    
            if (document.groupes) {
                document.groupes = deserialize(document.groupes, ModelName.GROUPE);
    
                document.groupes_ids = [];
                if (document.groupes.length > 0) {
                    document.groupes.forEach((groupe) => {
                        document.groupes_ids.push(groupe.id);
                    });
                }
            }
    
            if (document.versions) {
                document.versions = deserialize(document.versions, ModelName.VERSION);
            }
    
            if (document.partages) {
                document.partages = deserialize(document.partages, ModelName.PARTAGE);
            }
    
            if (document.approbations) {
                document.approbations = deserialize(document.approbations, ModelName.APPROBATION);
            }
    
            if (document.workflows && document.workflows.length > 0) {
                document.workflows = deserialize(document.workflows, ModelName.WORKFLOW);
                const documentWorkflow = document.workflows.find(w => w.statut === Enumeration.STATUT_ENCOURS);
                if (documentWorkflow) {
                    document.workflow_statut_code = documentWorkflow.statut;
                    document.workflow_statut_label = documentWorkflow.statut_label;
                }
                else {
                    document.workflow_statut_code = Enumeration.STATUT_TERMINE;
                    document.workflow_statut_label = trans('finished');
                }
            }
    
            if (authUser) {
                document.is_owner = authUser.id === document.user_id;
            }
    
            document.is_private = document.confidentialite_code === Enumeration.CONFIDENTIALITE_PRIVATE;
            document.is_public = document.confidentialite_code === Enumeration.CONFIDENTIALITE_PUBLIC;
            document.is_shared = document.confidentialite_code === Enumeration.CONFIDENTIALITE_SHARE;
    
            return document;
        case ModelName.DOSSIER:
            let dossier = serializer.deserialize<Dossier>(Dossier, object);

            dossier.configuration_id = dossier.configuration_id ? dossier.configuration_id : 0;
            
            dossier.dossier_id = dossier.dossier_id ? dossier.dossier_id : 0;
    
            if (dossier.user) {
                dossier.user = deserialize(dossier.user, ModelName.UTILISATEUR);
                dossier.user_name = dossier.user.fullname;
            }
    
            if (dossier.personnel) {
                dossier.personnel = deserialize(dossier.personnel, ModelName.PERSONNEL);
                dossier.personnel_name = dossier.personnel.fullname;
            }
    
            if (dossier.confidentialite) {
                dossier.confidentialite = deserialize(dossier.confidentialite, ModelName.CONFIDENTIALITE);
                dossier.confidentialite_name = dossier.confidentialite.nom;
                dossier.confidentialite_code = dossier.confidentialite.code;
            }
    
            if (dossier.dossier) {
                dossier.dossier = deserialize(dossier.dossier, ModelName.DOSSIER);
                dossier.path_id = `${dossier.dossier.path_id} ${dossier.dossier.id}`;
                dossier.path_name = `${dossier.dossier.path_name}/${dossier.nom}`;
                dossier.dossier_name = dossier.dossier.nom;
            }
            else {
                dossier.path_name = `/${dossier.nom}`;
            }
    
            if (dossier.configuration) {
                dossier.configuration = deserialize(dossier.configuration, ModelName.CONFIGURATION);
                dossier.configuration_name = dossier.configuration.nom;
            }
    
            if (dossier.metadonnees) {
                dossier.metadonnees = deserialize(dossier.metadonnees as MetadonneeDossier[], ModelName.METADONNEE_DOSSIER);
    
                if (dossier.metadonnees.length > 0) { 
                    dossier.metadonnees.forEach((metadonnee) => {
                        const key = getMetadonneeKey(metadonnee);
                        dossier[key] = "";
                        if (metadonnee.metaelement) {
                            dossier[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                        }
                    });
                }
            }
    
            if (dossier.dossiers) {
                dossier.dossiers = deserialize(dossier.dossiers, ModelName.DOSSIER);
            }
    
            if (dossier.documents) {
                dossier.documents = deserialize(dossier.documents, ModelName.DOCUMENT);
            }
    
            if (dossier.personnels) {
                dossier.personnels = deserialize(dossier.personnels, ModelName.PERSONNEL);
    
                dossier.personnels_ids = [];
                if (dossier.personnels.length > 0) {
                    dossier.personnels.forEach((personnel) => {
                        dossier.personnels_ids.push(personnel.id);
                    });
                }
            }
    
            if (dossier.profils) {
                dossier.profils = deserialize(dossier.profils, ModelName.PROFIL);
    
                dossier.profils_ids = [];
                if (dossier.profils.length > 0) {
                    dossier.profils.forEach((profil) => {
                        dossier.profils_ids.push(profil.id);
                    });
                }
            }
    
            if (dossier.groupes) {
                dossier.groupes = deserialize(dossier.groupes, ModelName.GROUPE);
    
                dossier.groupes_ids = [];
                if (dossier.groupes.length > 0) {
                    dossier.groupes.forEach((groupe) => {
                        dossier.groupes_ids.push(groupe.id);
                    });
                }
            }
    
            if (authUser) {
                dossier.is_owner = authUser.id === dossier.user_id;
            }
    
            dossier.is_private = dossier.confidentialite_code === Enumeration.CONFIDENTIALITE_PRIVATE;
            dossier.is_public = dossier.confidentialite_code === Enumeration.CONFIDENTIALITE_PUBLIC;
            dossier.is_shared = dossier.confidentialite_code === Enumeration.CONFIDENTIALITE_SHARE;
    
            return dossier;
        case ModelName.EXTENSION:
            let extension = serializer.deserialize<Extension>(Extension, object);

            extension.actif = anyToBoolean(extension.actif);

            extension.statut = extension.actif ? trans("active") : trans("inactive");
    
            // if (extension.user)
            //     extension.user = deserialize(extension.user, ModelName.UTILISATEUR);
    
            return extension;
        case ModelName.FICHIER:
            let fichier = serializer.deserialize<Fichier>(Fichier, object);

            fichier.uid = stringToNumber(_.uniqueId());

            fichier.configuration_id = fichier.configuration_id ? fichier.configuration_id : 0;
            
            fichier.dossier_id = fichier.dossier_id ? fichier.dossier_id : 0;
    
            fichier.is_document = fichier.version !== undefined;

            fichier.type = fichier.is_document ? Enumeration.NODE_DOCUMENT : Enumeration.NODE_DOSSIER;
    
            if (fichier.user) {
                fichier.user = deserialize(fichier.user, ModelName.UTILISATEUR);
                fichier.user_name = fichier.user.fullname;
            }
    
            if (fichier.personnel) {
                fichier.personnel = deserialize(fichier.personnel, ModelName.PERSONNEL);
                fichier.personnel_name = fichier.personnel.fullname;
            }
    
            if (fichier.confidentialite) {
                fichier.confidentialite = deserialize(fichier.confidentialite, ModelName.CONFIDENTIALITE);
                fichier.confidentialite_name = fichier.confidentialite.nom;
                fichier.confidentialite_code = fichier.confidentialite.code;
            }
    
            if (fichier.dossier) {
                fichier.dossier = deserialize(fichier.dossier, ModelName.DOSSIER);
                fichier.path_id = `${fichier.dossier.path_id} ${fichier.dossier.id}`;
                fichier.path_name = `${fichier.dossier.path_name}/${fichier.nom}`;
                fichier.dossier_name = fichier.dossier.nom;
            }
            else {
                fichier.path_name = `/${fichier.nom}`;
            }
    
            if (fichier.configuration) {
                fichier.configuration = deserialize(fichier.configuration, ModelName.CONFIGURATION);
                fichier.configuration_name = fichier.configuration.nom;
            }
    
            if (fichier.metadonnees) {
                if (fichier.is_document) {
                    fichier.metadonnees = deserialize(fichier.metadonnees as MetadonneeDocument[], ModelName.METADONNEE_DOCUMENT);
        
                    if (fichier.metadonnees.length > 0) { 
                        fichier.metadonnees.forEach((metadonnee) => {
                            const key = getMetadonneeKey(metadonnee);
                            fichier[key] = "";
                            if (metadonnee.metaelement) {
                                fichier[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                            }
                        });
                    }
                }
                else {
                    fichier.metadonnees = deserialize(fichier.metadonnees as MetadonneeDossier[], ModelName.METADONNEE_DOSSIER);
        
                    if (fichier.metadonnees.length > 0) { 
                        fichier.metadonnees.forEach((metadonnee) => {
                            const key = getMetadonneeKey(metadonnee);
                            fichier[key] = "";
                            if (metadonnee.metaelement) {
                                fichier[key] = metadonneeToModel(metadonnee.metaelement, metadonnee);
                            }
                        });
                    }
                }
            }
    
            if (fichier.personnels) {
                fichier.personnels = deserialize(fichier.personnels, ModelName.PERSONNEL);
    
                fichier.personnels_ids = [];
                if (fichier.personnels.length > 0) {
                    fichier.personnels.forEach((personnel) => {
                        fichier.personnels_ids.push(personnel.id);
                    });
                }
            }
    
            if (fichier.profils) {
                fichier.profils = deserialize(fichier.profils, ModelName.PROFIL);
    
                fichier.profils_ids = [];
                if (fichier.profils.length > 0) {
                    fichier.profils.forEach((profil) => {
                        fichier.profils_ids.push(profil.id);
                    });
                }
            }
    
            if (fichier.groupes) {
                fichier.groupes = deserialize(fichier.groupes, ModelName.GROUPE);
    
                fichier.groupes_ids = [];
                if (fichier.groupes.length > 0) {
                    fichier.groupes.forEach((groupe) => {
                        fichier.groupes_ids.push(groupe.id);
                    });
                }
            }
    
            if (authUser) {
                fichier.is_owner = authUser.id === fichier.user_id;
            }
    
            fichier.is_private = fichier.confidentialite_code === Enumeration.CONFIDENTIALITE_PRIVATE;
            fichier.is_public = fichier.confidentialite_code === Enumeration.CONFIDENTIALITE_PUBLIC;
            fichier.is_shared = fichier.confidentialite_code === Enumeration.CONFIDENTIALITE_SHARE;
    
            // Dossiers
            if (fichier.dossiers) {
                fichier.dossiers = deserialize(fichier.dossiers, ModelName.DOSSIER);
            }
    
            if (fichier.documents) {
                fichier.documents = deserialize(fichier.documents, ModelName.DOCUMENT);
            }
    
            // Documents
            if (fichier.version) {
                fichier.version = deserialize(fichier.version, ModelName.VERSION);
                
                // fichier.hash_name = fichier.version.hash_name;
                // fichier.extension = fichier.version.extension;
                // fichier.size = fichier.version.size;
                // fichier.url = fichier.version.url;
                // fichier.contents = fichier.version.contents;
                
                // fichier.size_display = fichier.version.size_display;
                // fichier: File;
            }

            if (fichier.versions) {
                fichier.versions = deserialize(fichier.versions, ModelName.VERSION);
            }

            if (fichier.partages) {
                fichier.partages = deserialize(fichier.partages, ModelName.PARTAGE);
            }

            if (fichier.approbations) {
                fichier.approbations = deserialize(fichier.approbations, ModelName.APPROBATION);
            }

            if (fichier.workflows && fichier.workflows.length > 0) {
                fichier.workflows = deserialize(fichier.workflows, ModelName.WORKFLOW);
                const fichierWorkflow = fichier.workflows.find(w => w.statut === Enumeration.STATUT_ENCOURS);
                if (fichierWorkflow) {
                    fichier.workflow_statut_code = fichierWorkflow.statut;
                    fichier.workflow_statut_label = fichierWorkflow.statut_label;
                }
                else {
                    fichier.workflow_statut_code = Enumeration.STATUT_TERMINE;
                    fichier.workflow_statut_label = trans('finished');
                }
            }
    
            return fichier;
        case ModelName.GROUPE:
            let groupe = serializer.deserialize<Groupe>(Groupe, object);

            if (groupe.perimetre) {
                groupe.perimetre = deserialize(groupe.perimetre, ModelName.PERIMETRE);
                groupe.perimetre_name = groupe.perimetre.nom;
            }
    
            if (groupe.personnels) {
                groupe.personnels = deserialize(groupe.personnels, ModelName.PERSONNEL);

                if (groupe.personnels && groupe.personnels.length > 0) {
                    groupe.personnels.forEach((personnel) => {
                        if (groupe.personnels_name) {
                            groupe.personnels_name = `${groupe.personnels_name} - ${personnel.fullname}`;
                        }
                        else {
                            groupe.personnels_name = `${personnel.fullname}`
                        }
                    })
                }
            }
    
            if (groupe.roles) {
                groupe.roles = deserialize(groupe.roles, ModelName.ROLE);

                if (groupe.roles && groupe.roles.length > 0) {
                    groupe.roles.forEach((role) => {
                        if (groupe.roles_name) {
                            groupe.roles_name = `${groupe.roles_name} - ${role.nom}`;
                        }
                        else {
                            groupe.roles_name = `${role.nom}`
                        }
                    })
                }
            }
    
            return groupe;
        case ModelName.GROUPE_PERSONNEL:
            let groupePersonnel = serializer.deserialize<GroupePersonnel>(GroupePersonnel, object);

            // if (groupePersonnel.user)
            //     groupePersonnel.user = deserialize(groupePersonnel.user, ModelName.UTILISATEUR);
    
            if (groupePersonnel.pivot)
                groupePersonnel.pivot = deserialize(groupePersonnel.pivot, ModelName.GROUPE_PERSONNEL_PIVOT);
    
            return groupePersonnel;
        case ModelName.GROUPE_PERSONNEL_PIVOT:
            return serializer.deserialize<GroupePersonnelPivot>(GroupePersonnelPivot, object);
        case ModelName.METADONNEE:
            let metadonnee = serializer.deserialize<Metadonnee>(Metadonnee, object);

            if (metadonnee.metaelement) {
                metadonnee.metaelement = deserialize(metadonnee.metaelement, ModelName.METAELEMENT);
                metadonnee.metaelement_name = metadonnee.metaelement.nom;
            }
    
            metadonnee.metavaleurs = deserialize(metadonnee.metavaleurs, ModelName.METAVALEUR);
    
            return metadonnee;
        case ModelName.METADONNEE_BRANCHE:
            let metadonneeBranche = serializer.deserialize<MetadonneeBranche>(MetadonneeBranche, object);

            if (metadonneeBranche.metaelement) {
                metadonneeBranche.metaelement = deserialize(metadonneeBranche.metaelement, ModelName.METAELEMENT);
                metadonneeBranche.metaelement_name = metadonneeBranche.metaelement.nom;
            }
    
            metadonneeBranche.metavaleurs = deserialize(metadonneeBranche.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneeBranche.pivot) {
                metadonneeBranche.pivot = deserialize(metadonneeBranche.pivot, ModelName.METADONNEE_BRANCHE_PIVOT);
                metadonneeBranche.valeur = metadonneeBranche.pivot.valeur;
            }
    
            return metadonneeBranche;
        case ModelName.METADONNEE_BRANCHE_PIVOT:
            return serializer.deserialize<MetadonneeBranchePivot>(MetadonneeBranchePivot, object);
        case ModelName.METADONNEE_CONFIGURATION:
            let metadonneeConfiguration = serializer.deserialize<MetadonneeConfiguration>(MetadonneeConfiguration, object);

            metadonneeConfiguration.requis = anyToBoolean(metadonneeConfiguration.requis);
            metadonneeConfiguration.visible = anyToBoolean(metadonneeConfiguration.visible);
    
            if (metadonneeConfiguration.metaelement) {
                metadonneeConfiguration.metaelement = deserialize(metadonneeConfiguration.metaelement, ModelName.METAELEMENT);
                metadonneeConfiguration.metaelement_name = metadonneeConfiguration.metaelement.nom;
            }
    
            metadonneeConfiguration.metavaleurs = deserialize(metadonneeConfiguration.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneeConfiguration.pivot) {
                metadonneeConfiguration.pivot = deserialize(metadonneeConfiguration.pivot, ModelName.METADONNEE_CONFIGURATION_PIVOT);
                metadonneeConfiguration.position = metadonneeConfiguration.pivot.position;
                metadonneeConfiguration.requis = metadonneeConfiguration.pivot.requis;
                metadonneeConfiguration.alignement = metadonneeConfiguration.pivot.alignement;
                metadonneeConfiguration.visible = metadonneeConfiguration.pivot.visible;
            }
    
            return metadonneeConfiguration;
        case ModelName.METADONNEE_CONFIGURATION_PIVOT:
            let metadonneeConfigurationPivot = serializer.deserialize<MetadonneeConfigurationPivot>(MetadonneeConfigurationPivot, object);
    
            metadonneeConfigurationPivot.requis = anyToBoolean(metadonneeConfigurationPivot.requis);
            metadonneeConfigurationPivot.visible = anyToBoolean(metadonneeConfigurationPivot.visible);
    
            return metadonneeConfigurationPivot;
        case ModelName.METADONNEE_DEPARTEMENT:
            let metadonneeDepartement = serializer.deserialize<MetadonneeDepartement>(MetadonneeDepartement, object);

            if (metadonneeDepartement.metaelement) {
                metadonneeDepartement.metaelement = deserialize(metadonneeDepartement.metaelement, ModelName.METAELEMENT);
                metadonneeDepartement.metaelement_name = metadonneeDepartement.metaelement.nom;
            }
    
            metadonneeDepartement.metavaleurs = deserialize(metadonneeDepartement.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneeDepartement.pivot) {
                metadonneeDepartement.pivot = deserialize(metadonneeDepartement.pivot, ModelName.METADONNEE_DEPARTEMENT_PIVOT);
                metadonneeDepartement.valeur = metadonneeDepartement.pivot.valeur;
            }
    
            return metadonneeDepartement;
        case ModelName.METADONNEE_DEPARTEMENT_PIVOT:
            return serializer.deserialize<MetadonneeDepartementPivot>(MetadonneeDepartementPivot, object);
        case ModelName.METADONNEE_DOCUMENT:
            let metadonneeDocument = serializer.deserialize<MetadonneeDocument>(MetadonneeDocument, object);

            if (metadonneeDocument.metaelement) {
                metadonneeDocument.metaelement = deserialize(metadonneeDocument.metaelement, ModelName.METAELEMENT);
                metadonneeDocument.metaelement_name = metadonneeDocument.metaelement.nom;
            }
    
            metadonneeDocument.metavaleurs = deserialize(metadonneeDocument.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneeDocument.pivot) {
                metadonneeDocument.pivot = deserialize(metadonneeDocument.pivot, ModelName.METADONNEE_DOCUMENT_PIVOT);
                metadonneeDocument.valeur = metadonneeDocument.pivot.valeur;
            }
    
            return metadonneeDocument;
        case ModelName.METADONNEE_DOCUMENT_PIVOT:
            return serializer.deserialize<MetadonneeDocumentPivot>(MetadonneeDocumentPivot, object);
        case ModelName.METADONNEE_DOSSIER:
            let metadonneeDossier = serializer.deserialize<MetadonneeDossier>(MetadonneeDossier, object);

            if (metadonneeDossier.metaelement) {
                metadonneeDossier.metaelement = deserialize(metadonneeDossier.metaelement, ModelName.METAELEMENT);
                metadonneeDossier.metaelement_name = metadonneeDossier.metaelement.nom;
            }
    
            metadonneeDossier.metavaleurs = deserialize(metadonneeDossier.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneeDossier.pivot) {
                metadonneeDossier.pivot = deserialize(metadonneeDossier.pivot, ModelName.METADONNEE_DOSSIER_PIVOT);
                metadonneeDossier.valeur = metadonneeDossier.pivot.valeur;
            }
    
            return metadonneeDossier;
        case ModelName.METADONNEE_DOSSIER_PIVOT:
            return serializer.deserialize<MetadonneeDossierPivot>(MetadonneeDossierPivot, object);
        case ModelName.METADONNEE_PERSONNEL:
            let metadonneePersonnel = serializer.deserialize<MetadonneePersonnel>(MetadonneePersonnel, object);

            if (metadonneePersonnel.metaelement) {
                metadonneePersonnel.metaelement = deserialize(metadonneePersonnel.metaelement, ModelName.METAELEMENT);
                metadonneePersonnel.metaelement_name = metadonneePersonnel.metaelement.nom;
            }
    
            metadonneePersonnel.metavaleurs = deserialize(metadonneePersonnel.metavaleurs, ModelName.METAVALEUR);
    
            if (metadonneePersonnel.pivot) {
                metadonneePersonnel.pivot = deserialize(metadonneePersonnel.pivot, ModelName.METADONNEE_PERSONNEL_PIVOT);
                metadonneePersonnel.valeur = metadonneePersonnel.pivot.valeur;
            }
    
            return metadonneePersonnel;
        case ModelName.METADONNEE_PERSONNEL_PIVOT:
            return serializer.deserialize<MetadonneePersonnelPivot>(MetadonneePersonnelPivot, object);
        case ModelName.METAVALEUR:
            let metavaleur = serializer.deserialize<Metavaleur>(Metavaleur, object);

            if (metavaleur.metadonnee) {
                metavaleur.metadonnee = deserialize(metavaleur.metadonnee, ModelName.METADONNEE);
                metavaleur.metadonnee_name = metavaleur.metadonnee.nom;
            }
    
            return metavaleur;
        case ModelName.PARTAGE:
            let partage = serializer.deserialize<Partage>(Partage, object);

            partage.telechargeable = anyToBoolean(partage.telechargeable);
            partage.publique = anyToBoolean(partage.publique);
    
            if (partage.document) {
                partage.document = deserialize(partage.document, ModelName.DOCUMENT);
                partage.document_name = partage.document.nom;
            }
    
            return partage;
        case ModelName.PERIMETRE:
            let perimetre = serializer.deserialize<Perimetre>(Perimetre, object);

            if (perimetre.ressource) {
                perimetre.ressource = deserialize(perimetre.ressource, ModelName.RESSOURCE);
                perimetre.ressource_name = perimetre.ressource.nom;
            }
    
            return perimetre;
        case ModelName.PERSONNEL:
            let personnel = serializer.deserialize<Personnel>(Personnel, object);

            deserializePersonnel(personnel);
    
            return personnel;
        case ModelName.PERSONNEL_DOCUMENT:
            let personnelDocument = serializer.deserialize<PersonnelDocument>(PersonnelDocument, object);

            deserializePersonnel(personnelDocument);

            if (personnelDocument.pivot) {
                personnelDocument.pivot = deserialize(personnelDocument.pivot, ModelName.PERSONNEL_DOCUMENT_PIVOT);
            }

            return personnelDocument;
        case ModelName.PERSONNEL_DOCUMENT_PIVOT:
            return serializer.deserialize<PersonnelDocumentPivot>(PersonnelDocumentPivot, object);
        case ModelName.PERSONNEL_DOSSIER:
            let personnelDossier = serializer.deserialize<PersonnelDossier>(PersonnelDossier, object);

            deserializePersonnel(personnelDossier);

            if (personnelDossier.pivot) {
                personnelDossier.pivot = deserialize(personnelDossier.pivot, ModelName.PERSONNEL_DOSSIER_PIVOT);
            }

            return personnelDossier;
        case ModelName.PERSONNEL_DOSSIER_PIVOT:
            return serializer.deserialize<PersonnelDossierPivot>(PersonnelDossierPivot, object);
        case ModelName.PERSONNEL_WORKFLOW:
            let personnelWorkflow = serializer.deserialize<PersonnelWorkflow>(PersonnelWorkflow, object);

            deserializePersonnel(personnelWorkflow);

            if (personnelWorkflow.pivot) {
                personnelWorkflow.pivot = deserialize(personnelWorkflow.pivot, ModelName.PERSONNEL_WORKFLOW_PIVOT);

                personnelWorkflow.consigne = personnelWorkflow.pivot.consigne;
                personnelWorkflow.commentaire = personnelWorkflow.pivot.commentaire;
                
                personnelWorkflow.delai = personnelWorkflow.pivot.delai;
                personnelWorkflow.delai_label = personnelWorkflow.pivot.delai_label;

                personnelWorkflow.position = personnelWorkflow.pivot.position;

                personnelWorkflow.statut = personnelWorkflow.pivot.statut;
                personnelWorkflow.statut_label = personnelWorkflow.pivot.statut_label;

                personnelWorkflow.personnel = personnelWorkflow.pivot.personnel;
                personnelWorkflow.workflow = personnelWorkflow.pivot.workflow;

                personnelWorkflow.is_approved = personnelWorkflow.pivot.is_approved;
                personnelWorkflow.is_consulted = personnelWorkflow.pivot.is_consulted;
                personnelWorkflow.is_expired = personnelWorkflow.pivot.is_expired;
                personnelWorkflow.is_rejected = personnelWorkflow.pivot.is_rejected;
                personnelWorkflow.is_waiting = personnelWorkflow.pivot.is_waiting;
            }
    
            return personnelWorkflow;
        case ModelName.PERSONNEL_WORKFLOW_CONFIGURATION:
            let personnelWorkflowConfiguration = serializer.deserialize<PersonnelWorkflowConfiguration>(PersonnelWorkflowConfiguration, object);

            deserializePersonnel(personnelWorkflowConfiguration);

            if (personnelWorkflowConfiguration.pivot) {
                personnelWorkflowConfiguration.pivot = deserialize(
                    personnelWorkflowConfiguration.pivot, 
                    ModelName.PERSONNEL_WORKFLOW_CONFIGURATION_PIVOT
                );

                personnelWorkflowConfiguration.consigne = personnelWorkflowConfiguration.pivot.consigne;
                personnelWorkflowConfiguration.periode = personnelWorkflowConfiguration.pivot.periode;
                personnelWorkflowConfiguration.duree = personnelWorkflowConfiguration.pivot.duree;
                personnelWorkflowConfiguration.position = personnelWorkflowConfiguration.pivot.position;

                const periode = periodes.find(p => p.value === personnelWorkflowConfiguration.periode);
                if (periode) {
                    personnelWorkflowConfiguration.periode_label = periode.label;
                }
            }
    
            return personnelWorkflowConfiguration;
        case ModelName.PERSONNEL_WORKFLOW_CONFIGURATION_PIVOT:
            return serializer.deserialize<PersonnelWorkflowConfigurationPivot>(PersonnelWorkflowConfigurationPivot, object);
        case ModelName.PERSONNEL_WORKFLOW_PIVOT:
            let personnelWorkflowPivot = serializer.deserialize<PersonnelWorkflowPivot>(PersonnelWorkflowPivot, object);
            
            deserializeActivite(personnelWorkflowPivot, now);

            return personnelWorkflowPivot;
        case ModelName.PROFIL:
            let profil = serializer.deserialize<Profil>(Profil, object);

            if (profil.perimetre) {
                profil.perimetre = deserialize(profil.perimetre, ModelName.PERIMETRE);
                profil.perimetre_name = profil.perimetre.nom;
            }
    
            if (profil.personnels) {
                profil.personnels = deserialize(profil.personnels, ModelName.PERSONNEL);

                if (profil.personnels && profil.personnels.length > 0) {
                    profil.personnels.forEach((personnel) => {
                        if (profil.personnels_name) {
                            profil.personnels_name = `${profil.personnels_name} - ${personnel.fullname}`;
                        }
                        else {
                            profil.personnels_name = `${personnel.fullname}`
                        }
                    })
                }
            }
    
            if (profil.roles) {
                profil.roles = deserialize(profil.roles, ModelName.ROLE);

                if (profil.roles && profil.roles.length > 0) {
                    profil.roles.forEach((role) => {
                        if (profil.roles_name) {
                            profil.roles_name = `${profil.roles_name} - ${role.nom}`;
                        }
                        else {
                            profil.roles_name = `${role.nom}`
                        }
                    })
                }
            }
    
            return profil;
        case ModelName.ROLE:
            let role = serializer.deserialize<Role>(Role, object);

            if (role.ressource) {
                role.ressource = deserialize(role.ressource, ModelName.RESSOURCE);
                role.ressource_code = role.ressource.code;
                role.ressource_name = role.ressource.nom;
            }

            if (role.action) {
                role.action = deserialize(role.action, ModelName.ACTION);
                role.action_code = role.action.code;
                role.action_name = role.action.nom;
            }

            return role;
        case ModelName.ROLE_GROUPE:
            let roleGroupe = serializer.deserialize<RoleGroupe>(RoleGroupe, object);

            if (roleGroupe.ressource) {
                roleGroupe.ressource = deserialize(roleGroupe.ressource, ModelName.RESSOURCE);
                roleGroupe.ressource_name = roleGroupe.ressource.nom;
            }
    
            if (roleGroupe.action) {
                roleGroupe.action = deserialize(roleGroupe.action, ModelName.ACTION);
                roleGroupe.action_name = roleGroupe.action.nom;
            }
    
            if (roleGroupe.pivot)
                roleGroupe.pivot = deserialize(roleGroupe.pivot, ModelName.ROLE_GROUPE_PIVOT);
    
            return roleGroupe;
        case ModelName.ROLE_GROUPE_PIVOT:
            return serializer.deserialize<RoleGroupePivot>(RoleGroupePivot, object);
        case ModelName.ROLE_PROFIL:
            let roleProfil = serializer.deserialize<RoleProfil>(RoleProfil, object);

            if (roleProfil.ressource) {
                roleProfil.ressource = deserialize(roleProfil.ressource, ModelName.RESSOURCE);
                roleProfil.ressource_name = roleProfil.ressource.nom;
            }
    
            if (roleProfil.action) {
                roleProfil.action = deserialize(roleProfil.action, ModelName.ACTION);
                roleProfil.action_name = roleProfil.action.nom;
            }
            
            if (roleProfil.pivot)
                roleProfil.pivot = deserialize(roleProfil.pivot, ModelName.ROLE_PROFIL_PIVOT);
    
            return roleProfil;
        case ModelName.ROLE_PROFIL_PIVOT:
            return serializer.deserialize<RoleProfilPivot>(RoleProfilPivot, object);
        case ModelName.SERVEUR:
            return serializer.deserialize<Serveur>(Serveur, object);
        case ModelName.TERMINOLOGIE:
            return serializer.deserialize<Terminologie>(Terminologie, object);
        case ModelName.VERSION:
            let version = serializer.deserialize<Version>(Version, object);
            
            if (version.user) {
                version.user = deserialize(version.user, ModelName.UTILISATEUR);
                version.user_name = version.user.fullname;
            }
    
            if (version.personnel) {
                version.personnel = deserialize(version.personnel, ModelName.PERSONNEL);
                version.personnel_name = version.personnel.fullname;
            }
    
            if (version.document) {
                version.document = deserialize(version.document, ModelName.DOCUMENT);
                version.document_name = version.document.nom;
                version.document_confidentialite = version.document.confidentialite_name;
            }
            
            if (version.size) {
                version.size_display = formatBytes(stringToNumber(version.size));
            }

            // version.is_document = version.extension !== undefined;
    
            return version;
        case ModelName.WORKFLOW:
                let workflow = serializer.deserialize<Workflow>(Workflow, object);
        
                if (workflow.statut === Enumeration.STATUT_ENCOURS) {
                    workflow.is_inprogress = true;
                    workflow.statut_label = trans('in_progress');
                }
                else if (workflow.statut === Enumeration.STATUT_TERMINE) {
                    workflow.is_finished = true;
                    workflow.statut_label = trans('finished');
                }
                else {
                    workflow.statut_label = "";
                }
                
                if (workflow.document) {
                    workflow.document = deserialize(
                        workflow.document, 
                        ModelName.DOCUMENT
                    );
                }
        
                if (workflow.workflow_configuration) {
                    workflow.workflow_configuration = deserialize(
                        workflow.workflow_configuration, 
                        ModelName.WORKFLOW_CONFIGURATION
                    );
                    workflow.workflow_configuration_name = workflow.workflow_configuration.nom;
                }
        
                if (workflow.personnels) {
                    workflow.personnels = deserialize(
                        workflow.personnels as PersonnelWorkflow[], 
                        ModelName.PERSONNEL_WORKFLOW
                    );
                }
        
                return workflow;
        case ModelName.WORKFLOW_ACTIVITE:
            let workflowActivite = serializer.deserialize<WorkflowActivite>(WorkflowActivite, object);
            
            deserializeActivite(workflowActivite, now);

            workflowActivite.id = `${workflowActivite.personnel_id}-${workflowActivite.workflow_id}`;
            
            const format = formatDateToDuration(workflowActivite.delai, true);
            workflowActivite.delai_duration = format.formatted;
            workflowActivite.urgence = format.urgency;

            return workflowActivite;
        case ModelName.WORKFLOW_CONFIGURATION:
            let workflowConfiguration = serializer.deserialize<WorkflowConfiguration>(WorkflowConfiguration, object);
    
            if (workflowConfiguration.workflow_type) {
                workflowConfiguration.workflow_type = deserialize(
                    workflowConfiguration.workflow_type, 
                    ModelName.WORKFLOW_TYPE
                );
                workflowConfiguration.workflow_type_name = workflowConfiguration.workflow_type.nom;
            }
    
            if (workflowConfiguration.personnels) {
                workflowConfiguration.personnels = deserialize(
                    workflowConfiguration.personnels as PersonnelWorkflowConfiguration[], 
                    ModelName.PERSONNEL_WORKFLOW_CONFIGURATION
                );
            }
    
            if (workflowConfiguration.workflows) {
                workflowConfiguration.workflows = deserialize(
                    workflowConfiguration.workflows, 
                    ModelName.WORKFLOW
                );
            }
    
            return workflowConfiguration;
        case ModelName.WORKFLOW_TYPE:
            let workflowType = serializer.deserialize<WorkflowType>(WorkflowType, object);

            if (workflowType.workflows) {
                workflowType.workflows = deserialize(workflowType.workflows, ModelName.WORKFLOW);
            }
    
            return workflowType;
                
        default:
            return object;
    }
}

export function createList(object: any, name: string) {
    switch (name) {
        // Auth
        // case ModelName.LOGIN_REQUEST:
        //     return deserializeArray<LoginRequest>(object, name);
        // case ModelName.LOGIN_RESPONSE:
        //     return deserializeArray<LoginResponse>(object, name);
        // case ModelName.TOKEN:
        //     return deserializeArray<Token>(object, name);

        // Base
        case ModelName.ALIGNEMENT:
            return deserializeArray<Alignement>(object, name);
        case ModelName.CIVILITE:
            return deserializeArray<Civilite>(object, name);
        case ModelName.ENTITE:
            return deserializeArray<Entite>(object, name);
        case ModelName.EXCEPTION:
            return deserializeArray<Exception>(object, name);
        case ModelName.OPERATION:
            return deserializeArray<Operation>(object, name);
        case ModelName.TYPE:
            return deserializeArray<Type>(object, name);

        // Central
        case ModelName.ACTION:
            return deserializeArray<Action>(object, name);
        case ModelName.CONFIDENTIALITE:
            return deserializeArray<Confidentialite>(object, name);
        case ModelName.DOMAINE:
            return deserializeArray<Domaine>(object, name);
        case ModelName.METAELEMENT:
            return deserializeArray<Metaelement>(object, name);
        case ModelName.MODULE:
            return deserializeArray<Module>(object, name);
        case ModelName.MODULE_ORGANISATION:
            return deserializeArray<ModuleOrganisation>(object, name);
        case ModelName.MODULE_ORGANISATION_PIVOT:
            return deserializeArray<ModuleOrganisationPivot>(object, name);
        case ModelName.ORGANISATION:
            return deserializeArray<Organisation>(object, name);
        case ModelName.PAYS:
            return deserializeArray<Pays>(object, name);
        case ModelName.PAYS_ORGANISATION:
            return deserializeArray<PaysOrganisation>(object, name);
        case ModelName.PAYS_ORGANISATION_PIVOT:
            return deserializeArray<PaysOrganisationPivot>(object, name);
        case ModelName.RESSOURCE:
            return deserializeArray<Ressource>(object, name);

        // Root
        case ModelName.BREADCRUMB:
            return deserializeArray<Breadcrumb>(object, name);
        case ModelName.DASHBOARD:
            return deserializeArray<Dashboard>(object, name);
        case ModelName.HEADER_NOTIFICATION:
            return deserializeArray<HeaderNotification>(object, name);
        case ModelName.FILE_VIEW:
            return deserializeArray<FileView>(object, name);
        case ModelName.JOURNAL:
            return deserializeArray<Journal>(object, name);
        case ModelName.NOTIFICATION:
            return deserializeArray<Notification>(object, name);
        case ModelName.PASSWORD:
            return deserializeArray<Password>(object, name);
        case ModelName.RECHERCHE:
            return deserializeArray<Recherche>(object, name);
        case ModelName.UTILISATEUR:
            return deserializeArray<Utilisateur>(object, name);

        // Tenant
        case ModelName.APPROBATION:
            return deserializeArray<Approbation>(object, name);
        case ModelName.BRANCHE:
            return deserializeArray<Branche>(object, name);
        case ModelName.CONFIGURATION:
            return deserializeArray<Configuration>(object, name);
        case ModelName.DEPARTEMENT:
            return deserializeArray<Departement>(object, name);
        case ModelName.DOCUMENT:
            return deserializeArray<Document>(object, name);
        case ModelName.DOSSIER:
            return deserializeArray<Dossier>(object, name);
        case ModelName.EXTENSION:
            return deserializeArray<Extension>(object, name);
        case ModelName.FICHIER:
            return deserializeArray<Fichier>(object, name);
        case ModelName.GROUPE:
            return deserializeArray<Groupe>(object, name);
        case ModelName.GROUPE_PERSONNEL:
            return deserializeArray<GroupePersonnel>(object, name);
        case ModelName.GROUPE_PERSONNEL_PIVOT:
            return deserializeArray<GroupePersonnelPivot>(object, name);
        case ModelName.METADONNEE:
            return deserializeArray<Metadonnee>(object, name);
        case ModelName.METADONNEE_BRANCHE:
            return deserializeArray<MetadonneeBranche>(object, name);
        case ModelName.METADONNEE_BRANCHE_PIVOT:
            return deserializeArray<MetadonneeBranchePivot>(object, name);
        case ModelName.METADONNEE_CONFIGURATION:
            return deserializeArray<MetadonneeConfiguration>(object, name);
        case ModelName.METADONNEE_CONFIGURATION_PIVOT:
            return deserializeArray<MetadonneeConfigurationPivot>(object, name);
        case ModelName.METADONNEE_DEPARTEMENT:
            return deserializeArray<MetadonneeDepartement>(object, name);
        case ModelName.METADONNEE_DEPARTEMENT_PIVOT:
            return deserializeArray<MetadonneeDepartementPivot>(object, name);
        case ModelName.METADONNEE_DOCUMENT:
            return deserializeArray<MetadonneeDocument>(object, name);
        case ModelName.METADONNEE_DOCUMENT_PIVOT:
            return deserializeArray<MetadonneeDocumentPivot>(object, name);
        case ModelName.METADONNEE_DOSSIER:
            return deserializeArray<MetadonneeDossier>(object, name);
        case ModelName.METADONNEE_DOSSIER_PIVOT:
            return deserializeArray<MetadonneeDossierPivot>(object, name);
        case ModelName.METADONNEE_PERSONNEL:
            return deserializeArray<MetadonneePersonnel>(object, name);
        case ModelName.METADONNEE_PERSONNEL_PIVOT:
            return deserializeArray<MetadonneePersonnelPivot>(object, name);
        case ModelName.METAVALEUR:
            return deserializeArray<Metavaleur>(object, name);
        case ModelName.PARTAGE:
            return deserializeArray<Partage>(object, name);
        case ModelName.PERIMETRE:
            return deserializeArray<Perimetre>(object, name);
        case ModelName.PERSONNEL:
            return deserializeArray<Personnel>(object, name);
        case ModelName.PERSONNEL_DOCUMENT:
            return deserializeArray<PersonnelDocument>(object, name);
        case ModelName.PERSONNEL_DOCUMENT_PIVOT:
            return deserializeArray<PersonnelDocumentPivot>(object, name);
        case ModelName.PERSONNEL_DOSSIER:
            return deserializeArray<PersonnelDossier>(object, name);
        case ModelName.PERSONNEL_DOSSIER_PIVOT:
            return deserializeArray<PersonnelDossierPivot>(object, name);
        case ModelName.PERSONNEL_WORKFLOW:
            return deserializeArray<PersonnelWorkflow>(object, name);
        case ModelName.PERSONNEL_WORKFLOW_CONFIGURATION:
            return deserializeArray<PersonnelWorkflowConfiguration>(object, name);
        case ModelName.PERSONNEL_WORKFLOW_CONFIGURATION_PIVOT:
            return deserializeArray<PersonnelWorkflowConfigurationPivot>(object, name);
        case ModelName.PERSONNEL_WORKFLOW_PIVOT:
            return deserializeArray<PersonnelWorkflowPivot>(object, name);
        case ModelName.PROFIL:
            return deserializeArray<Profil>(object, name);
        case ModelName.ROLE:
            return deserializeArray<Role>(object, name);
        case ModelName.ROLE_GROUPE:
            return deserializeArray<RoleGroupe>(object, name);
        case ModelName.ROLE_GROUPE_PIVOT:
            return deserializeArray<RoleGroupePivot>(object, name);
        case ModelName.ROLE_PROFIL:
            return deserializeArray<RoleProfil>(object, name);
        case ModelName.ROLE_PROFIL_PIVOT:
            return deserializeArray<RoleProfilPivot>(object, name);
        case ModelName.SERVEUR:
            return deserializeArray<Serveur>(object, name);
        case ModelName.TERMINOLOGIE:
            return deserializeArray<Terminologie>(object, name);
        case ModelName.VERSION:
            return deserializeArray<Version>(object, name);
        case ModelName.WORKFLOW:
            return deserializeArray<Workflow>(object, name);
        case ModelName.WORKFLOW_ACTIVITE:
            return deserializeArray<WorkflowActivite>(object, name);
        case ModelName.WORKFLOW_CONFIGURATION:
            return deserializeArray<WorkflowConfiguration>(object, name);
        case ModelName.WORKFLOW_TYPE:
            return deserializeArray<WorkflowType>(object, name);
            
        default:
            return object;
    }
}

export function serialize(value: any) {
    return serializer.serialize(value);
}

export function deserialize(object: any, modelName: string) {
    if (Array.isArray(object)) {
        return createList(object, modelName);
    }
    else {
        return createObject(object, modelName);
    }
}

export function cloneObject(object: any) {
    return _.cloneDeep(object);
}

export function isObject(element: any) {
    return typeof element === 'object' && !Array.isArray(element) && element !== null;
}

export function classToObject(clss: any): any {
    // const keys = (x: any) => Object.getOwnPropertyNames(x).concat(Object.getOwnPropertyNames(x?.__proto__));
    // const isAnObject = (v: any) => Object.prototype.toString.call(v) === '[object Object]';

    const keys = (x: any) => Object.keys(x);
    const isObject = (v: any) => _.isObject(v);
    const isArray = (t: any) => Array.isArray(t);

    if (isArray(clss)) return clss.map(classToObject);

    return keys(clss ?? {}).reduce((object: any, key: string) => {
        const [val, arr, obj] = [clss[key], isArray(clss[key]), isObject(clss[key])];
        object[key] = arr ? val.map(classToObject) : obj ? classToObject(val) : val;
        return object;
    }, {});
}

const BASE64_MARKER = ';base64,';

export function addBase64Marker(dataString: string, dataType: string) {
    return `data:${dataType}${BASE64_MARKER}${dataString}`;//data:application/pdf;base64,
}

export function removeBase64Marker(base64String: string) {
    let dataString = base64String;
    const base64MarkerIndex = base64String.indexOf(BASE64_MARKER);
    if (base64MarkerIndex !== -1) {
        var base64Index = base64MarkerIndex + BASE64_MARKER.length;
        dataString = base64String.substring(base64Index);
    }
    return dataString;
}

export function base64StringToBinary(base64String: string, withMarker: boolean = true) {
    var base64 = base64String;
    if (withMarker) {
        var base64Index = base64String.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        base64 = base64String.substring(base64Index);
    }

    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }

    return array;
}

export function getBase64String(file: File) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        console.log(reader.result);
    };
    reader.onerror = (error) => {
        console.log('Error: ', error);
    };
}

export async function getBase64StringAsync(file: File, withoutMarker = false) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let base64String = "";
            if (reader.result) {
                let result = reader.result.toString();
                base64String = withoutMarker ? removeBase64Marker(result) : result;
            }
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });
}

// Root - FileView
export function revokeUrl(fileView: FileView) {
    if (fileView.fileUrl) {
        URL.revokeObjectURL(fileView.fileUrl);
    }
}
