import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IRessource extends IBase {
    id: number;
    code: string;
    nom: string;
    configurable: boolean;
    description: string;
}

export class Ressource extends Base implements IRessource {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    configurable: boolean = false;

    @JsonProperty()
    description: string = "";

    public constructor(init?: Partial<IRessource>) {
        super();
        Object.assign(this, init);
    }
}
