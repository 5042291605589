import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export type TButtonColor = "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
export type TButtonEdge = false | "start" | "end";
export type TButtonRef = React.RefObject<HTMLButtonElement> | ((instance: HTMLButtonElement | null) => void) | null;
export type TButtonSize = "small" | "medium" | "large";
export type TButtonVariant = "text" | "outlined" | "contained";

export type TAnchorClickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;
export type TButtonClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
export type TSpanClickEvent = React.MouseEvent<HTMLSpanElement, MouseEvent>;

interface IProps {
    label: string,
    icon: React.ReactNode,
    
    ariaLabel?: string,
    color?: TButtonColor,
    disabled?: boolean,
    edge?: TButtonEdge,
    ref?: TButtonRef,
    size?: TButtonSize,
    tabIndex?: number,
    
    onClick: (event: TButtonClickEvent) => void,
}

export default function MuiIconButton(props: IProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton
                aria-label={props.ariaLabel}
                ref={props.ref}
                disabled={props.disabled}
                color={props.color} size={props.size}
                edge={props.edge} tabIndex={props.tabIndex}
                onClick={props.onClick}
                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                { props.icon }
            </IconButton>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{props.label}</Typography>
            </Popover>
        </div>
    );
}