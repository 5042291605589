import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IMetaelement extends IBase {
    id: number;
    code: string;
    nom: string;
    description: string;
}

export class Metaelement extends Base implements IMetaelement {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";
    
    public constructor(init?: Partial<IMetaelement>) {
        super();
        Object.assign(this, init);
    }
}
