import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Perimetre } from "./Perimetre";
import { Personnel } from "./Personnel";
import { Role } from "./Role";

export interface IGroupe extends IBase {
    id: number;
    perimetre_id: number;
    nom: string;
    description: string;
    
    perimetre: Perimetre;
    perimetre_name: string;

    personnels: Personnel[];
    personnels_name: string;

    roles: Role[];
    roles_name: string;
}

export class Groupe extends Base implements IGroupe {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    perimetre_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    perimetre!: Perimetre;

    @JsonProperty()
    perimetre_name: string = "";

    @JsonProperty()
    personnels: Personnel[] = [];

    @JsonProperty()
    personnels_name: string = "";

    @JsonProperty()
    roles: Role[] = [];

    @JsonProperty()
    roles_name: string = "";

    public constructor(init?: Partial<IGroupe>) {
        super();
        Object.assign(this, init);
    }
}
