import { JsonProperty } from "@paddls/ts-serializer";
import { trans } from "../../helpers/TransHelper";
import Enumeration from "../../utils/Enumeration";
import { IPersonnel, Personnel } from "./Personnel";
import { PersonnelWorkflowPivot } from "./PersonnelWorkflowPivot";
import { Workflow } from "./Workflow";

export interface IPersonnelWorkflow extends IPersonnel {
    pivot: PersonnelWorkflowPivot;

    consigne: string;
    commentaire: string;
    
    delai: Date;
    delai_label: string;

    position: number;

    statut: string;
    statut_label: string;

    personnel: Personnel;
    workflow: Workflow;
    
    is_approved: boolean;
    is_consulted: boolean;
    is_expired: boolean;
    is_rejected: boolean;
    is_waiting: boolean;
}

export class PersonnelWorkflow extends Personnel implements IPersonnelWorkflow {
    @JsonProperty()
    pivot: PersonnelWorkflowPivot = new PersonnelWorkflowPivot();
    
    consigne: string = "";
    commentaire: string = "";
    delai: Date = new Date();
    delai_label: string = "";
    position: number = 0;
    
    statut: string = Enumeration.STATUT_ATTENTE;
    statut_label: string = trans('waiting');
    
    personnel!: Personnel;
    workflow!: Workflow;

    is_approved: boolean = false;
    is_consulted: boolean = false;
    is_expired: boolean = false;
    is_rejected: boolean = false;
    is_waiting: boolean = false;

    public constructor(init?: Partial<IPersonnelWorkflow>) {
        super();
        Object.assign(this, init);
    }
}
