import { GridAlignment, GridColDef, GridColType } from "@mui/x-data-grid/models";
import { CountryCode } from "libphonenumber-js";
import { FormGroup } from "reactstrap";
import { Metaelement } from "../models/central/Metaelement";
import { Metadonnee } from "../models/tenant/Metadonnee";
import { MetadonneeBranche } from "../models/tenant/MetadonneeBranche";
import { MetadonneeConfiguration } from "../models/tenant/MetadonneeConfiguration";
import { MetadonneeDepartement } from "../models/tenant/MetadonneeDepartement";
import { MetadonneeDocument } from "../models/tenant/MetadonneeDocument";
import { MetadonneeDossier } from "../models/tenant/MetadonneeDossier";
import { MetadonneePersonnel } from "../models/tenant/MetadonneePersonnel";
import { Metavaleur } from "../models/tenant/Metavaleur";
import Enumeration from "../utils/Enumeration";
import { dateFormat, dateFormatMo, dateTimeFormat, dateTimeFormatMo, dateToString, stringToDate, timeFormat } from "../helpers/InputHelpers";
import { anyToBoolean, stringToNumber } from "../helpers/NumberHelper";
import { trans } from "../helpers/TransHelper";
import {
    IListItem, ICheckboxesParams, ICheckboxParams, IDatePickerParams, IDatetimePickerParams,
    IRadioGroupParams, ISelectParams, ITextAreaParams, ITextFieldParams, ITimePickerParams,
    render_alert, render_checkboxes_input, render_checkbox_input, render_datetime_picker_input,
    render_date_picker_input, render_radio_group_input, render_select_input, render_text_area_input,
    render_text_field_input, render_time_picker_input, TAutocompleteChangeReason,
} from "./ComponentHelper";
import { IBrancheError } from "./forms/tenant/BrancheForm";
import { IDepartementError } from "./forms/tenant/DepartementForm";
import { IPersonnelError } from "./forms/tenant/PersonnelForm";
import { centerRadio } from "./styles/Commons";

export type IError = IBrancheError | IDepartementError | IPersonnelError;
export type MetadonneeObject = MetadonneeBranche | MetadonneeDepartement | MetadonneeDossier | MetadonneeDocument | MetadonneePersonnel;

export function getConfigColumns(metadonnees: MetadonneeConfiguration[]): GridColDef[] {
    let columns: GridColDef[] = [];

    if (metadonnees.length > 0) {
        metadonnees.forEach((metadonnee) => {
            if (metadonnee.visible) {
                const key = getMetadonneeKey(metadonnee);

                let columnType = "string";
                if (metadonnee.metaelement) {
                    columnType = getMetadonneeType(metadonnee.metaelement);
                }

                const column: GridColDef = { 
                    field: `${key}`,
                    headerName: metadonnee.nom,
                    type: columnType,
                    // width: 100,
                    flex: 1,
                    headerAlign: "center",
                    align: metadonnee.alignement as GridAlignment
                };
                
                columns.push(column);
            }
        });
    }

    return columns;
}

export function getDefaultValue(metadonnee: Metadonnee, items: IListItem[] = []) {
    let value: any = "";
    
    const metaelement = metadonnee.metaelement;
    if (metaelement) {
        switch (metaelement.code) {
            case Enumeration.METAELEMENT_CHECKBOX:
                value = false;
                break;
            case Enumeration.METAELEMENT_CHECKBOXES:
                value = [];
                break;
            case Enumeration.METAELEMENT_RADIO:
            case Enumeration.METAELEMENT_SELECT:
                value = (items.length > 0) ? items[0].value : value;
                break;
            case Enumeration.METAELEMENT_NUMBER:
                value = 0;
                break;
            case Enumeration.METAELEMENT_DATE:
            case Enumeration.METAELEMENT_DATETIME:
            case Enumeration.METAELEMENT_TIME:
            case Enumeration.METAELEMENT_PASSWORD:
            case Enumeration.METAELEMENT_TEXTAREA:
            case Enumeration.METAELEMENT_TEXTBOX:
                break;
            default:
                break;
        }
    }
    
    return value;
}

export function getListItems(metavaleurs: Metavaleur[]) {
    let items: IListItem[] = [];

    if (metavaleurs.length > 0) {
        metavaleurs.forEach((metavaleur) => {
            const item: IListItem = {
                id: metavaleur.id,
                label: metavaleur.valeur,
                value: metavaleur.valeur
            };
            items.push(item);
        });
    }

    return items;
}

export function getMetadonneeKey(metadonnee: Metadonnee) {
    return `${metadonnee.id}`;
}

export function getMetadonneeType(metaelement: Metaelement): GridColType {
    let type = 'string';

    switch (metaelement.code) {
        case Enumeration.METAELEMENT_CHECKBOX:
            type = "boolean";
            break;
        case Enumeration.METAELEMENT_DATE:
            type = 'date';
            break;
        case Enumeration.METAELEMENT_DATETIME:
            type = 'dateTime';
            break;
        case Enumeration.METAELEMENT_NUMBER:
            type = 'number';
            break;
        case Enumeration.METAELEMENT_CHECKBOXES:
        case Enumeration.METAELEMENT_PASSWORD:
        case Enumeration.METAELEMENT_RADIO:
        case Enumeration.METAELEMENT_SELECT:
        case Enumeration.METAELEMENT_TEXTAREA:
        case Enumeration.METAELEMENT_TEXTBOX:
        case Enumeration.METAELEMENT_TIME:
        default:
            break;
    }

    return type;
}

export function getModelValue(metaelement: Metaelement, model: any, key: string, defaultValue: any) {
    let value = defaultValue;

    switch (metaelement.code) {
        case Enumeration.METAELEMENT_CHECKBOX:
            value = model[key] ? anyToBoolean(model[key]) : defaultValue;
            break;
        case Enumeration.METAELEMENT_CHECKBOXES:
            value = model[key] ? model[key] : defaultValue;
            break;
        case Enumeration.METAELEMENT_DATE:
            value = model[key] ? stringToDate(model[key], dateFormat) : defaultValue;
            break;
        case Enumeration.METAELEMENT_DATETIME:
            value = model[key] ? stringToDate(model[key], dateTimeFormat) : defaultValue;
            break;
        case Enumeration.METAELEMENT_TIME:
            value = model[key] ? stringToDate(model[key], timeFormat) : defaultValue;
            break;
        case Enumeration.METAELEMENT_NUMBER:
            value = model[key] ? stringToNumber(model[key]) : defaultValue;
            break;
        case Enumeration.METAELEMENT_PASSWORD:
        case Enumeration.METAELEMENT_RADIO:
        case Enumeration.METAELEMENT_SELECT:
        case Enumeration.METAELEMENT_TEXTAREA:
        case Enumeration.METAELEMENT_TEXTBOX:
        default:
            value = model[key] ? model[key] : defaultValue;
            break;
    }

    return value;
}

export function metadonneeToModel(metaelement: Metaelement, metadonnee: MetadonneeObject) {
    const defaultValue = getDefaultValue(metadonnee);
    let value = defaultValue;
    
    switch (metaelement.code) {
        case Enumeration.METAELEMENT_CHECKBOX:
            value = metadonnee.valeur ? anyToBoolean(metadonnee.valeur) : defaultValue;
            break;
        case Enumeration.METAELEMENT_CHECKBOXES:
            value = metadonnee.valeur ? metadonnee.valeur.split('|') : defaultValue;
            break;
        case Enumeration.METAELEMENT_DATE:
            value = metadonnee.valeur ? stringToDate(metadonnee.valeur, dateFormatMo) : defaultValue;
            break;
        case Enumeration.METAELEMENT_DATETIME:
            value = metadonnee.valeur ? stringToDate(metadonnee.valeur, dateTimeFormatMo) : defaultValue;
            break;
        case Enumeration.METAELEMENT_TIME:
            value = metadonnee.valeur ? stringToDate(metadonnee.valeur, timeFormat) : defaultValue;
            break;
        case Enumeration.METAELEMENT_NUMBER:
            value = metadonnee.valeur ? stringToNumber(metadonnee.valeur) : defaultValue;
            break;
        case Enumeration.METAELEMENT_PASSWORD:
        case Enumeration.METAELEMENT_RADIO:
        case Enumeration.METAELEMENT_SELECT:
        case Enumeration.METAELEMENT_TEXTAREA:
        case Enumeration.METAELEMENT_TEXTBOX:
        default:
            value = metadonnee.valeur ? metadonnee.valeur : defaultValue;
            break;
    }

    return value;
}

export function modelToMetadonnee(metaelement: Metaelement, model: any, key: string) {
    let value = undefined;

    switch (metaelement.code) {
        case Enumeration.METAELEMENT_CHECKBOXES:
            value = model[key].join('|');
            break;
        case Enumeration.METAELEMENT_DATE:
            value = model[key] ? dateToString(model[key], dateFormatMo) : model[key];
            break;
        case Enumeration.METAELEMENT_DATETIME:
            value = model[key] ? dateToString(model[key], dateTimeFormatMo) : model[key];
            break;
        case Enumeration.METAELEMENT_TIME:
            value = model[key] ? dateToString(model[key], timeFormat) : model[key];
            break;
        case Enumeration.METAELEMENT_CHECKBOX:
        case Enumeration.METAELEMENT_NUMBER:
        case Enumeration.METAELEMENT_PASSWORD:
        case Enumeration.METAELEMENT_RADIO:
        case Enumeration.METAELEMENT_SELECT:
        case Enumeration.METAELEMENT_TEXTAREA:
        case Enumeration.METAELEMENT_TEXTBOX:
        default:
            value = `${model[key]}`;
            break;
    }

    return value;
}

export interface IInputParams {
    metadonnees: MetadonneeConfiguration[],
    model: any,
    errors: any,
    stateMember: string,
    disabled: boolean
}

export interface IInputHandlers {
    onAutocompleteChange?: (option: any, reason: TAutocompleteChangeReason, fieldToSet: string, stateMember: string, isMulti: boolean, valueField?: string) => void,
    onAutocompleteDeleteValue?: (option: string, fieldToSet: string, stateMember: string, isMulti: boolean, valueField?: string) => void,

    onCheckboxChange?: (checked: boolean, fieldToSet: string, stateMember: string, isMulti: boolean, value?: string) => void,

    onDateTimeChange?: (pickerType: string, date: any, fieldToSet: string, stateMember: string) => void,

    onPaswwordChange?: (event: any, fieldToSet: string, stateMember: string) => void,
    onPaswwordShowHide?: (event: any, fieldToSet: string, stateMember: string) => void,

    onPhoneChange?: (value: any, country: CountryCode | null, fieldToSet: string, stateMember: string) => void,

    onRadioChange?: (radioValue: any, fieldToSet: string, stateMember: string) => void,

    onSelectChange: (event: any, valueField: string, fieldToSet: string, stateMember: string, isMulti: boolean) => void,

    onSwitchChange?: (checked: boolean, fieldToSet: string, stateMember: string) => void,

    onTextAreaChange?: (event: any, fieldToSet: string, stateMember: string) => void,

    onTextFieldChange?: (event: any, fieldToSet: string, numberFormat: boolean, stateMember: string) => void;
    onTextFieldKeyPress?: (event: any) => void,
}

export function render_inputs(params: IInputParams, handlers: IInputHandlers) {
    if (params.metadonnees.length === 0) return null;

    return params.metadonnees.map((metadonnee) => {
        const validate = validateConfiguration(metadonnee);
        let element = render_alert(validate.message);

        if (validate.isValid) {
            const items = getListItems(metadonnee.metavaleurs);
            const defaultValue = getDefaultValue(metadonnee, items);
            const key = getMetadonneeKey(metadonnee);

            let value = defaultValue;
            if (metadonnee.metaelement) {
                value = getModelValue(metadonnee.metaelement, params.model, key, defaultValue);
            }

            const error = params.errors[key] ? params.errors[key] : undefined;
            switch (metadonnee.metaelement.code) {
                case Enumeration.METAELEMENT_CHECKBOX:
                    const checkboxParams: ICheckboxParams = {
                        // required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom,
                        // placeholder: metadonnee.nom,
                        value: value,
                        // errorText: error,
                        onChange: handlers.onCheckboxChange
                    };
                    element = render_checkbox_input(checkboxParams);
                    break;
                case Enumeration.METAELEMENT_CHECKBOXES:
                    const checkboxesParams: ICheckboxesParams = {
                        items,
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom,
                        // placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        row: true,
                        style: centerRadio,
                        onChange: handlers.onCheckboxChange
                    };
                    element = render_checkboxes_input(checkboxesParams);
                    break;
                case Enumeration.METAELEMENT_DATE:
                    const datePickerParams: IDatePickerParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        onChange: handlers.onDateTimeChange
                    };
                    element = render_date_picker_input(datePickerParams);
                    break;
                case Enumeration.METAELEMENT_DATETIME:
                    const datetimePickerParams: IDatetimePickerParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        onChange: handlers.onDateTimeChange
                    };
                    element = render_datetime_picker_input(datetimePickerParams);
                    break;
                case Enumeration.METAELEMENT_NUMBER:
                    const numberFieldParams: ITextFieldParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        type: "number",
                        onChange: handlers.onTextFieldChange
                    };
                    element = render_text_field_input(numberFieldParams);
                    break;
                case Enumeration.METAELEMENT_PASSWORD:
                    const passwordFieldParams: ITextFieldParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        type: "password",
                        onChange: handlers.onTextFieldChange
                    };
                    element = render_text_field_input(passwordFieldParams);
                    break;
                case Enumeration.METAELEMENT_RADIO:
                    const radioParams: IRadioGroupParams = {
                        items,
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        row: true,
                        style: centerRadio,
                        onChange: handlers.onRadioChange
                    };
                    element = render_radio_group_input(radioParams);
                    break;
                case Enumeration.METAELEMENT_SELECT:
                    const selectParams: ISelectParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        valueField: "valeur", labelField: "valeur",
                        multiple: false, items: metadonnee.metavaleurs,
                        onChange: handlers.onSelectChange,
                    };
                    element = render_select_input(selectParams);
                    break;
                case Enumeration.METAELEMENT_TEXTAREA:
                    const textAreaParams: ITextAreaParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        onChange: handlers.onTextAreaChange
                    };
                    element = render_text_area_input(textAreaParams);
                    break;
                case Enumeration.METAELEMENT_TEXTBOX:
                    const textFieldParams: ITextFieldParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        onChange: handlers.onTextFieldChange
                    };
                    element = render_text_field_input(textFieldParams);
                    break;
                case Enumeration.METAELEMENT_TIME:
                    const timePickerParams: ITimePickerParams = {
                        required: anyToBoolean(metadonnee.requis),
                        disabled: params.disabled,
                        stateMember: params.stateMember,
                        id: metadonnee.code, name: key,
                        label: metadonnee.nom, placeholder: metadonnee.nom,
                        value: value,
                        errorText: error,
                        onChange: handlers.onDateTimeChange
                    };
                    element = render_time_picker_input(timePickerParams);
                    break;
                default:
                    const message = trans('validation:field_element_required', {field: metadonnee.nom});
                    element = render_alert(message, "info");
                    break;
            }
        }

        return (
            <FormGroup key={metadonnee.id}>
                {element}
            </FormGroup>
        );
    });
}

export function validateConfiguration(metadonnee: Metadonnee) {
    let isValid = false;
    let message = trans('validation:field_element_required', {field: metadonnee.nom});

    const metaelement = metadonnee.metaelement;
    if (metaelement) {
        isValid = true;
        switch (metaelement.code) {
            case Enumeration.METAELEMENT_CHECKBOXES:
            case Enumeration.METAELEMENT_RADIO:
            case Enumeration.METAELEMENT_SELECT:
                if (metadonnee.metavaleurs.length < 1) {
                    isValid = false;
                    message = trans('validation:field_values_required', {field: metadonnee.nom});
                }
                break;
            default:
                break;
        }
    }
    
    return { isValid, message };
}

export function validateModel(metaelement: Metaelement, model: any, key: string, required: boolean, defaultValue: string = "") {
    let isValid = true;
    let message = trans('validation:field_required');

    switch (metaelement.code) {
        case Enumeration.METAELEMENT_CHECKBOXES:
            if (required && model[key].length === 0) {
                isValid = false;
            }
            break;
        case Enumeration.METAELEMENT_CHECKBOX:
        case Enumeration.METAELEMENT_DATE:
        case Enumeration.METAELEMENT_DATETIME:
        case Enumeration.METAELEMENT_NUMBER:
        case Enumeration.METAELEMENT_PASSWORD:
        case Enumeration.METAELEMENT_RADIO:
        case Enumeration.METAELEMENT_SELECT:
        case Enumeration.METAELEMENT_TEXTAREA:
        case Enumeration.METAELEMENT_TEXTBOX:
        case Enumeration.METAELEMENT_TIME:
        default:
            if (required && model[key] === defaultValue) {
                isValid = false;
            }
            break;
    }
    
    return { isValid, message };
}
