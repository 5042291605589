import { JsonProperty } from "@paddls/ts-serializer";
import { trans } from "../../helpers/TransHelper";
import Enumeration from "../../utils/Enumeration";
import { Base, IBase } from '../Base';
import { Document } from "./Document";
import { PersonnelWorkflow } from "./PersonnelWorkflow";
import { WorkflowConfiguration } from "./WorkflowConfiguration";

export interface IWorkflow extends IBase {
    id: number;
    document_id: number;
    workflow_configuration_id: number;
    nom: string;
    description: string;

    statut: string;
    statut_label: string;

    document: Document;

    workflow_configuration: WorkflowConfiguration;
    workflow_configuration_name: string;

    personnels: PersonnelWorkflow[];
    personnels_ids: number[];

    is_inprogress: boolean;
    is_finished: boolean;
}

export class Workflow extends Base implements IWorkflow {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    document_id: number = 0;
    
    @JsonProperty()
    workflow_configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    statut: string = Enumeration.STATUT_ENCOURS;
    statut_label: string = trans('waiting');

    @JsonProperty()
    document!: Document;

    @JsonProperty()
    workflow_configuration: WorkflowConfiguration = new WorkflowConfiguration();
    workflow_configuration_name: string = "";

    @JsonProperty()
    personnels: PersonnelWorkflow[] = [];
    personnels_ids: number[] = [];

    is_inprogress: boolean = false;
    is_finished: boolean = false;

    public constructor(init?: Partial<IWorkflow>) {
        super();
        Object.assign(this, init);
    }
}

export interface IWorkflowRequest {
    id: number;
    document_id: number;
    workflow_configuration_id: number;
    nom: string;
    description: string;
}

export class WorkflowRequest implements IWorkflowRequest {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    document_id: number = 0;
    
    @JsonProperty()
    workflow_configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    public constructor(init?: Partial<IWorkflowRequest>) {
        Object.assign(this, init);
    }
}
