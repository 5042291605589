import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ILoader, withLoader } from './components/hoc/LoaderHoc';
import { IToast, withToast } from './components/hoc/ToastHoc';
import { IWindow, withWindow } from './components/hoc/WindowHoc';
import MuiLoader from './components/commons/MuiLoader';
import { loginRoute, shareRoute, testRoute } from './routes';

interface IProps extends ILoader, IToast, IWindow {
}

// Containers
const DefaultLayout = React.lazy(() => import('./components/layouts/DefaultLayout'));

function App(props: IProps) {
    return (
        <BrowserRouter>
            <Suspense fallback={<MuiLoader isOpen={true} />}>
                <Routes>
                    <Route path={loginRoute.path} element={<loginRoute.element {...props} />} />
                    <Route path={shareRoute.path} element={<shareRoute.element {...props} />} />
                    <Route path={testRoute.path} element={<testRoute.element {...props} />} />
                    <Route path="*" element={<DefaultLayout {...props} />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}
export default withLoader(withToast(withWindow(App)));