import { JsonProperty } from "@paddls/ts-serializer";

interface ILoginRequest {
    username: string;
    password: string;
}

export class LoginRequest implements ILoginRequest {
    @JsonProperty()
    username: string = "";

    @JsonProperty()
    password: string = "";
    
}