import { JsonProperty } from "@paddls/ts-serializer";

export interface IAlignement {
    id: number;
    label: string;
    value: string;
}

export class Alignement implements IAlignement {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    label: string = "";

    @JsonProperty()
    value: string = "";

    public constructor(init?: Partial<IAlignement>) {
        Object.assign(this, init);
    }
}