import { JsonProperty } from "@paddls/ts-serializer";
import { MetadonneeDossierPivot } from "./MetadonneeDossierPivot";
import { IMetadonnee, Metadonnee } from "./Metadonnee";

export interface IMetadonneeDossier extends IMetadonnee {
    pivot: MetadonneeDossierPivot;

    valeur: string;
}

export class MetadonneeDossier extends Metadonnee implements IMetadonneeDossier {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneeDossierPivot = new MetadonneeDossierPivot();

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeDossier>) {
        super();
        Object.assign(this, init);
    }
}
