import { JsonProperty } from "@paddls/ts-serializer";
import { IRole, Role } from "./Role";
import { RoleGroupePivot } from "./RoleGroupePivot";

export interface IRoleGroupe extends IRole {
    pivot: RoleGroupePivot;
}

export class RoleGroupe extends Role implements IRoleGroupe {
    @JsonProperty()
    pivot: RoleGroupePivot = new RoleGroupePivot();

    public constructor(init?: Partial<IRoleGroupe>) {
        super();
        Object.assign(this, init);
    }
}
