import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Domaine } from "./Domaine";
import { ModuleOrganisation } from './ModuleOrganisation';
import { PaysOrganisation } from "./PaysOrganisation";

export interface IOrganisation extends IBase {
    id: number;
    code: string;
    nom: string;
    confidentiel: boolean;
    description: string;

    domains: Domaine[];

    modules: ModuleOrganisation[];
    pays: PaysOrganisation[];

    modules_ids: number[];
    // pays_ids: number[];
}

export class Organisation extends Base implements IOrganisation {
    [key: string]: any;
    
    @JsonProperty({ field: "id" })
    id: number = 0;

    @JsonProperty({ field: "code" })
    code: string = "";

    @JsonProperty({ field: "nom" })
    nom: string = "";

    @JsonProperty({ field: "confidentiel" })
    confidentiel: boolean = true;

    @JsonProperty({ field: "description" })
    description: string = "";

    @JsonProperty({ field: "domains" })
    domains: Domaine[] = [];

    @JsonProperty({ field: "modules" })
    modules: ModuleOrganisation[] = [];

    @JsonProperty({ field: "pays" })
    pays: PaysOrganisation[] = [];

    modules_ids: number[] = [];

    // @JsonProperty()
    // pays_ids: number[] = [];

    public constructor(init?: Partial<IOrganisation>) {
        super();
        Object.assign(this, init);
    }
}
