import { JsonProperty } from "@paddls/ts-serializer";
import { MetadonneePersonnelPivot } from "./MetadonneePersonnelPivot";
import { IMetadonnee, Metadonnee } from "./Metadonnee";

export interface IMetadonneePersonnel extends IMetadonnee {
    pivot: MetadonneePersonnelPivot;

    valeur: string;
}

export class MetadonneePersonnel extends Metadonnee implements IMetadonneePersonnel {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneePersonnelPivot = new MetadonneePersonnelPivot();

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneePersonnel>) {
        super();
        Object.assign(this, init);
    }
}
