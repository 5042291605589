import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IServeur extends IBase {
    id: number;
    type: string;
    host: string;
    port: number;
    encryption: string;
    username: string;
    password: string;
}

export class Serveur extends Base implements IServeur {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    type: string = "";

    @JsonProperty()
    host: string = "";

    @JsonProperty()
    port: number = 0;

    @JsonProperty()
    encryption: string = "";

    @JsonProperty()
    username: string = "";
    
    @JsonProperty()
    password: string = "";

    public constructor(init?: Partial<IServeur>) {
        super();
        Object.assign(this, init);
    }
}
