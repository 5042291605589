import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from "./Base";

export interface IHeaderNotificationElement {
    [key: string]: any;

    duration: string;
    number: number;
}

export interface IHeaderNotification extends IBase {
    [key: string]: any;

    approbations: IHeaderNotificationElement;
    documents: IHeaderNotificationElement;
    dossiers: IHeaderNotificationElement;
    partages: IHeaderNotificationElement;
    versions: IHeaderNotificationElement;
    total: number;
}

export class HeaderNotification extends Base implements IHeaderNotification {
    [key: string]: any;

    @JsonProperty()
    approbations: IHeaderNotificationElement = { duration: "", number: 0 };

    @JsonProperty()
    documents: IHeaderNotificationElement = { duration: "", number: 0 };

    @JsonProperty()
    dossiers: IHeaderNotificationElement = { duration: "", number: 0 };

    @JsonProperty()
    partages: IHeaderNotificationElement = { duration: "", number: 0 };

    @JsonProperty()
    versions: IHeaderNotificationElement = { duration: "", number: 0 };

    @JsonProperty()
    total: number = 0;

    public constructor(init?: Partial<IHeaderNotification>) {
        super();
        Object.assign(this, init);
    }
}