import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //     serializableCheck: {
    //         // Ignore these action types
    //         ignoredActions: ['auth/setToken', 'auth/setSociete'],
    //         // Ignore these field paths in all actions
    //         ignoredActionPaths: ['payload.value'],
    //         // Ignore these paths in the state
    //         ignoredPaths: ['auth.token', 'auth.societe'],
    //     },
    // }),
});
  
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const authState = () => store.getState().auth;

export default store;