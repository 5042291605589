import { Document } from './Document';
import { DateConverter, JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IPartage extends IBase {
    id: number;
    document_id: number;
    url: string;
    destinataire: string;
    date_expiration: Date;
    telechargeable: boolean;
    publique: boolean;
    password: string;

    document: Document;
    document_name: string;
}

export class Partage extends Base implements IPartage {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    document_id: number = 0;

    @JsonProperty()
    url: string = "";

    @JsonProperty()
    destinataire: string = "";

    @JsonProperty({ customConverter: () => DateConverter })
    date_expiration: Date = new Date();
    
    @JsonProperty()
    telechargeable: boolean = false;

    @JsonProperty()
    publique: boolean = false;
    
    @JsonProperty()
    password: string = "";
    
    @JsonProperty()
    document: Document = new Document();

    @JsonProperty()
    document_name: string = "";

    public constructor(init?: Partial<IPartage>) {
        super();
        Object.assign(this, init);
    }
}
