import { JsonProperty } from "@paddls/ts-serializer";
import { IGroupe, Groupe } from "./Groupe";
import { GroupePersonnelPivot } from "./GroupePersonnelPivot";

export interface IGroupePersonnel extends IGroupe {
    pivot: GroupePersonnelPivot;
}

export class GroupePersonnel extends Groupe implements IGroupePersonnel {
    @JsonProperty()
    pivot: GroupePersonnelPivot = new GroupePersonnelPivot();

    public constructor(init?: Partial<IGroupePersonnel>) {
        super();
        Object.assign(this, init);
    }
}
