import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Utilisateur } from "../Utilisateur";
import { Document } from "./Document";
import { Personnel } from "./Personnel";

export interface IVersion extends IBase {
    id: number;
    user_id: number;
    document_id: number;
    hash_name: string;
    extension: string;
    size: string;
    last: boolean;
    contents: string;
    // url: string;

    user: Utilisateur;
    user_name: string;
    
    personnel: Personnel;
    personnel_name: string;
    
    document: Document;
    document_name: string;
    document_confidentialite: string;

    size_display: string;
    // is_document: boolean;
}

export class Version extends Base implements IVersion {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    user_id: number = 0;
    
    @JsonProperty()
    document_id: number = 0;

    @JsonProperty()
    hash_name: string = "";

    @JsonProperty()
    extension: string = "";

    @JsonProperty()
    size: string = "";

    @JsonProperty()
    last: boolean = false;

    @JsonProperty()
    contents: string = "";

    // @JsonProperty()
    // url: string = "";

    @JsonProperty()
    user!: Utilisateur;
    user_name: string = "";

    @JsonProperty()
    personnel!: Personnel;
    personnel_name: string = "";

    @JsonProperty()
    document!: Document;
    document_name: string = "";
    document_confidentialite: string = "";

    size_display: string = "";
    // is_document: boolean = this.extension !== undefined;

    public constructor(init?: Partial<IVersion>) {
        super();
        Object.assign(this, init);
    }
}
