import { JsonProperty } from "@paddls/ts-serializer";
import { IMetadonnee, Metadonnee } from "./Metadonnee";
import { MetadonneeDocumentPivot } from "./MetadonneeDocumentPivot";

export interface IMetadonneeDocument extends IMetadonnee {
    pivot: MetadonneeDocumentPivot;

    valeur: string;
}

export class MetadonneeDocument extends Metadonnee implements IMetadonneeDocument {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneeDocumentPivot = new MetadonneeDocumentPivot();

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeDocument>) {
        super();
        Object.assign(this, init);
    }
}
