import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Ressource } from '../central/Ressource';

export interface IPerimetre extends IBase {
    id: number;
    ressource_id: number;
    nom: string;
    description: string;
    
    ressource: Ressource;
    ressource_name: string;
}

export class Perimetre extends Base implements IPerimetre {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    ressource_id: number = 0;
    
    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    ressource!: Ressource;

    @JsonProperty()
    ressource_name: string = "";

    public constructor(init?: Partial<IPerimetre>) {
        super();
        Object.assign(this, init);
    }
}
