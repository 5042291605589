import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from "./Base";
import { Utilisateur } from "./Utilisateur";

export interface INotification extends IBase {
    id: number;
    user_id: number;
    objet_id: number;
    objet_type: string;
    operation: string;
    actif: boolean;
    description: string;

    user: Utilisateur;

    user_name: string;
    user_email: string;
    statut: string;
}

export class Notification extends Base implements INotification {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    user_id: number = 0;

    @JsonProperty()
    objet_id: number = 0;

    @JsonProperty()
    objet_type: string = "";

    @JsonProperty()
    operation: string = "";
    
    @JsonProperty()
    actif: boolean = false;

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    user!: Utilisateur;

    user_name: string = "";

    user_email: string = "";

    statut: string = "";

    public constructor(init?: Partial<INotification>) {
        super();
        Object.assign(this, init);
    }
}