import { JsonProperty } from "@paddls/ts-serializer";

export interface IPersonnelDocumentPivot {
    personnel_id: number;
    document_id: number;
}

export class PersonnelDocumentPivot implements IPersonnelDocumentPivot {
    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty()
    document_id: number = 0;

    public constructor(init?: Partial<IPersonnelDocumentPivot>) {
        Object.assign(this, init);
    }
}
