import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface ITerminologie extends IBase {
    id: number;
    code: string;
    nom_fr: string;
    nom_en: string;
}

export class Terminologie extends Base implements ITerminologie {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom_fr: string = "";

    @JsonProperty()
    nom_en: string = "";

    public constructor(init?: Partial<ITerminologie>) {
        super();
        Object.assign(this, init);
    }
}
