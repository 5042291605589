export const TENANT_CHANGED = "tenantChanged";
export const TENANT_SWITCHED = "tenantSwitched";
export const WINDOW_CLOSE = "windowClose";

export function subscribe(eventName: string, listener: any) {
    // console.log("EventHelper->subscribe", eventName);
    document.addEventListener(eventName, listener);
}
  
export function unsubscribe(eventName: string, listener: any) {
    // console.log("EventHelper->unsubscribe", eventName);
    document.removeEventListener(eventName, listener);
}
  
export function publish(eventName: string, data: any) {
    // console.log("EventHelper->publish", eventName, data);
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}
