import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeViewProps, TreeView } from '@mui/x-tree-view/TreeView';
import React from "react";
import { TIcon } from './MuiIconsPlus';
import MuiMenu, { IMenuAction, IMenuRef, IMuiMenu } from './MuiMenu';
import MuiTreeItemDashed from './MuiTreeItemDashed';

interface IProps extends TreeViewProps<boolean> {
    data: TreeNode;
    disabled?: boolean;
    selectOnToggle?: boolean;
    toggleOnSelect?: boolean;

    contextMenuActions?: TreeContextMenuActions;
    contextMenuListener?: TreeContextMenuListener;
    
    onToggle?: (event: React.SyntheticEvent<Element, Event>, nodeIds: string[], nodeId: string, isExpand: boolean) => void;
}

export type TreeContextMenuActions = Array<ITreeContextMenuAction>;
export type TreeContextMenuListener = (nodeId: string, actionId: string) => void;

export interface ITreeContextMenuAction extends IMenuAction {
    
}

export interface TreeNode {
    id: string;
    name: string;
    type: string;
    icon?: TIcon;
    info?: JSX.Element;
    children?: TreeNode[];
}

export interface IMuiTreeView {
    getSelectedNodes: () => string | string[],
    setSelectedNodes: (nodeIds: string[]) => void,
}

const WrappedMuiTreeView = React.forwardRef(
    function MuiTreeView(props: IProps, ref: React.ForwardedRef<IMuiTreeView>) {
        // Variables
        const defaultExpanded = props.expanded ? props.expanded : (
            props.defaultExpanded ? props.defaultExpanded : []
        );
        const defaultSelected = props.selected ? props.selected : (
            props.defaultSelected ? props.defaultSelected : (
                props.multiSelect ? [] : ""
            )
        );
        const selectOnToggle = props.selectOnToggle ? props.selectOnToggle : false;
        const toggleOnSelect = props.toggleOnSelect ? props.toggleOnSelect : false;

        const hasContextMenu = () => {
            return props.contextMenuActions ? props.contextMenuActions.length > 0 : false;
        };

        // const rootNodeId = () => {
        //     return props.data.id;
        // };

        const treeNodeMenuActions = (treeNode: TreeNode) => {
            const actions = hasContextMenu() ?
                (props.contextMenuActions as TreeContextMenuActions).filter(action => {
                    const types = action.type;
                    return Array.isArray(types) ? 
                        types.some(type => type === treeNode.type) : 
                        action.type === treeNode.type;
                }) : 
            [];

            // console.log({source: "treeNodeMenuActions", treeNode, actions});
            return actions;
        };
        
        // References
        let myMenu: IMenuRef = {};

        // Handlers
        const getAllChild = (treeNode: TreeNode | null) => {
            let array: string[] = [];
            
            if (treeNode) {
                array.push(treeNode.id);
                if (Array.isArray(treeNode.children)) {
                    treeNode.children.forEach(node => {
                        array = [...array, ...getAllChild(node)];
                        array = array.filter((v, i) => array.indexOf(v) === i);
                    });
                }
            }
            
            return array;
        };

        const getNodeById = (treeNode: TreeNode, id: string) => {
            if (treeNode.id === id) {
                return treeNode;
            }
            else if (Array.isArray(treeNode.children)) {
                let result = null;
                treeNode.children.forEach(node => {
                    if (!!getNodeById(node, id)) {
                        result = getNodeById(node, id);
                    }
                });
                return result;
            }
    
            return null;
        };

        const getChildById = (node: TreeNode, id: string) => {
            return getAllChild(getNodeById(node, id));
        };
        
        const getSelectedNodes = () => {
            return selected;
        };
        
        const setSelectedNodes = (nodeIds: string[]) => {
            return setSelected(nodeIds);
        };

        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, treeNode: TreeNode) => {
            // console.log({source: "MuiTreeView->handleCheckboxChange", treeNode, disabled: props.disabled});
            const checked = event.currentTarget.checked;
            if (Array.isArray(selected)) {
                const allNode: string[] = getChildById(props.data, treeNode.id);
                let array = checked
                ? [...selected, ...allNode]
                : selected.filter(value => !allNode.includes(value));
            
                array = array.filter((v, i) => array.indexOf(v) === i);
            
                setSelected(array);
            }
            else {
                setSelected(treeNode.id);
            }
        };
        
        const handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, treeNode: TreeNode) => {
            // console.log({source: "MuiTreeView->handleCheckboxClick", event, treeNode, disabled: props.disabled});
            event.stopPropagation();
        };
        
        const handleSelect = (event: React.SyntheticEvent<Element, Event>, nodeIds: string | string[]) => {
            // console.log("handleSelect", event, nodeIds);
            let iconClicked = (event.target as Element).closest(".MuiTreeItem-iconContainer");
            if (!iconClicked || selectOnToggle) {
                setSelected(nodeIds);
                if (props.onNodeSelect) {
                    props.onNodeSelect(event, nodeIds);
                }
            }
        };

        const isNodeExpand = (nodeIds: string[]) => {
            return expanded.length < nodeIds.length;
        }

        const toggledNode = (nodeIds: string[], isExpand: boolean) => {
            const nodes = isExpand ? 
                nodeIds.filter((element: string) => !expanded.includes(element)) : 
                expanded.filter((element: string) => !nodeIds.includes(element));
            
            return nodes[0];
        }

        const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
            // console.log("handleToggle", event, expanded, nodeIds);
            let iconClicked = (event.target as Element).closest(".MuiTreeItem-iconContainer");
            if (iconClicked || toggleOnSelect) {
                const isExpand = isNodeExpand(nodeIds);
                const nodeId = toggledNode(nodeIds, isExpand);

                setExpanded(nodeIds);
                if (props.onToggle) {
                    props.onToggle(event, nodeIds, nodeId, isExpand);
                }
                else if (props.onNodeToggle) {
                    props.onNodeToggle(event, nodeIds);
                }
            }
        };

        const handleContextMenu = (event: React.MouseEvent, treeNode: TreeNode) => {
            // console.log("handleContextMenu", event, treeNode);

            const menuActions = treeNodeMenuActions(treeNode);

            if (menuActions.length > 0) {
                event.preventDefault();

                const position = {
                    mouseX: event.clientX + 2,//-2
                    mouseY: event.clientY - 6,//-4
                };

                myMenu[treeNode.id].current!.open(treeNode.id, position);
            }
        };

        const openPopOver = (treeNode: TreeNode, element: HTMLElement | null) => {
            setTreeNode(treeNode);
            setAnchorEl(element);
        };

        const closePopOver = () => {
            setAnchorEl(null);
        };

        const handlePopoverClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            // console.log("handleNodeMouseEnter", event, reason);
            closePopOver();
        };

        const handleNodeMouseEnter = (event: React.MouseEvent<HTMLElement>, treeNode: TreeNode) => {
            // console.log("handleNodeMouseEnter", event.currentTarget, treeNode);
            openPopOver(treeNode, event.currentTarget);
        };
        
        const handleNodeMouseLeave = (event: React.MouseEvent<HTMLElement>, treeNode: TreeNode) => {
            // console.log("handleNodeMouseLeave", event, treeNode);
            closePopOver();
        };
        
        // Hooks
        const [expanded, setExpanded] = React.useState<string[]>(defaultExpanded);
        const [selected, setSelected] = React.useState<string | string[]>(defaultSelected);
        const [treeNode, setTreeNode] = React.useState<TreeNode | null>(null);
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

        React.useImperativeHandle(ref, () => ({
            getSelectedNodes, setSelectedNodes
        }));
        
        // Properties
        const open = Boolean(anchorEl);
        
        // Renders
        const render_popover = () => {
            return (
                <Popover
                    sx={{ pointerEvents: 'none', }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {/* <Typography sx={{ p: 1 }}>{treeNode !== null ? treeNode.name : null}</Typography> */}
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, }}>
                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                            {treeNode !== null ? treeNode.name : null}
                        </Typography>
                        {
                            treeNode !== null && treeNode.info !== undefined ?
                            <Typography variant="caption" color="inherit">
                                {treeNode.info}
                            </Typography> : null
                        }
                    </Box> */}
                    <Stack direction="row" spacing={2}>
                        <Typography sx={{ p: 1 }}>
                            {treeNode !== null ? treeNode.name : null}
                        </Typography>
                        {
                            treeNode !== null && treeNode.info !== undefined ?
                            <Typography variant="caption" color="inherit" sx={{ p: 1 }}>
                                {treeNode.info}
                            </Typography> : null
                        }
                    </Stack>
                </Popover>
            );
        };

        const renderCheckboxNode = (treeNode: TreeNode) => {
            const checked = Array.isArray(selected) ? 
                selected.some(item => item === treeNode.id) : 
                selected === treeNode.id;
            
            const menuActions = treeNodeMenuActions(treeNode);
            myMenu[treeNode.id] = React.createRef<IMuiMenu>();
            return (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked} disabled={props.disabled}
                                onChange={(event) => handleCheckboxChange(event, treeNode)}
                                onClick={(event) => handleCheckboxClick(event, treeNode)}
                            />
                        }
                        label={<>{treeNode.name}</>}
                        key={treeNode.id}
                        onContextMenu={(event) => handleContextMenu(event, treeNode)} style={{ cursor: "context-menu" }}
                    />
                    {
                        menuActions.length > 0 &&
                        <MuiMenu ref={myMenu[treeNode.id]}
                            menuActions={menuActions}
                            menuListener={props.contextMenuListener as TreeContextMenuListener}
                        />
                    }
                </>
            );
        };

        const renderIconNode = (treeNode: TreeNode) => {
            const menuActions = treeNodeMenuActions(treeNode);
            myMenu[treeNode.id] = React.createRef<IMuiMenu>();
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, }}
                    onContextMenu={(event) => handleContextMenu(event, treeNode)} style={{ cursor: "context-menu" }}
                    onMouseEnter={(event) => handleNodeMouseEnter(event, treeNode)}
                    onMouseLeave={(event) => handleNodeMouseLeave(event, treeNode)}
                >
                    <Box component={treeNode.icon ?? CheckIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {treeNode.name}
                    </Typography>
                    {
                        treeNode.info !== undefined ?
                        <Typography variant="caption" color="inherit">
                            {treeNode.info}
                        </Typography> : null
                    }
                    {
                        menuActions.length > 0 ?
                        <MuiMenu ref={myMenu[treeNode.id]}
                            menuActions={menuActions}
                            menuListener={props.contextMenuListener as TreeContextMenuListener}
                        /> :
                        null
                    }
                </Box>
            );
        };

        const renderCheckableTree = (treeNode: TreeNode) => (
            <TreeItem
                key={treeNode.id}
                nodeId={treeNode.id}
                label={renderCheckboxNode(treeNode)}
            >
                {Array.isArray(treeNode.children)
                    ? treeNode.children.map(node => renderCheckableTree(node))
                    : null}
            </TreeItem>
        );

        const renderIconableTree = (treeNode: TreeNode) => (
            <MuiTreeItemDashed
                key={treeNode.id}
                nodeId={treeNode.id}
                label={renderIconNode(treeNode)}
            >
                {Array.isArray(treeNode.children)
                    ? treeNode.children.map(node => renderIconableTree(node))
                    : null}
            </MuiTreeItemDashed>
        );

        return (
            <>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    // defaultExpanded={props.defaultExpanded}
                    // defaultSelected={props.defaultSelected}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeSelect={handleSelect}
                    onNodeToggle={handleToggle}
                    multiSelect={props.multiSelect}
                >
                    {props.multiSelect ? renderCheckableTree(props.data) : renderIconableTree(props.data)}
                </TreeView>
                { render_popover() }
            </>        
        );
    }
);
export default WrappedMuiTreeView;