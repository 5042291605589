export default class Enumeration {

    public static ENV_DEVELOPMENT = 'development';
    public static ENV_TEST = 'test';
    public static ENV_PRODUCTION = 'production';

    public static STATUS_OFFLINE = 'offline';
    public static STATUS_ONLINE = 'online';

    public static ACCOUNT_HIDE = 'hide';
    public static ACCOUNT_SHOW = 'show';

    // Actions
    public static ACTION_ACTIVATION_SUSPENSION = "activation_suspension";
    public static ACTION_CREATE = "create";
    public static ACTION_DELETE = "delete";
    public static ACTION_DISPLAY = "display";
    public static ACTION_DOWNLOAD = "download";
    public static ACTION_LIST = "list";
    public static ACTION_UPDATE = "update";
    public static ACTION_VIEW = "view";

    // Alignements
    public static ALIGNEMENT_CENTER = 'center';
    public static ALIGNEMENT_LEFT = "left";
    public static ALIGNEMENT_RIGHT = "right";

    // Badge
    public static BADGE_DANGER = "danger";
    public static BADGE_INFO = "info";
    public static BADGE_PRIMARY = "primary";
    public static BADGE_SECONDARY = "secondary";
    public static BADGE_SUCCESS = "success";
    public static BADGE_WARNING = "warning";

    // Confidentialites
    public static CONFIDENTIALITE_PRIVATE = 'private';
    // public static CONFIDENTIALITE_PROTECTED = "protected";
    public static CONFIDENTIALITE_PUBLIC = "public";
    public static CONFIDENTIALITE_SHARE = "share";

    // Extensions
    public static EXTENSION_BMP = 'bmp';
    public static EXTENSION_DOC = 'doc';
    public static EXTENSION_DOCX = "docx";
    public static EXTENSION_GIF = "gif";
    public static EXTENSION_JPEG = "jpeg";
    public static EXTENSION_JPG = "jpg";
    public static EXTENSION_PDF = "pdf";
    public static EXTENSION_PNG = "png";
    public static EXTENSION_PPT = "ppt";
    public static EXTENSION_PPTX = "pptx";
    public static EXTENSION_SVG = "svg";
    public static EXTENSION_WEBP = "webp";
    public static EXTENSION_XLS = "xls";
    public static EXTENSION_XLSX = "xlsx";

    // Metadonnees
    public static METADONNEE_ADDRESS = "address";
    public static METADONNEE_CONTRIBUTOR = "contributor";
    public static METADONNEE_COVERAGE = "coverage";
    public static METADONNEE_CREATOR = "creator";
    public static METADONNEE_DATE = "date";
    public static METADONNEE_DESCRIPTION = "description";
    public static METADONNEE_EMAIL = "email";
    public static METADONNEE_FIRSTNAME = "firstname";
    public static METADONNEE_FORMAT = "format";
    public static METADONNEE_IDENTIFIER = "identifier";
    public static METADONNEE_LANGUAGE = "language";
    public static METADONNEE_NAME = "name";
    public static METADONNEE_PHONE = "phone";
    public static METADONNEE_PUBLISHER = "publisher";
    public static METADONNEE_RELATION = "relation";
    public static METADONNEE_RIGHTS = "rights";
    public static METADONNEE_SOURCE = "source";
    public static METADONNEE_SUBJECT = "subject";
    public static METADONNEE_TITLE = "title";
    public static METADONNEE_TYPE = "type";

    // Metaelements
    public static METAELEMENT_CHECKBOX = "checkboxfield";
    public static METAELEMENT_CHECKBOXES = "checkboxesfield";
    public static METAELEMENT_DATE = "datefield";
    public static METAELEMENT_DATETIME = "datetimefield";
    public static METAELEMENT_NUMBER = "numberfield";
    public static METAELEMENT_PASSWORD = "passwordfield";
    public static METAELEMENT_RADIO = "radiofield";
    public static METAELEMENT_SELECT = "selectfield";
    public static METAELEMENT_TEXTAREA = "textareafield";
    public static METAELEMENT_TEXTBOX = "textfield";
    public static METAELEMENT_TIME = "timefield";

    // Modules
    public static MODULE_ARCHIVAGE = "archivage";
    public static MODULE_COURRIER = "courrier";
    public static MODULE_DOSSIER = "dossier";
    public static MODULE_MAILS = "mails";

    // Nodes
    public static NODE_DOCUMENT = "document";
    public static NODE_DOSSIER = "dossier";
    public static NODE_RACINE = "racine";

    // Operations
    public static OPERATION_ACTIVATION = "activation";
    public static OPERATION_CREATION = "creation";
    public static OPERATION_DELETION = "deletion";
    public static OPERATION_DISPLAYING = "displaying";
    public static OPERATION_DOWNLOADING = "downloading";
    public static OPERATION_LISTING = "listing";
    public static OPERATION_LOGIN = "login";
    public static OPERATION_LOGOUT = "logout";
    public static OPERATION_MODIFICATION = "modification";
    public static OPERATION_SUSPENSION = "suspension";
    public static OPERATION_UPDATE_PASSWORD = "update_password";
    public static OPERATION_VIEWING = "viewing";

    // Organisations
    public static ORGANISATION_KIKUN = "Kikun Admin";
    public static ORGANISATION_KIKUN_ID = -1;

    // Periodes
    public static PERIODE_HEURE = "heure";
    public static PERIODE_JOUR = "jour";
    public static PERIODE_MOIS = "mois";
    public static PERIODE_SEMAINE = "semaine";

    public static PERIODE_HEURE_LABEL = "Heure";
    public static PERIODE_JOUR_LABEL = "Jour";
    public static PERIODE_MOIS_LABEL = "Mois";
    public static PERIODE_SEMAINE_LABEL = "Semaine";

    // Profils
    public static PROFIL_ADMIN = "admin";

    // Ressources
    public static RESSOURCE_ALL = 'all';

    // Central
    public static RESSOURCE_ACTION = "actions";
    public static RESSOURCE_CONFIDENTIALITE = "confidentialites";
    public static RESSOURCE_METAELEMENT = "metaelements";
    public static RESSOURCE_MODULE = "modules";
    public static RESSOURCE_ORGANISATION = "tenants";
    public static RESSOURCE_PAYS = "pays";
    public static RESSOURCE_RESSOURCE = "ressources";

    // Central & Tenant
    public static RESSOURCE_JOURNAL = "journals";
    public static RESSOURCE_NOTIFICATION = "notifications";
    public static RESSOURCE_UTILISATEUR = "users";

    // Tenant
    public static RESSOURCE_APPROBATION = "approbations";
    public static RESSOURCE_BRANCHE = "branches";
    public static RESSOURCE_CONFIGURATION = "configurations";
    public static RESSOURCE_DEPARTEMENT = "departements";
    public static RESSOURCE_DOCUMENT = "documents";
    public static RESSOURCE_DOSSIER = "dossiers";
    public static RESSOURCE_EXTENSION = "extensions";
    public static RESSOURCE_GROUPE = "groupes";
    public static RESSOURCE_METADONNEE = "metadonnees";
    public static RESSOURCE_METAVALEUR = "metavaleurs";
    public static RESSOURCE_PARTAGE = "partages";
    public static RESSOURCE_PERIMETRE = "perimetres";
    public static RESSOURCE_PERSONNEL = "personnels";
    public static RESSOURCE_PROFIL = "profils";
    public static RESSOURCE_ROLE = "roles";
    public static RESSOURCE_SERVEUR = "serveurs";
    public static RESSOURCE_TERMINOLOGIE = "terminologies";
    public static RESSOURCE_VERSION = "versions";
    public static RESSOURCE_WORKFLOW = "workflows";
    public static RESSOURCE_WORKFLOW_ACTIVITE = "workflow_activites";
    public static RESSOURCE_WORKFLOW_CONFIGURATION = "workflow_configurations";
    public static RESSOURCE_WORKFLOW_TYPE = "workflow_types";

    public static RESSOURCE_METADONNEE_CONFIGURATION = "configuration_metadonnee";
    
    public static RESSOURCE_ROLE_GROUPE = "groupe_role";
    public static RESSOURCE_ROLE_PROFIL = "profil_role";

    // Serveurs
    public static SERVEUR_SFTP = 'sftp';
    public static SERVEUR_SMTP = "smtp";


    // Statuts
    public static STATUT_APPROUVE = "approuve";
    public static STATUT_ATTENTE = "attente";
    public static STATUT_CONSULTE = "consulte";
    public static STATUT_ENCOURS = "encours";
    public static STATUT_REJETE = "rejete";
    public static STATUT_TERMINE = "termine";


    // Actions sur activite de workflow
    public static WORKFLOW_ACTION_APPROUVER = "approuver";
    public static WORKFLOW_ACTION_CONSULTER = "consulter";
    public static WORKFLOW_ACTION_REJETER = "rejeter";


    // Types de workflow
    public static WORKFLOW_APPROBATION_PARALLELE = "approbation_parallele";
    public static WORKFLOW_APPROBATION_SERIE = "approbation_serie";
    public static WORKFLOW_CONNAISSANCE_PARALLELE = "connaissance_parallele";
    public static WORKFLOW_CONNAISSANCE_SERIE = "connaissance_serie";
    
}