import { JsonProperty } from "@paddls/ts-serializer";

export interface IMetadonneeBranchePivot {
    metadonnee_id: number;
    branche_id: number;
    valeur: string;
}

export class MetadonneeBranchePivot implements IMetadonneeBranchePivot {
    [key: string]: any;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    branche_id: number = 0;

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeBranchePivot>) {
        Object.assign(this, init);
    }
}
