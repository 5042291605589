import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';

interface IProps extends SelectProps {
    items: any[];

    dummyValue: any;

    valueField?: string;
    labelField?: string | string[];

    errorText?: string;
    // marginBottom?: string;
    
    controlStyle?: SxProps<Theme>;
    inputStyle?: SxProps<Theme>;
    selectStyle?: SxProps<Theme>;
}

function MuiSelect(props: IProps) {
    // Variables
    const inputLabelId = `${props.id}-label`;
    const hasError = props.errorText !== undefined;
    // const marginBottom = props.marginBottom ? props.marginBottom : '0px';
    
    // Renders
    const render_select_value = (selected: any): JSX.Element => {
        // console.log("render_select_value", selected);
        if (Array.isArray(selected)) {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {
                        selected.map.length !== 0 && selected.map((elt: any) => {
                            // const key = elt[props.valueField as string];
                            const key = elt;
                            return (
                                <Chip key={key} label={getSelectedLabel(elt)} color='info'
                                    // disabled={props.disabled}
                                />
                            );
                        })
                    }
                </Box>
            );
        }
        else {
            return (
                // <Chip key={getItemId(selected)} label={getItemLabel(selected)} color='info' />
                <>
                    { getSelectedLabel(selected) }
                </>
            );
        }
    }

    // Handlers
    const getItemValue = (item: any): any => {
        return props.valueField !== undefined ? item[props.valueField] : item;
    }

    const getItemLabel = (item: any): string => {
        let label = "";
        if (!props.labelField) {
            label = item;
        }
        else if (!Array.isArray(props.labelField)) {
            label = props.labelField !== undefined ? item[props.labelField as string] : item;
        }
        else {
            const labelFields = props.labelField as string[];
            let labelField = labelFields[0], newLabel = "";

            label = props.labelField !== undefined ? item[labelField] : item;
            for (let i = 1; i < labelFields.length; i++) {
                labelField = labelFields[i];
                newLabel = props.labelField !== undefined ? item[labelField] : item;
                if (newLabel !== "") {
                    label = label !== "" ? `${label} - ${newLabel}` : newLabel;
                }
            }
        }

        return label;
    }

    const getItemIsChecked = (item: any): boolean => {
        const value = props.value as any;
        if (!Array.isArray(value)) {
            return props.valueField !== undefined ? value === item[props.valueField] : value === item;
        }
        else {
            let index = value.findIndex(
                (elt: any) => props.valueField !== undefined ? elt === item[props.valueField as string] : elt === item
            );
            return index !== -1;
        }
    }

    const getSelectedLabel = (selected: any): string => {
        let index = props.items.findIndex(
            (item: any) => props.valueField !== undefined ? item[props.valueField as string] === selected : item === selected
        );

        return index !== -1 ? getItemLabel(props.items[index]) : "";
    }

    const getSelectedValue = (selected: any): any => {
        if (!Array.isArray(selected)) {
            return selected === props.dummyValue ? "" : selected;
        }
        else {
            return selected.length === 0 ? [] : selected;
        }
    }

    // const style: React.CSSProperties = { marginBottom: marginBottom };

    return (
        <FormControl variant="outlined" fullWidth={true} required={props.required} error={hasError} sx={props.controlStyle}>
            <InputLabel id={inputLabelId} sx={props.inputStyle}>{props.label}</InputLabel>
            <Select
                multiple={props.multiple} disabled={props.disabled}
                labelId={inputLabelId} label={props.label}
                placeholder={props.placeholder}
                id={props.id} name={props.name}
                onChange={props.onChange} sx={props.selectStyle}
                value={getSelectedValue(props.value)}
                renderValue={(selected: any) => render_select_value(selected)}
            >
                {
                    props.items.length !== 0 && props.items.map((item: any) => (
                        <MenuItem key={getItemValue(item)} value={getItemValue(item)}>
                            <Checkbox checked={getItemIsChecked(item)} />
                            <ListItemText primary={getItemLabel(item)} />
                        </MenuItem>
                    ))
                }
            </Select>
            <FormHelperText>{hasError ? props.errorText : ''}</FormHelperText>
        </FormControl>
    );
}
export default MuiSelect;
