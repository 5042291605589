import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Breakpoint, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

export type TWidth = Breakpoint | false;

export interface BootstrapDialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
  
const BootstrapDialogTitle = (props: BootstrapDialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {
                onClose ? (
                    <IconButton aria-label="close" onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null
            }
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

interface IProps extends DialogProps {
    winTitle: any,
    winContent: any,
    winActions?: any,
    winTitleId: string,
}

interface IState {
    [key: string]: any,

    isOpen: boolean,
    maxWidth?: TWidth,

    winTitle: any,
    winContent: any,
    winActions?: any,
    winTitleId: string,
}

export interface IWinProps {
    maxWidth?: TWidth,

    winTitle?: any,
    winContent?: any,
    winActions?: any,
    winTitleId?: string,
}

export interface IMuiWindow {
    open: (props?: IWinProps) => void,
    close: () => void,
}

const WrappedMuiWindow = React.forwardRef(
    function MuiWindow(props: IProps, ref: React.ForwardedRef<IMuiWindow>) {
        // Variables
        const defaultState: IState = {
            isOpen: props.open ? props.open : false,
            maxWidth: props.maxWidth ? props.maxWidth : "lg",

            winTitle: props.winTitle,
            winContent: props.winContent,
            winTitleId: props.winTitleId,
        };

        // Hooks
        const didMount = useRef(false);
        const closeWin = useRef(false);
        
        const [state, setState] = useState(defaultState);

        useEffect(() => {
            // Return early, if this is the first render:
            if (!didMount.current) {
                didMount.current = true;
                return;
            }

            if (closeWin.current && props.onClose) {
                closeWin.current = false;
                props.onClose({}, "backdropClick");
            }
        });

        useImperativeHandle(ref, () => ({
            open, close//, setTitle, setContent
        }));

        // Handlers
        const open = (props?: IWinProps) => {
            // console.log("open", props);
            const maxWidth = props && props.maxWidth ? props.maxWidth : state.maxWidth;
            const winTitle = props && props.winTitle ? props.winTitle : state.winTitle;
            const winContent = props && props.winContent ? props.winContent : state.winContent;
            const winActions = props && props.winActions ? props.winActions : state.winActions;
            const winTitleId = props && props.winTitleId ? props.winTitleId : state.winTitleId;

            setState({...state, isOpen: true, maxWidth, 
                winTitle, winContent, winActions, winTitleId
            });
        }
    
        const close = () => {
            closeWin.current = true;
            
            setState({...state, isOpen: false});
        }

        const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            // console.log("handleClose", event, reason);
            const isNotBackdropClick = reason !== "backdropClick";
            const isNoteEscapeKeyDown = reason !== "escapeKeyDown";
            if (isNotBackdropClick && isNoteEscapeKeyDown) {
                close();
            }
        }
    
        // const setTitle = (title: any) => {
        //     setState({...state, winTitle: title});
        // }
    
        // const setContent = (content: any) => {
        //     setState({...state, winContent: content});
        // }

        // Renders
        const render_actions = () => {
            if (!state.winActions) return null;
            return (
                <DialogActions>
                    {state.winActions}
                </DialogActions>
            );
        }

        const render_window = () => {
            return (
                <BootstrapDialog open={state.isOpen} onClose={handleClose} aria-labelledby={state.winTitleId}
                    fullWidth={props.fullWidth} maxWidth={state.maxWidth}>
                    <BootstrapDialogTitle id={state.winTitleId} onClose={close}>
                        {state.winTitle}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        {state.winContent}
                    </DialogContent>
                    { render_actions() }
                </BootstrapDialog>
            );
        }
        
        return render_window();
    }
);

export default WrappedMuiWindow;
