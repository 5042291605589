import { JsonProperty } from "@paddls/ts-serializer";

export interface IMetadonneeDepartementPivot {
    metadonnee_id: number;
    departement_id: number;
    valeur: string;
}

export class MetadonneeDepartementPivot implements IMetadonneeDepartementPivot {
    [key: string]: any;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    departement_id: number = 0;

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeDepartementPivot>) {
        Object.assign(this, init);
    }
}
