import { JsonProperty } from "@paddls/ts-serializer";

export interface IGroupePersonnelPivot {
    groupe_id: number;
    personnel_id: number;
}

export class GroupePersonnelPivot implements IGroupePersonnelPivot {
    @JsonProperty()
    groupe_id: number = 0;

    @JsonProperty()
    personnel_id: number = 0;

    public constructor(init?: Partial<IGroupePersonnelPivot>) {
        Object.assign(this, init);
    }
}
