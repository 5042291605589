/* eslint-disable jsx-a11y/anchor-is-valid */
import { Theme } from '@emotion/react';
import DeleteIcon from '@mui/icons-material/Delete';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CountryCode } from 'libphonenumber-js';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { MuiTelInput } from 'mui-tel-input';
import React from 'react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { NumericFormat } from 'react-number-format';
import { FormFeedback, Input, Label } from "reactstrap";
import { dateFormat, dateTimeFormat, dateTimeToDate, dateTimeToTimeSpan, timeFormat } from "../helpers/InputHelpers";
import { anyToBoolean, valueIsBoolean } from "../helpers/NumberHelper";
import { trans } from '../helpers/TransHelper';
import { FileView } from '../models/FileView';
import FileViewer from './commons/FileViewer';
import MuiAutocomplete from './commons/MuiAutocomplete';
import MuiIconButton, {
    TAnchorClickEvent, TButtonClickEvent, TButtonColor, TButtonEdge,
    TButtonRef, TButtonSize, TButtonVariant, TSpanClickEvent
} from './commons/MuiIconButton';
import MuiSelect from './commons/MuiSelect';
import MuiTreeView, { IMuiTreeView, TreeContextMenuActions, TreeContextMenuListener, TreeNode } from './commons/MuiTreeView';
import { requiredElement } from './styles/Commons';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const NumberFormatCustom = React.forwardRef<typeof NumericFormat, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;
  
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: any) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={" "}
                valueIsNumericString
                // prefix="$"
            />
        );
    },
);

// Types
export type TBgColor = "blue" | "cyan" | "danger" | "dark" | "gray" | "gray-dark"
    | "green" | "indigo" | "info" | "light" | "pink" | "primary" | "purple" | "red"
    | "secondary" | "success"| "orange" | "teal" | "transparent" | "warning" | "white" | "yellow";

export type TAlertColor = "error" | "info" | "success" | "warning";

export type TCardColor = "primary" | "secondary" | "info" | "success" | "warning" | "danger";

export type TChipColor = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
export type TChipSize = "small" | "medium";
export type TChipVariant = "filled" | "outlined";

export type TDeleteClickHandler = (event: TButtonClickEvent, guid: string) => void;

export type TAutocompleteChangeReason = "createOption" | "selectOption" | "removeOption" | "clear" | "blur";

export type TLeading = "allow" | "deny" | "keep";

export type TLinkClick = (event: TLinkClickEvent, name: string, field?: string) => void;
export type TLinkClickEvent = TAnchorClickEvent | TSpanClickEvent;
export type TLinkUnderline = "none" | "hover" | "always";

export type TPlacement = "start" | "end" | "top" | "bottom";
export type TPosition = "right" | "left" | "center";
export type TSignPlacement = "l" | "r" | "p" | "s";
export type TStackDirection = "row" | "row-reverse" | "column" | "column-reverse";

// Objects
export interface IListItem {
    id: number;
    label: string;
    value: any;
}

export interface ISelectItem {
    fieldName: string;
    listName: string;
}

// Params
export interface IAutocompleteParams {
    stateMember: string;

    name: string;
    value: any;
    items: any[];
    
    labelField?: string | string[];
    valueField?: string;

    id?: string;
    dummyValue?: any;

    limitTags?: number;
    
    label?: string;
    placeholder?: string;

    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    
    errorText?: string;

    onChange?: (option: any, reason: TAutocompleteChangeReason, fieldToSet: string, stateMember: string, isMulti: boolean, valueField?: string) => void;
    onDeleteValue?: (option: string, fieldToSet: string, stateMember: string, isMulti: boolean, valueField?: string) => void;
    // onInputChange?: (value: string, fieldToSet: string, stateMember: string, isMulti: boolean, valueField?: string) => void;
}

export interface IButtonParams {
    label: string;
    name: string;
    
    ariaLabel?: string;
    children?: React.ReactNode;
    color?: TButtonColor;
    disabled?: boolean;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    href?: string;
    ref?: TButtonRef;
    size?: TButtonSize;
    startIcon?: React.ReactNode;
    variant?: TButtonVariant;

    onClick?: (event: TButtonClickEvent, name: string) => void;
}

export interface ICheckboxParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;

    label?: string;

    disabled?: boolean;
    key?: any;
    multiple?: boolean;
    style?: React.CSSProperties;

    onChange?: (checked: boolean, fieldToSet: string, stateMember: string, isMulti: boolean, value?: string) => void;
}

export interface ICheckboxesParams extends ICheckboxParams {
    items: IListItem[];

    errorText?: string;

    required?: boolean;
    
    row?: boolean;
    style?: React.CSSProperties;
}

export interface IChipParams {
    avatar?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    color?: TChipColor;
    disabled?: boolean;
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    key?: React.Key;
    label?: React.ReactNode;
    size?: TChipSize;
    variant?: TChipVariant;
    // onDelete?: (event: any, key?: React.Key) => void;
}

export interface IChipsParams {
    items: IChipParams[];
    direction?: TStackDirection;
    label?: string;
    required?: boolean;
}

export interface IDatePickerParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;

    format?: string;
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    disableFuture?: boolean;

    errorText?: string;

    onChange?: (pickerType: string, date: any, fieldToSet: string, stateMember: string) => void;
}

export interface IDatetimePickerParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;

    format?: string;
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    disableFuture?: boolean;

    errorText?: string;

    onChange?: (pickerType: string, date: any, fieldToSet: string, stateMember: string) => void;
}

export interface IIconButtonParams {
    id: string;
    label: string;
    name: string;
    icon: React.ReactNode;
    
    ariaLabel?: string;
    color?: TButtonColor;
    disabled?: boolean;
    edge?: TButtonEdge;
    ref?: TButtonRef;
    size?: TButtonSize;
    tabIndex?: number;
    
    onClick: (event: TButtonClickEvent, name: string, id: string) => void;
}

export interface IImageCardParams {
    guid: string;
    image: string;
    alt: string;
    title?: string;
    cardStyle?: React.CSSProperties;
    mediaStyle?: React.CSSProperties;
    onDeleteClick?: TDeleteClickHandler;
}

export interface ILinkParams {
    name: string;
    field?: string;

    child?: React.ReactNode;
    className?: string;
    href?: string;
    underline?: TLinkUnderline;

    onClick?: TLinkClick;
}

export interface ILteCardParams {
    title?: string;
    color?: TCardColor;
    maximize?: boolean;
    collapse?: boolean;
    content: any;
    refresh?: React.MouseEventHandler<HTMLButtonElement>
}

export interface IPasswordParams {
    stateMember: string;
    showMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;
    showPassword: boolean;

    errorText?: string;

    tabIndex?: number;
    tabIndexAdornment?: number;

    onChange?: (event: any, fieldToSet: string, stateMember: string) => void;
    onShowHide?: (event: any, fieldToSet: string, stateMember: string) => void;
}

export interface IPhoneParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    defaultCountry?: CountryCode;
    
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;

    onChange?: (value: any, country: CountryCode | null, fieldToSet: string, stateMember: string) => void;
}

export interface IRadioGroupParams {
    stateMember: string;
    items: IListItem[];
    
    name: string;
    value: any;
    
    id?: string;
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;
    
    row?: boolean, style?: React.CSSProperties;

    onChange?: (radioValue: any, fieldToSet: string, stateMember: string) => void;
}

export interface ISearchParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    type?: string;
    
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;

    numberFormat?: boolean;
    
    onChange?: (event: any, fieldToSet: string, numberFormat: boolean, stateMember: string) => void;
    onClick?: (event: any, fieldToSet: string, numberFormat: boolean, stateMember: string) => void;
    onMouseDown?: (event: any, fieldToSet: string, stateMember: string) => void;
}

export interface ISelectParams {
    stateMember: string;
    
    name: string;
    value: any;
    items: any[];
    
    labelField: string | string[];
    valueField: string;
    
    id?: string;
    dummyValue?: any;
    
    // limitTags?: number;
    
    label?: string;
    placeholder?: string;

    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;

    errorText?: string;

    controlStyle?: SxProps<Theme>;
    inputStyle?: SxProps<Theme>;
    selectStyle?: SxProps<Theme>;

    onChange: (event: any, valueField: string, fieldToSet: string, stateMember: string, isMulti: boolean) => void;
}

export interface ISmallBoxParams {
    color: TCardColor;
    value: string;
    percent?: boolean;
    label: string;
    icon: string;
    name: string;
    field?: string;
    moreInfo: (event: TLinkClickEvent, name: string, field?: string) => void;
}

export interface ISwitchParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    placement?: TPlacement;

    label?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;

    onChange?: (checked: boolean, fieldToSet: string, stateMember: string) => void;
}

export interface ITextAreaParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    style?: React.CSSProperties;

    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;
    
    onChange?: (event: any, fieldToSet: string, stateMember: string) => void;
}

export interface ITextFieldParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    type?: string;
    inputProps?: any;

    inputRef?: React.Ref<any>;
    style?: SxProps<Theme>;

    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;
    multiline?: boolean;

    errorText?: string;

    startAdornment?: string;
    endAdornment?: string;

    numberFormat?: boolean;

    onChange?: (event: any, fieldToSet: string, numberFormat: boolean, stateMember: string) => void;
    onKeyPress?: (event: any) => void;
}

export interface ITimePickerParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;

    format?: string;
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;

    onChange?: (pickerType: string, date: any, fieldToSet: string, stateMember: string) => void;
}

export interface ITimelineItem {
    key: number;
    date: Date;
    items: ITimelineItemParams[];
}

export interface ITimelineItemParams {
    id: number;
    
    actor: string;
    date: Date;
    message: string;
    
    color?: TBgColor;
    icon?: string;

    body?: React.ReactNode;
    footer?: React.ReactNode;
}

export interface ITreeViewParams {
    stateMember: string;
    
    id: string;
    name: string;
    data: TreeNode;
    
    ref?: React.RefObject<IMuiTreeView>;
    disabled?: boolean;
    selectOnToggle?: boolean;
    toggleOnSelect?: boolean;
    
    multiSelect?: boolean;
    defaultExpanded?: string[];
    defaultSelected?: string | string[];
    
    contextMenuActions?: TreeContextMenuActions;
    contextMenuListener?: TreeContextMenuListener;
    
    onNodeSelect?: (event: React.SyntheticEvent, nodeIds: string | string[], fieldToSet: string, stateMember: string) => void;
    onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[], fieldToSet: string, stateMember: string) => void;
    
    onToggle?: (event: React.SyntheticEvent, nodeIds: string[], nodeId: string, isExpand: boolean, fieldToSet: string, stateMember: string) => void;
}

export interface IUploadFileParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    accept?: string;

    text?: string;
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;

    isBase64?: boolean;
    withMarker?: boolean;

    errorText?: string;

    onChange?: (event: any, fieldToSet: string, isMulti: boolean, isBase64: boolean, withMarker: boolean, stateMember: string) => void;
}

export interface IUserParams {
    stateMember: string;
    
    name: string;
    value: any;
    
    id?: string;
    
    label?: string;
    placeholder?: string;

    required?: boolean;
    disabled?: boolean;

    errorText?: string;

    tabIndex?: number;
    tabIndexAdornment?: number;
    
    onChange?: (event: any, fieldToSet: string, stateMember: string) => void;
}

export interface IViewerCardParams {
    guid: string;
    model: FileView;
    title?: string;
    embedded?: boolean;
    cardStyle?: React.CSSProperties;
    viewerStyle?: React.CSSProperties;
    frameStyle?: React.CSSProperties;
    onDeleteClick?: TDeleteClickHandler;
}

export interface IViewerParams {
    model: FileView;
    embedded?: boolean;
    viewerStyle?: React.CSSProperties;
    frameStyle?: React.CSSProperties;
}

// Functions
function render_card_action(guid: string, onDeleteClick?: TDeleteClickHandler) {
    if (!onDeleteClick) return null;

    return (
        <IconButton aria-label="delete image"
            onClick={(event: TButtonClickEvent) => {
                if (onDeleteClick !== undefined) {
                    onDeleteClick(event, guid);
                }
            }}
        >
            <DeleteIcon />
        </IconButton>
    );
}

function render_time_label(label: string, color: TBgColor = "pink") {
    return (
        <div className="time-label">
            <span className={"bg-" + color}>{label}</span>
        </div>
    );
}

function render_timeline_item(params: ITimelineItemParams) {
    const headerClassName = "timeline-header" + (params.body ? "" : " no-border");
    const bgClassName = "bg-" + (params.color ? params.color : "blue");
    const iconClassName = "fas fa-" + (params.icon ? params.icon : "user");
    return (
        <div key={params.id}>
            <i className={iconClassName + " " + bgClassName}></i>
            <div className="timeline-item">
                <span className="time"><i className="fas fa-clock" /> {dateTimeToTimeSpan(params.date, "HH:mm")}</span>
                <h3 className={headerClassName}><a href="#">{params.actor}</a> {params.message}</h3>
                {params.body && 
                    <div className="timeline-body">
                        {params.body}
                    </div>
                }
                {params.footer &&
                    <div className="timeline-footer">
                        {params.footer}
                    </div>
                }
            </div>
        </div>
    );
}

export function getDisplayName(WrappedComponent: any) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function render_alert(message?: string, severity: TAlertColor = "error") {
    if (!message) return null;
    return (
        <Alert variant="outlined" severity={severity}>
            {message}
        </Alert>
    );
}

export function render_autocomplete_input(params: IAutocompleteParams) {
    const isMulti = params.multiple !== undefined ? params.multiple : false;
    const hasItems = params.items.length > 0;
    const value = hasItems ? params.value : (isMulti ? [] : "");
    const dummyValue = params.dummyValue ? params.dummyValue : 0;
    return (
        <MuiAutocomplete
            label={params.label} placeholder={params.placeholder}
            name={params.name} id={params.id}
            required={params.required} disabled={params.disabled}
            multiple={params.multiple} limitTags={params.limitTags}

            items={params.items} value={value} dummyValue={dummyValue}
            onChange={(_event, value, reason, _details) => {
                if (params.onChange !== undefined) {
                    params.onChange(value, reason, params.name, params.stateMember, isMulti, params.valueField);
                }
            }}
            onDeleteValue={(_event, value) => {
                if (params.onDeleteValue !== undefined) {
                    params.onDeleteValue(value, params.name, params.stateMember, isMulti, params.valueField);
                }
            }}
            // onInputChange={(_event, value: string, _reason) => {
            //     if (params.onInputChange !== undefined) {
            //         params.onInputChange(value, params.name, params.stateMember, isMulti, params.valueField);
            //     }
            // }}
        
            labelField={params.labelField} valueField={params.valueField}
        
            errorText={params.errorText}
        />
    );
}

export function render_button(params: IButtonParams) {
    return (
        <Button ref={params.ref} href={params.href}
            startIcon={params.startIcon} endIcon={params.endIcon}
            fullWidth={params.fullWidth} disabled={params.disabled}
            variant={params.variant} color={params.color} size={params.size}
            onClick={(event) => {
                if (params.onClick !== undefined) {
                    params.onClick(event, params.name);
                }
            }}
        >
            { params.children ? params.children : params.label }
        </Button>
    );
}

export function render_checkbox_input(params: ICheckboxParams) {
    let checked = false;
    const isMulti = params.multiple !== undefined ? params.multiple : false;
    
    if (isMulti) {
        let index = params.value.findIndex(
            (item: any) => item === params.label
        );

        checked = (index !== -1);
    }
    else {
        checked = valueIsBoolean(params.value) ? anyToBoolean(params.value) : false;
    }
    
    return (
        <FormControlLabel key={params.key ? params.key : params.id}
            control={
                <Checkbox
                    id={params.id} name={params.name} 
                    disabled={params.disabled} checked={checked}
                    onChange={(_event, checked) => {
                        if (params.onChange !== undefined) {
                            params.onChange(checked, params.name, params.stateMember, isMulti, params.label);
                        }
                    }}
                />
            }
            label={params.label}
            style={params.style}
        />
    );
}

export function render_checkboxes_input(params: ICheckboxesParams) {
    const hasError = params.errorText !== undefined;
    return (
        <FormControl component="fieldset" fullWidth={true} required={params.required}>
            <FormLabel component="legend">{params.label}</FormLabel>
            <FormGroup row={params.row}>
                {params.items.map((item: IListItem) => {
                    const checkboxParams: ICheckboxParams = {
                        stateMember: params.stateMember,
                        name: params.name,
                        value: params.value,
                        id: params.id,
                        label: item.label,
                        disabled: params.disabled,
                        key: item.id,
                        multiple: params.multiple,
                        style: params.style,
                        onChange: params.onChange,
                    };
                    return render_checkbox_input(checkboxParams);
                })}
            </FormGroup>
            <FormHelperText
                children={hasError ? params.errorText : ""}
                error={hasError}
            />
        </FormControl>
    );
}

export function render_chip_element(params: IChipParams): JSX.Element {
    return (
        <Chip
            style={{flex: '1 1 auto', margin: '5px'}}
            key={params.key}
            avatar={params.avatar}
            color={params.color}
            disabled={params.disabled}
            icon={params.icon}
            label={params.label}
            size={params.size}
            variant={params.variant}
            // onDelete={(_event) => {
            //     if (params.onDelete !== undefined) {
            //         params.onDelete(params.key, params.stateMember);
            //     }
            // }}
        />
    );
}

export function render_chips_list(params: IChipsParams): JSX.Element {
    const direction = params.direction ? params.direction : "row";
    return (
        <>
            <Label>{params.label} {render_required_element(params.required)}</Label>    
            <div style={{display: 'flex', flexDirection: direction, flexWrap: 'wrap', alignItems: 'stretch'}}>
                {
                    params.items.map((item: IChipParams) => {
                        return render_chip_element(item);
                    })
                }
            </div>
        </>
    );
}

export function render_date_picker_input(params: IDatePickerParams) {
    const hasError = params.errorText !== undefined;
    return (
        <DatePicker
            label={params.label} value={params.value} disabled={params.disabled}
            disablePast={params.disablePast} disableFuture={params.disableFuture}
            inputFormat={params.format ? params.format : dateFormat}
            onChange={(date: any) => {
                if (params.onChange !== undefined) {
                    params.onChange('DatePicker', date, params.name, params.stateMember);
                }
            }}
            renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                    {...inputProps} fullWidth={true}
                    placeholder={params.placeholder}
                    name={params.name} id={params.id}
                    required={params.required} disabled={params.disabled}
                    error={hasError} helperText={hasError ? params.errorText : ''}
                />
            )}
        />
    );
}

export function render_datetime_picker_input(params: IDatetimePickerParams) {
    const hasError = params.errorText !== undefined;
    return (
        <DateTimePicker
            label={params.label} value={params.value} disabled={params.disabled}
            disablePast={params.disablePast} disableFuture={params.disableFuture}
            inputFormat={params.format ? params.format : dateTimeFormat} ampm={false}
            mask="__/__/____ __:__:__"
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            onChange={(date: any) => {
                if (params.onChange !== undefined) {
                    params.onChange('DateTimePicker', date, params.name, params.stateMember);
                }
            }}
            renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                    {...inputProps} fullWidth={true}
                    placeholder={params.placeholder}
                    name={params.name} id={params.id}
                    required={params.required} disabled={params.disabled}
                    error={hasError} helperText={hasError ? params.errorText : ''}
                />
            )}
        />
    );
}

export function render_icon_button(params: IIconButtonParams) {
    return (
        <MuiIconButton
            ariaLabel={params.ariaLabel}
            ref={params.ref} label={params.label}
            disabled={params.disabled} icon={params.icon}
            color={params.color} size={params.size}
            edge={params.edge} tabIndex={params.tabIndex}
            onClick={(event) => {
                if (params.onClick !== undefined) {
                    params.onClick(event, params.name, params.id);
                }
            }}
        />
    );
}

export function render_image_actions_card(params: IImageCardParams) {
    const hasHeader = params.title || params.onDeleteClick;
    return (
        <Card style={params.cardStyle}>
            <ReactPanZoom
                image={params.image}
                alt={params.alt}
            />
            {
                hasHeader &&
                <CardActions disableSpacing
                    style={{fontSize: '1.1rem', backgroundColor: 'lightgray'}}>
                    <Typography>{params.title}</Typography>
                    <Grid container justifyContent="flex-end">
                        {render_card_action(params.guid, params.onDeleteClick)}
                    </Grid>
                </CardActions>
            } 
        </Card>
    );
}

export function render_image_card(params: IImageCardParams) {
    const hasHeader = params.title || params.onDeleteClick;
    return (
        <Card style={params.cardStyle}>
            {
                hasHeader &&
                <CardHeader
                    action={render_card_action(params.guid, params.onDeleteClick)}
                    title={params.title}
                    disableTypography={true}
                    style={{fontSize: '1.1rem', backgroundColor: 'lightgray'}}
                />
            }
            <CardMedia
                component="img"
                image={params.image}
                alt={params.alt}
                style={params.mediaStyle}
            />
        </Card>
    );
}

export function render_link(params: ILinkParams) {
    return (
        <Link href={params.href} className={params.className} underline={params.underline}
            onClick={(event) => {
                if (params.onClick !== undefined) {
                    params.onClick(event, params.name, params.field);
                }
            }}
        >
            { params.child }
        </Link>
    );
}

export function render_lte_card(params: ILteCardParams) {
    const hasTools = params.refresh || params.maximize || params.collapse;
    const hasHeader = params.title || hasTools;
    const cardClassName = "card" + (params.color ? ` card-${params.color}` : "");
    return (
        <div className={cardClassName}>
            {hasHeader && 
                <div className="card-header">
                    {params.title && 
                        <h3 className="card-title">{params.title}</h3>
                    }
                    {hasTools && 
                        <div className="card-tools">
                            {params.refresh && 
                                <button type="button" className="btn btn-tool" onClick={params.refresh}>
                                    <i className="fas fa-sync-alt" />
                                </button>
                            }
                            {params.maximize && 
                                <button type="button" className="btn btn-tool" data-card-widget="maximize">
                                    <i className="fas fa-expand" />
                                </button>
                            }
                            {params.collapse && 
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                            }
                        </div>
                    }
                </div>
            }
            
            <div className="card-body">
                {params.content}
            </div>
        </div>
    );
}

export function render_password_input(params: IPasswordParams) {
    const hasError = params.errorText !== undefined;
    const required = render_required_element(params.required);
    const label = required ? params.label + "" + required : params.label;
    return (
        <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor={params.name}>{params.label} {required}</InputLabel>
            <OutlinedInput
                id={params.id} name={params.name}
                fullWidth={true} placeholder={params.placeholder}
                required={params.required} disabled={params.disabled}
                type={params.showPassword ? 'text' : 'password'}
                value={params.value !== null ? params.value : ""}
                tabIndex={params.tabIndex}
                onChange={(event) => {
                    if (params.onChange !== undefined) {
                        params.onChange(event, params.name, params.stateMember);
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={(event) => {
                                if (params.showMember === undefined) {
                                    throw new Error('render_password_input must be used within a showMember props')
                                }
                                else if (params.onShowHide !== undefined) {
                                    params.onShowHide(event, params.name, params.showMember);
                                }
                            }}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.preventDefault();
                            }}
                            edge="end" tabIndex={params.tabIndexAdornment}
                        >
                        {params.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
                error={hasError}
            />
            <FormHelperText
                children={hasError ? params.errorText : ""}
                error={hasError}
            />
        </FormControl>
    );
}

export function render_phone_input(params: IPhoneParams) {
    const hasError = params.errorText !== undefined;
    return (
        <MuiPhoneNumber variant="outlined"
            name={params.name} id={params.id}
            label={params.label} placeholder={params.placeholder}
            required={params.required} disabled={params.disabled}
            fullWidth={true} defaultCountry={params.defaultCountry}
            // style={{ marginBottom: '15px' }}
            sx={{ '& svg': { height: '1em', }, }}              
            value={params.value !== null ? params.value : ""}
            onChange={(value) => {
                // console.log("render_phone_input->onChange", {value, params});
                if (params.onChange !== undefined) {
                    params.onChange(value, null, params.name, params.stateMember);
                }
            }}
            error={hasError} helperText={hasError ? params.errorText : ""}
        />
    );
}

export function render_phonetel_input(params: IPhoneParams) {
    const hasError = params.errorText !== undefined;
    return (
        <MuiTelInput
            variant="outlined"
            name={params.name} id={params.id}
            label={params.label} placeholder={params.placeholder}
            required={params.required} disabled={params.disabled}
            fullWidth={true} defaultCountry={params.defaultCountry}
            value={params.value !== null ? params.value : ""}
            onChange={(value, info) => {
                // console.log("render_phonetel_input->onChange", {value, info, params});
                if (params.onChange !== undefined) {
                    params.onChange(value, info.countryCode, params.name, params.stateMember);
                }
            }}
            error={hasError} helperText={hasError ? params.errorText : ""}
        />
    );
}

export function render_radio_group_input(params: IRadioGroupParams) {
    const hasError = params.errorText !== undefined;
    return (
        <FormControl component="fieldset" fullWidth={true} required={params.required}>
            <FormLabel component="legend">{params.label}</FormLabel>
            <RadioGroup row={params.row}
                name={params.name} id={params.id}
                value={params.value}
                onChange={(_event, value) => {
                    if (params.onChange !== undefined) {
                        params.onChange(value, params.name, params.stateMember);
                    }
                }}
            >
                {params.items.map(function (item: IListItem) {
                    return (
                        <FormControlLabel 
                            key={item.id} value={item.value}
                            label={item.label}
                            control={<Radio disabled={params.disabled} />}
                            style={params.style}
                        />
                    );
                })}
            </RadioGroup>
            <FormHelperText
                children={hasError ? params.errorText : ""}
                error={hasError}
            />
        </FormControl>
    );
}

export function render_required_element(required?: boolean) {
    if (!required) return null;
    return <span style={requiredElement}>*</span>
}

export function render_search_input(params: ISearchParams) {
    const hasError = params.errorText !== undefined;
    const numberFormat = params.numberFormat ? params.numberFormat : false;
    return (
        <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor={params.name}>{params.label} {render_required_element(params.required)}</InputLabel>
            <OutlinedInput
                type={params.type}
                name={params.name} id={params.id}
                placeholder={params.placeholder}
                required={params.required} disabled={params.disabled}
                fullWidth={true}
                // style={{ marginBottom: '15px' }}
                value={params.value !== null ? params.value : ""}
                onChange={(event) => {
                    if (params.onChange !== undefined) {
                        params.onChange(event, params.name, numberFormat, params.stateMember);
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            disabled={params.disabled}
                            onClick={(event) => {
                                if (params.onClick !== undefined) {
                                    params.onClick(event, params.name, numberFormat, params.stateMember);
                                }
                            }}
                            onMouseDown={(event) => {
                                if (params.onMouseDown !== undefined) {
                                    params.onMouseDown(event, params.name, params.stateMember);
                                }
                            }}
                            edge="end" >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                label={params.label}
                error={hasError}
            />
            <FormHelperText
                children={hasError ? params.errorText : ""}
                error={hasError}
            />
        </FormControl>
    );
}

export function render_select_input(params: ISelectParams) {
    const isMulti = params.multiple !== undefined ? params.multiple : false;
    const hasItems = params.items.length > 0;
    const value = hasItems ? params.value : (isMulti ? [] : "");
    return (
        <MuiSelect
            label={params.label} placeholder={params.placeholder}
            name={params.name} id={params.id}
            required={params.required} disabled={params.disabled}
            multiple={params.multiple}

            items={params.items} value={value} dummyValue={params.dummyValue}

            controlStyle={params.controlStyle} inputStyle={params.inputStyle}
            selectStyle={params.selectStyle}

            onChange={(event) => {
                if (params.onChange !== undefined) {
                    params.onChange(event, params.valueField, params.name, params.stateMember, isMulti);
                }
            }}
        
            labelField={params.labelField} valueField={params.valueField}
        
            errorText={params.errorText}
        />
    );
}

export function render_small_box(params: ISmallBoxParams) {
    const boxClassName = `small-box bg-${params.color}`;
    const iconClassName = `fas fa-${params.icon}`;
    const moreInfoText = trans('more_info');

    const linkParams: ILinkParams = {
        name: params.name, field: params.field,
        href: "#", onClick: params.moreInfo,
        className: "small-box-footer", underline: "none",
        child: <>{ moreInfoText } <i className="fas fa-arrow-circle-right" /></>
    };

    return (
        <div className={boxClassName}>
            <div className="inner">
                <h3>{params.value}{params.percent ? <sup style={{fontSize: 20}}>%</sup> : ""}</h3>
                <p>{params.label}</p>
            </div>
            <div className="icon">
                <i className={iconClassName} />
            </div>
            {render_link(linkParams)}
        </div>
    );
}

export function render_switch_input(params: ISwitchParams) {
    return (
        <FormControlLabel
            control={
                <Switch
                    // inputProps={{ 'aria-label': 'controlled' }}
                    id={params.id} name={params.name}
                    required={params.required} disabled={params.disabled}
                    checked={params.value}
                    onChange={(_event, checked) => {
                        if (params.onChange !== undefined) {
                            params.onChange(checked, params.name, params.stateMember);
                        }
                    }}
                />
            }
            label={params.label}
            labelPlacement={params.placement}
        />
    );
}

export function render_text_area_input(params: ITextAreaParams) {
    const hasError = params.errorText !== undefined;
    const inputStyle = params.style ? params.style : { height: "93px" };
    return (
        <>
            <Label for={params.name}>{params.label} {render_required_element(params.required)}</Label>
            <Input type="textarea" style={inputStyle}
                placeholder={params.placeholder}
                name={params.name} id={params.id} disabled={params.disabled}
                value={params.value !== null ? params.value : ""}
                onChange={(event) => {
                    if (params.onChange !== undefined) {
                        params.onChange(event, params.name, params.stateMember);
                    }
                }}
                invalid={hasError ? true : undefined}
            />
            <FormFeedback>{params.errorText}</FormFeedback>
        </>
    );
}

export function render_text_field_input(params: ITextFieldParams) {
    const hasError = params.errorText !== undefined;
    const startAdornment = params.startAdornment;
    const endAdornment = params.endAdornment;
    const numberFormat = params.numberFormat ? params.numberFormat : false;
    const InputProps: Partial<OutlinedInputProps> = {
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
        inputComponent: params.numberFormat ? NumberFormatCustom as any : undefined,
    };
    return (
        <TextField variant="outlined"
            type={params.type} inputProps={params.inputProps}
            InputProps={InputProps}
            inputRef={params.inputRef}
            name={params.name} id={params.id}
            label={params.label} placeholder={params.placeholder}
            required={params.required} disabled={params.disabled}
            fullWidth={true} multiline={params.multiline}
            // style={{ marginBottom: '15px' }}
            sx={params.style}
            value={params.value !== null ? params.value : ""}
            onChange={(event) => {
                if (params.onChange !== undefined) {
                    params.onChange(event, params.name, numberFormat, params.stateMember);
                }
            }}
            // onKeyPress={(event) => {
            //     if (params.onKeyPress !== undefined) {
            //         params.onKeyPress(event);
            //     }
            // }}
            error={hasError} helperText={hasError ? params.errorText : ""}
        />
    );
}

export function render_time_picker_input(params: ITimePickerParams) {
    const hasError = params.errorText !== undefined;
    return (
        <TimePicker
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            inputFormat={params.format ? params.format : timeFormat}
            mask="__:__:__"
            label={params.label} value={params.value} disabled={params.disabled}
            onChange={(date: any) => {
                if (params.onChange !== undefined) {
                    params.onChange('TimePicker', date, params.name, params.stateMember);
                }
            }}
            renderInput={(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                    {...inputProps} fullWidth={true}
                    placeholder={params.placeholder}
                    name={params.name} id={params.id}
                    required={params.required} disabled={params.disabled}
                    error={hasError} helperText={hasError ? params.errorText : ''}
                />
            )}
        />
    );
}

export function render_timeline(elements: ITimelineItem[]) {
    if (elements.length < 1) return;
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="timeline">
                    {
                        elements.map((element: ITimelineItem) => {
                            return (
                                <React.Fragment key={element.key}>
                                    {render_time_label(dateTimeToDate(element.date))}
                                    {
                                        element.items.map((item: ITimelineItemParams) => {
                                            return render_timeline_item(item);
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                    <div>
                        <i className="fas fa-clock bg-gray" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function render_tree_view(params: ITreeViewParams) {
    const defaultExpanded = params.defaultExpanded ? params.defaultExpanded : undefined;
    const defaultSelected = params.defaultSelected ? params.defaultSelected : undefined;

    return (
        <MuiTreeView
            ref={params.ref}
            data={params.data}
            disabled={params.disabled}
            multiSelect={params.multiSelect}
            selectOnToggle={params.selectOnToggle}
            toggleOnSelect={params.toggleOnSelect}
            defaultExpanded={defaultExpanded}
            defaultSelected={defaultSelected}
            contextMenuActions={params.contextMenuActions}
            contextMenuListener={params.contextMenuListener}
            onNodeSelect={(event: React.SyntheticEvent<Element, Event>, nodeIds: string | string[]) => {
                if (params.onNodeSelect !== undefined) {
                    params.onNodeSelect(event, nodeIds, params.name, params.stateMember);
                }
            }}
            onNodeToggle={(event: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => {
                if (params.onNodeToggle !== undefined) {
                    params.onNodeToggle(event, nodeIds, params.name, params.stateMember);
                }
            }}
            onToggle={(event: React.SyntheticEvent<Element, Event>, nodeIds: string[], nodeId: string, isExpand: boolean) => {
                if (params.onToggle !== undefined) {
                    params.onToggle(event, nodeIds, nodeId, isExpand, params.name, params.stateMember);
                }
            }}
        />
    );
}

export function render_upload_file_input(params: IUploadFileParams) {
    const hasError = params.errorText !== undefined;
    const isMulti = params.multiple ? params.multiple : false;
    const isBase64 = params.isBase64 ? params.isBase64 : false;
    const withMarker = params.withMarker ? params.withMarker : true;

    return (
        <Stack direction="row" spacing={2}>
            <label htmlFor={params.name}>
                <Input
                    type="file"
                    style={{ display: 'none' }}
                    name={params.name} id={params.id}
                    accept={params.accept} multiple={params.multiple}
                    onChange={(event) => {
                        if (params.onChange !== undefined) {
                            params.onChange(event, params.name, isMulti, isBase64, withMarker, params.stateMember);
                        }
                    }}
                />
                <Button variant="contained" color="inherit" component="span" style={{ marginTop: '10px' }}>
                    {params.text}
                </Button>
            </label>
            <TextField variant="outlined"
                label={params.label} placeholder={params.placeholder}
                required={params.required} disabled={true}
                fullWidth={true}
                value={params.value !== null ? params.value : trans('no_file_selected')}
                error={hasError} helperText={hasError ? params.errorText : ""}
            />
        </Stack>
    );
}

export function render_user_input(params: IUserParams) {
    const hasError = params.errorText !== undefined;
    const required = render_required_element(params.required);
    const label = required ? params.label + "" + required : params.label;
    return (
        <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor={params.name}>{params.label} {required}</InputLabel>
            <OutlinedInput
                id={params.id} name={params.name}
                fullWidth={true} placeholder={params.placeholder}
                required={params.required} disabled={params.disabled}
                value={params.value !== null ? params.value : ""}
                tabIndex={params.tabIndex}
                onChange={(event) => {
                    if (params.onChange !== undefined) {
                        params.onChange(event, params.name, params.stateMember);
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="user icon"
                            edge="end" tabIndex={params.tabIndexAdornment}
                        >
                            <FingerprintIcon />
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
                error={hasError}
            />
            <FormHelperText
                children={hasError ? params.errorText : ""}
                error={hasError}
            />
        </FormControl>
    );
}

export function render_viewer(params: IViewerParams) {
    let url = params.model.fileUrl;
    switch(params.model.extension) {
        case 'doc':
        case 'docx':
        case 'ppt':
        case 'pptx':
        case 'xls':
        case 'xlsx':
            url = params.model.url;
            break;
        default:
            url = params.model.fileUrl;
            break;
    }

    return (
        <FileViewer title={params.model.nom} url={url} extension={params.model.extension}
            viewerStyle={params.viewerStyle} frameStyle={params.frameStyle} />
    );
}

export function render_viewer_actions_card(props: IViewerCardParams) {
    const hasHeader = props.title || props.onDeleteClick;
    const viewerParams: IViewerParams = {
        model: props.model,
        embedded: props.embedded,
        viewerStyle: props.viewerStyle,
        frameStyle: props.frameStyle
    };
    return (
        <Card style={props.cardStyle}>
            {
                hasHeader &&
                <CardHeader
                    action={render_card_action(props.guid, props.onDeleteClick)}
                    title={props.title}
                    disableTypography={true}
                    style={{fontSize: '1.1rem', backgroundColor: 'lightgray'}}
                />
            }
            {render_viewer(viewerParams)}
        </Card>
    );
}
