import { JsonProperty } from "@paddls/ts-serializer";
import { IMetadonnee, Metadonnee } from "./Metadonnee";
import { MetadonneeDepartementPivot } from "./MetadonneeDepartementPivot";

export interface IMetadonneeDepartement extends IMetadonnee {
    pivot: MetadonneeDepartementPivot;

    valeur: string;
}

export class MetadonneeDepartement extends Metadonnee implements IMetadonneeDepartement {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneeDepartementPivot = new MetadonneeDepartementPivot();

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeDepartement>) {
        super();
        Object.assign(this, init);
    }
    
}
