import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { PersonnelWorkflowConfiguration, PersonnelWorkflowConfigurationRequest } from "./PersonnelWorkflowConfiguration";
import { Workflow } from './Workflow';
import { WorkflowType } from './WorkflowType';

export interface IWorkflowConfiguration extends IBase {
    id: number;
    workflow_type_id: number;
    nom: string;
    description: string;

    workflow_type: WorkflowType;
    workflow_type_name: string;

    personnels: PersonnelWorkflowConfiguration[];
    personnels_ids: number[];

    workflows: Workflow[];
}

export class WorkflowConfiguration extends Base implements IWorkflowConfiguration {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    workflow_type_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    workflow_type: WorkflowType = new WorkflowType();
    workflow_type_name: string = "";

    @JsonProperty()
    personnels: PersonnelWorkflowConfiguration[] = [];
    personnels_ids: number[] = [];

    @JsonProperty()
    workflows: Workflow[] = [];

    public constructor(init?: Partial<IWorkflowConfiguration>) {
        super();
        Object.assign(this, init);
    }
}

export interface IWorkflowConfigurationRequest {
    id: number;
    workflow_type_id: number;
    nom: string;
    description: string;

    personnels: PersonnelWorkflowConfigurationRequest[];
}

export class WorkflowConfigurationRequest implements IWorkflowConfigurationRequest {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    workflow_type_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    personnels: PersonnelWorkflowConfigurationRequest[] = [];

    public constructor(init?: Partial<IWorkflowConfigurationRequest>) {
        Object.assign(this, init);
    }
}