import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IModule extends IBase {
    id: number;
    code: string;
    nom: string;
    description: string;
}

export class Module extends Base implements IModule {
    @JsonProperty({ field: "id" })
    id: number = 0;

    @JsonProperty({ field: "code" })
    code: string = "";

    @JsonProperty({ field: "nom" })
    nom: string = "";

    @JsonProperty({ field: "description" })
    description: string = "";

    public constructor(init?: Partial<IModule>) {
        super();
        Object.assign(this, init);
    }
}
