import { JsonProperty } from "@paddls/ts-serializer";
import { IRole, Role } from "./Role";
import { RoleProfilPivot } from "./RoleProfilPivot";

export interface IRoleProfil extends IRole {
    pivot: RoleProfilPivot;
}

export class RoleProfil extends Role implements IRoleProfil {
    @JsonProperty()
    pivot: RoleProfilPivot = new RoleProfilPivot();

    public constructor(init?: Partial<IRoleProfil>) {
        super();
        Object.assign(this, init);
    }
}
