import { useRef } from "react";
import { WINDOW_CLOSE, publish } from "../../helpers/EventHelper";
import MuiWindow, { IMuiWindow, IWinProps, TWidth } from "../commons/MuiWindow";
import { getDisplayName } from "../ComponentHelper";

export interface IWindow {
    openWindow: (winProps?: IWinProps) => void,
    closeWindow: () => void
};

function withWindow(WrappedComponent: any) {
    function WithWindow(props: any) {
        // Properties
        const winTitle = () => { return <></> };
        const winTitleId = () => { return "hoc-window-id"; };
        const winContent = () => { return <></> }
        const winWidth = (): TWidth => { return 'xs'; };
    
        // Refs
        const myWindow = useRef<IMuiWindow>(null);
    
        // Handlers
        const winIsReady = () => {
            return myWindow !== null && myWindow.current !== null;
        };

        const openWin = (props?: IWinProps) => {
            if (winIsReady()) myWindow.current!.open(props);
        };
    
        const closeWin = () => {
            if (winIsReady()) myWindow.current!.close();
        };

        const handleWinClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            // console.log("WindowHoc->handleWinClose", event, reason);
            publish(WINDOW_CLOSE, { event, reason });
        }

        // Renders
        const render_window = () => {
            return <MuiWindow ref={myWindow} open={false}
                winTitle={winTitle()}
                winTitleId={winTitleId()}
                winContent={winContent()}
                fullWidth maxWidth={winWidth()}
                onClose={handleWinClose}
            />
        }
        
        return (
            <>
                <WrappedComponent {...props}
                    openWindow={openWin} closeWindow={closeWin}
                />
                {render_window()}
            </>
        );
    }
  
    WithWindow.displayName = `withWindow(${getDisplayName(WrappedComponent)})`;

    return WithWindow;
}

export {withWindow};