import { JsonProperty } from "@paddls/ts-serializer";

export interface IToken {
    accessToken: string;
    expiresIn: number;
    expiresAt: number;
    refreshToken: string;
}

export class Token implements IToken {
    @JsonProperty({ field: 'access_token' })
    public accessToken: string = "";

    @JsonProperty({ field: 'expires_in' })
    public expiresIn: number = 0;

    @JsonProperty({ field: 'expires_at' })
    public expiresAt: number = 0;

    @JsonProperty({ field: 'refresh_token' })
    public refreshToken: string = "";

    public constructor(init?: Partial<IToken>) {
        Object.assign(this, init);
    }
}
