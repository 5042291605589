import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Metaelement } from "../central/Metaelement";
import { Metavaleur } from "./Metavaleur";

export interface IMetadonnee extends IBase {
    id: number;
    metaelement_id: number;
    code: string;
    nom: string;
    description: string;

    metaelement: Metaelement;
    metaelement_name: string;
    metavaleurs: Metavaleur[];
}

export class Metadonnee extends Base implements IMetadonnee {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    metaelement_id: number = 0;

    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    metaelement!: Metaelement;

    @JsonProperty()
    metaelement_name: string = "";

    @JsonProperty()
    metavaleurs: Metavaleur[] = [];

    public constructor(init?: Partial<IMetadonnee>) {
        super();
        Object.assign(this, init);
    }
}
