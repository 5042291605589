import { JsonProperty } from "@paddls/ts-serializer";
import { IPersonnel, Personnel } from "./Personnel";
import { PersonnelDossierPivot } from "./PersonnelDossierPivot";

export interface IPersonnelDossier extends IPersonnel {
    pivot: PersonnelDossierPivot;
}

export class PersonnelDossier extends Personnel implements IPersonnelDossier {
    @JsonProperty()
    pivot: PersonnelDossierPivot = new PersonnelDossierPivot();

    public constructor(init?: Partial<IPersonnelDossier>) {
        super();
        Object.assign(this, init);
    }
}
