import { JsonProperty } from "@paddls/ts-serializer";

export interface IOperation {
    label: string;
    value: string;
}

export class Operation implements IOperation {
    @JsonProperty()
    label: string = "";

    @JsonProperty()
    value: string = "";

    public constructor(init?: Partial<IOperation>) {
        Object.assign(this, init);
    }
}