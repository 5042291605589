import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface IProps {
    isOpen?: boolean
}

export interface IMuiLoader {
    openLoader: () => void,
    closeLoader: () => void,
    isLoading: () => boolean
}

let openLoaderCounter = 0;

const WrappedMuiLoader = React.forwardRef(
    function MuiLoader(props: IProps, ref: React.ForwardedRef<IMuiLoader>) {
        // Variables
        const defaultIsOpen = props.isOpen ? props.isOpen : false;
        
        // Hooks
        const [isOpen, setIsOpen] = React.useState(defaultIsOpen);

        React.useImperativeHandle(ref, () => ({
            openLoader, closeLoader, isLoading
        }));

        // Handlers
        const close = () => {
            setIsOpen(false);
        };
        
        const open = () => {
            setIsOpen(true);
        };
        
        const isLoading = () => isOpen;

        const openLoader = () => {
            // console.log("MuiLoader->openLoader", openLoaderCounter);
            if (openLoaderCounter === 0) {
                open();
            }
            openLoaderCounter++;
        };
    
        const closeLoader = () => {
            // console.log("MuiLoader->closeLoader", openLoaderCounter);
            if (openLoaderCounter > 0) {
                openLoaderCounter--;
            }

            if (openLoaderCounter === 0) {
                close();
            }
        };
        
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={isOpen}
                // onClick={close}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
);

export default WrappedMuiLoader;