import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React, { useImperativeHandle, useState } from 'react';

interface IProps {
    isOpen?: boolean,
    template?: AlertColor,
    message?: string,
    position?: SnackbarOrigin,
    autoHideDuration?: number,
}

interface IState {
    isOpen: boolean,
    message: string,
    template: AlertColor,
    position: SnackbarOrigin,
    autoHideDuration: number,
}

export interface IMuiNotification {
    open: (message: string, template?: AlertColor, position?: SnackbarOrigin) => void,
    close: (event?: React.SyntheticEvent | Event, reason?: string) => void
}

const WrappedMuiNotification = React.forwardRef(
    function MuiNotification(props: IProps, ref: React.ForwardedRef<IMuiNotification>) {
        // Variables
        const defaultState: IState = {
            isOpen: props.isOpen ? props.isOpen : false,
            message: props.message ? props.message : "",
            template: props.template ? props.template : 'info',
            position: props.position ? props.position : { vertical: 'bottom', horizontal: 'right' },
            autoHideDuration: props.autoHideDuration ? props.autoHideDuration : 5000
        };

        // Handlers
        const close = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }

            setState({ ...state, isOpen: false });
        };
        
        const open = (message: string, tpl?: AlertColor, pos?: SnackbarOrigin) => {
            const template = tpl ? tpl : state.template;
            const position = pos ? pos : state.position;

            setState({ ...state, message, template, position, isOpen: true });
        };
        
        // Hooks
        const [state, setState] = useState<IState>(defaultState);

        useImperativeHandle(ref, () => ({
            open, close
        }));
        
        return (
            <Snackbar onClose={close}
                autoHideDuration={state.autoHideDuration}
                open={state.isOpen} anchorOrigin={state.position}
            >
                <Alert sx={{ width: '100%' }} variant="filled" elevation={6} 
                    onClose={close} severity={state.template}
                >
                    {state.message}
                </Alert>
            </Snackbar>
        );
    }
);
export default WrappedMuiNotification;