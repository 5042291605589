import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from "moment";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store/store";
import i18n from './translations/i18n';
import { Provider } from 'react-redux';

// When using TypeScript 4.x and above
// import type {} from '@mui/x-data-grid/themeAugmentation';

// When using TypeScript 4.x and above
// import type {} from '@mui/lab/themeAugmentation';

// Init moment
moment.locale('fr');

const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red',
                },
            },
        },
        // Use `MuiDataGrid` on both DataGrid and DataGridPro
        // MuiDataGrid: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: 'red',
        //         },
        //     },
        // },
        // MuiTimeline: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: 'red',
        //         },
        //     },
        // },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
