import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { classToObject } from '../helpers/ModelHelper';
import { IToken, Token } from '../models/auth/Token';
import { IBreadcrumb } from '../models/Breadcrumb';
import { IDomaine } from '../models/central/Domaine';
import { IModule } from '../models/central/Module';
import { IOrganisation, Organisation } from '../models/central/Organisation';
import { Dossier, IDossier } from '../models/tenant/Dossier';
import { IPersonnel, Personnel } from '../models/tenant/Personnel';
import { IUtilisateur, Utilisateur } from '../models/Utilisateur';
import type { RootState } from './store';

// Define a type for the slice state
interface AuthState {
    [key: string]: any;

    user: IUtilisateur,
    personnel: IPersonnel,
    
    token: IToken;
    tenant: IOrganisation,

    dossier: IDossier,

    domains: IDomaine[],
    modules: IModule[],
    tenants: IOrganisation[],
    abilities: string[],
    
    breadcrumbs: IBreadcrumb[];
}

// Define the initial state using that type
const initialState: AuthState = {
    user: classToObject(new Utilisateur()),
    personnel: classToObject(new Personnel()),
    
    token: classToObject(new Token()),
    tenant: classToObject(new Organisation()),

    dossier: classToObject(new Dossier()),
    
    domains: [],
    modules: [],
    tenants: [],
    abilities: [],

    breadcrumbs: []
}

export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetAuth: (state) => {
            Object.keys(initialState).forEach((key) => state[key] = initialState[key])
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        setUser: (state, action: PayloadAction<IUtilisateur>) => {
            state.user = action.payload
        },
        setPersonnel: (state, action: PayloadAction<IPersonnel>) => {
            state.personnel = action.payload
        },
        setToken: (state, action: PayloadAction<IToken>) => {
            state.token = action.payload
        },
        setTenant: (state, action: PayloadAction<IOrganisation>) => {
            state.tenant = action.payload
        },
        setDossier: (state, action: PayloadAction<IDossier>) => {
            state.dossier = action.payload
        },
        setDomains: (state, action: PayloadAction<IDomaine[]>) => {
            state.domains = action.payload
        },
        setModules: (state, action: PayloadAction<IModule[]>) => {
            state.modules = action.payload
        },
        setTenants: (state, action: PayloadAction<IOrganisation[]>) => {
            state.tenants = action.payload
        },
        setAbilities: (state, action: PayloadAction<string[]>) => {
            state.abilities = action.payload
        },
        setBreadcrumbs: (state, action: PayloadAction<IBreadcrumb[]>) => {
            state.breadcrumbs = action.payload
        },
        setAuth: (state, action: PayloadAction<AuthState>) => {
            Object.keys(action.payload).forEach((key) => state[key] = action.payload[key])
        },
    },
});

export const { 
    resetAuth, setUser, setPersonnel, setToken, setTenant, setDossier, 
    setDomains, setModules, setTenants, setAbilities, setBreadcrumbs, setAuth
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;