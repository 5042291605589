import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { MetadonneeConfiguration } from './MetadonneeConfiguration';
import { Ressource } from '../central/Ressource';

export interface IConfiguration extends IBase {
    id: number;
    ressource_id: number;
    configuration_id: number;
    nom: string;
    description: string;
    
    ressource: Ressource;
    ressource_name: string;
    metadonnees: MetadonneeConfiguration[];
    
    configuration: Configuration;
    configuration_name: string;

    configurations: Configuration[];
}

export class Configuration extends Base implements IConfiguration {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    ressource_id: number = 0;
    
    @JsonProperty()// (value?: number) => value ? value : 0
    configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    ressource!: Ressource;

    @JsonProperty()
    ressource_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeConfiguration[] = [];

    @JsonProperty()
    configuration!: Configuration;

    @JsonProperty()
    configuration_name: string = "";

    @JsonProperty()
    configurations: Configuration[] = [];

    public constructor(init?: Partial<IConfiguration>) {
        super();
        Object.assign(this, init);
    }
}
