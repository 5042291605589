import { Organisation } from './../models/central/Organisation';
import { ApiConfigs } from "../models/api/ApiConfigs";
import { Module } from "../models/central/Module";
import { authState } from "../store/store";
import { lng } from '../translations/i18n';
import Enumeration from "../utils/Enumeration";

export function getAuthToken() {
    return authState().token;
}

export function getAuthUser() {
    return authState().user;
}

export function getAuthPersonnel() {
    return authState().personnel;
}

export function getAuthTenant() {
    return authState().tenant;
}

export function getAuthTenants() {
    return authState().tenants;
}

export function getUserAuthTenant() {
    const user = getAuthUser();
    return user.tenant;
}

export function getAuthDomains() {
    return authState().domains;
}

export function getAuthModules() {
    return authState().modules;
}

export function getAuthAbilities() {
    return authState().abilities;
}

export function getAbility(action: string, ressource: string): string {
    return `${action}-${ressource}`;
}

export const userIsAuth = (): boolean => {
    return getAuthUser().id !== 0;
}

export function authIsTenant(organisation?: Organisation): boolean {
    if (organisation) {
        return organisation.id !== Enumeration.ORGANISATION_KIKUN_ID;
    }

    return getAuthTenant().id !== Enumeration.ORGANISATION_KIKUN_ID;
}

export function authUserIsTenant(): boolean {
    return getUserAuthTenant() !== undefined;
}

export function authTenantHasModule(module: string): boolean {
    const modules: Module[] = getAuthTenant().modules;
    if (modules.length === 0) return false;

    return modules.findIndex((item: Module) => module === item.code) !== -1;
}

export function authUserCanDo(ability: string): boolean {
    const abilities: string[] = getAuthAbilities();
    if (abilities.length === 0) return false;

    return abilities.findIndex((item: string) => ability === item || "*" === item) !== -1;
}

export function authUserCanDoes(abilities: string[], every: boolean = false) : boolean {
    if (every) return abilities.every(authUserCanDo);
    return abilities.some(authUserCanDo);
}

export function getRequestToken() {
    return getAuthToken().accessToken;
}

export function getRequestTenant() {
    const tenant = getAuthTenant();
    return authIsTenant() ? tenant.id : 0;
}

export function getRequestDomain() {
    let domain = "";
    const tenant = getAuthTenant();
    if (tenant.id !== 0) {
        const authDomain = getAuthDomains().find((elt) => elt.tenant_id === tenant.id);
        domain = authDomain ? authDomain.domain : "";
    }
    return domain;
}

export function getRequestConfigs() {
    let config: ApiConfigs = {
        token: getRequestToken(),
        domain: getRequestDomain(),
        tenant: getRequestTenant(),
        language: lng,
    };

    return config;
}
