import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Action } from '../central/Action';
import { Ressource } from '../central/Ressource';

export interface IRole extends IBase {
    id: number;
    ressource_id: number;
    action_id: number;
    nom: string;
    description: string;

    ressource: Ressource;
    ressource_code: string;
    ressource_name: string;

    action: Action;
    action_code: string;
    action_name: string;
}

export class Role extends Base implements IRole {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    ressource_id: number = 0;
    
    @JsonProperty()
    action_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    ressource!: Ressource;

    @JsonProperty()
    ressource_name: string = "";

    @JsonProperty()
    ressource_code: string = "";

    @JsonProperty()
    action!: Action;

    @JsonProperty()
    action_name: string = "";

    @JsonProperty()
    action_code: string = "";
    
    public constructor(init?: Partial<IRole>) {
        super();
        Object.assign(this, init);
    }
}
