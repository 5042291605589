import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from './Base';
import { Organisation } from "./central/Organisation";

export interface IUtilisateur extends IBase {
    id: number;
    nom: string;
    prenom: string;
    email: string;
    fullname: string;
    password: string;
    tenant_id: number;
    
    actif: boolean;
    statut: string;

    tenant?: Organisation;
}

export class Utilisateur extends Base implements IUtilisateur {
    [key: string]: any;
    
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    prenom: string = "";

    @JsonProperty()
    email: string = "";

    @JsonProperty()
    fullname: string = "";

    @JsonProperty()
    password: string = "";

    @JsonProperty()
    tenant_id: number = 0;
    
    @JsonProperty({ field: "actif" })
    actif: boolean = true;
    statut: string = "";

    @JsonProperty()
    tenant?: Organisation;

    public constructor(init?: Partial<IUtilisateur>) {
        super();
        Object.assign(this, init);
    }
}
