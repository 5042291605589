import { JsonProperty } from "@paddls/ts-serializer";
import { Domaine } from "../central/Domaine";
import { Module } from "../central/Module";
import { Organisation } from "../central/Organisation";
import { Personnel } from "../tenant/Personnel";
import { Utilisateur } from "../Utilisateur";
import { Token } from "./Token";

interface ILoginResponse {
    token: Token;
    user: Utilisateur;
    personnel: Personnel;
    domains: Domaine[];
    modules: Module[];
    tenants: Organisation[];
    abilities: string[];
}

export class LoginResponse implements ILoginResponse {
    @JsonProperty()
    token: Token = new Token();

    @JsonProperty()
    user: Utilisateur = new Utilisateur();

    @JsonProperty()
    personnel: Personnel = new Personnel();

    @JsonProperty()
    domains: Domaine[] = [];

    @JsonProperty()
    modules: Module[] = [];

    @JsonProperty()
    tenants: Organisation[] = [];

    @JsonProperty()
    abilities: string[] = [];

    public constructor(init?: Partial<ILoginResponse>) {
        Object.assign(this, init);
    }

}