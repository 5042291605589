import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IExtension extends IBase {
    id: number;
    code: string;
    nom: string;
    description: string;
    actif: boolean;

    statut: string;
}

export class Extension extends Base implements IExtension {
    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";
    
    @JsonProperty()
    actif: boolean = true;

    statut: string = "";

    public constructor(init?: Partial<IExtension>) {
        super();
        Object.assign(this, init);
    }
}
