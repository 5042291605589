import { JsonProperty } from "@paddls/ts-serializer";
import { IPays, Pays } from "./Pays";
import { PaysOrganisationPivot } from "./PaysOrganisationPivot";

export interface IPaysOrganisation extends IPays {
    pivot: PaysOrganisationPivot;
}

export class PaysOrganisation extends Pays implements IPaysOrganisation {
    @JsonProperty()
    pivot: PaysOrganisationPivot = new PaysOrganisationPivot();

    public constructor(init?: Partial<IPaysOrganisation>) {
        super();
        Object.assign(this, init);
    }
}
