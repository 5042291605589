// import numeral from 'numeral';

export function round(n: number, decimals: number) : number {
    return parseFloat(n.toFixed(decimals))
}

export function stringToInt(s: string): number {
    return isNaN(parseInt(s)) ? 0 : parseInt(s);
}

export function stringToNumber(s: string): number {
    return isNaN(parseFloat(s)) ? 0 : parseFloat(s);
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 o';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function valueIsBoolean(value: any): boolean {
    switch(value) {
        case true:
        case false:
        case "true":
        case "false":
        case 1:
        case 0:
        case "1":
        case "0":
        case "on":
        case "off":
        case "yes":
        case "no":
            return true; 
        default: 
            return false; 
    } 
}

export function anyToBoolean(value: any): boolean { 
    switch(value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true; 
        default: 
            return false; 
    } 
}

// export function numberDisplay(n: number) : string {
//     return numeral(n).format('0,0.[000000]')
// }

export const DEFAULT_IDLE_TIME_OUT = 1000 * 60 * 30;