import { DateConverter, JsonProperty } from "@paddls/ts-serializer";
import { trans } from "../../helpers/TransHelper";
import Enumeration from "../../utils/Enumeration";
import { IBase, Base } from "../Base";
import { Document } from "./Document";
import { Personnel } from "./Personnel";
import { Role } from "./Role";

export interface IApprobation extends IBase {
    id: number;
    document_id: number;
    personnel_id: number;
    date_expiration: Date;
    statut: string;
    statut_label: string;

    document: Document;
    personnel: Personnel;

    document_name: string;
    personnel_name: string;

    roles: Role[];
    roles_ids: number[];

    is_approved: boolean;
    is_expired: boolean;
    is_rejected: boolean;
    is_waiting: boolean;
}

export class Approbation extends Base implements IApprobation {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    document_id: number = 0;

    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty({ customConverter: () => DateConverter })
    date_expiration: Date = new Date();
    
    @JsonProperty()
    statut: string = Enumeration.STATUT_ATTENTE;
    
    @JsonProperty()
    statut_label: string = trans('waiting');
    
    @JsonProperty()
    document: Document = new Document();

    @JsonProperty()
    personnel: Personnel = new Personnel();

    @JsonProperty()
    document_name: string = "";

    @JsonProperty()
    personnel_name: string = "";

    @JsonProperty()
    roles: Role[] = [];

    @JsonProperty()
    roles_ids: number[] = [];

    @JsonProperty()
    is_approved: boolean = false;

    @JsonProperty()
    is_expired: boolean = false;

    @JsonProperty()
    is_rejected: boolean = false;

    @JsonProperty()
    is_waiting: boolean = false;

    public constructor(init?: Partial<IApprobation>) {
        super();
        Object.assign(this, init);
    }
}
