import { JsonProperty } from "@paddls/ts-serializer";

export interface IMetadonneePersonnelPivot {
    metadonnee_id: number;
    personnel_id: number;
    valeur: string;
}

export class MetadonneePersonnelPivot implements IMetadonneePersonnelPivot {
    [key: string]: any;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneePersonnelPivot>) {
        Object.assign(this, init);
    }
}
