import * as React from 'react';

export const alignRightStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end"
};

export const buttonStyle: React.CSSProperties = {
    float: "left",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 0,
    marginBottom: 5,
    cursor: 'pointer',
};

export const disabledStyle: React.CSSProperties = {
    pointerEvents: "none",
    opacity: 0.4
};

export const formContainer: React.CSSProperties = {
    width: '100%',
    lineHeight: 3,
    marginBottom: 10
};

export const labelContainer: React.CSSProperties = {
    width: '30%'
};

export const textInputContainer: React.CSSProperties = {
    width: '70%'
};

export const textInput: React.CSSProperties = {
    width: '100%',
    height: 30,
    marginLeft: 0,
    marginRight: 'auto',
    borderRadius: 3
};

export const requiredElement: React.CSSProperties = {
    color: 'red'
};

export const normalSpacing: React.CSSProperties = {
    letterSpacing: 'normal'
};

export const centerTextAlign: React.CSSProperties = {
    textAlign: "center"
};

export const middleVerticalAlign: React.CSSProperties = {
    verticalAlign: "middle"
};

export const sMarginLeft: React.CSSProperties = {
    marginLeft: 10,
};

export const noPaddingLeft: React.CSSProperties = {
    verticalAlign: "middle",
    paddingLeft: 0
};

export const creditMargin: React.CSSProperties = {
    marginLeft: 0,
    marginRight: '10%',
};

export const centerRadio: React.CSSProperties = {
    flex: 1,//'40%',
    justifyContent: 'center',
};

export const centerElement: React.CSSProperties = {
    // margin: 0,
    // position: absolute,
    // top: 50%,
    // left: 50%,
    // -ms-transform: translate(-50%, -50%),
    // transform: translate(-50%, -50%)
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh'
};

export const centerCard: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '88vh'
};

export const centerFrame: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
};

export const centerWindow: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '599px'
};

export default buttonStyle;