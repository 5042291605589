import { JsonProperty } from "@paddls/ts-serializer";

interface IException {
    trace: string;
    message: string;
    stack: string;
}

export class Exception  implements IException {
    @JsonProperty()
    trace: string = "";

    @JsonProperty()
    message: string = "";

    @JsonProperty()
    stack: string = "";
    
}