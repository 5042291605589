import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Utilisateur } from "../Utilisateur";
import { Approbation } from "./Approbation";
import { Departement } from "./Departement";
import { Groupe } from "./Groupe";
import { MetadonneePersonnel } from "./MetadonneePersonnel";
import { Profil } from "./Profil";

export interface IPersonnel extends IBase {
    id: number;
    departement_id: number;
    profil_id: number;
    user_id: number;
    configuration_id: number;

    departement: Departement;
    departement_name: string;
    branche_name: string;
    pays_name: string;

    profil: Profil;
    profil_name: string;
    profil_code: string;

    user: Utilisateur;
    nom: string;
    prenom: string;
    email: string;
    actif: boolean;
    statut: string;
    fullname: string;

    groupes: Groupe[];
    groupes_name: string;

    metadonnees: MetadonneePersonnel[];
    approbations: Approbation[];

    groupes_ids: number[];

    is_admin: boolean;
}

export class Personnel extends Base implements IPersonnel {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    departement_id: number = 0;

    @JsonProperty()
    profil_id: number = 0;
    
    @JsonProperty()
    user_id: number = 0;
    
    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    departement!: Departement;
    departement_name: string = "";
    branche_name: string = "";
    pays_name: string = "";

    @JsonProperty()
    profil!: Profil;
    profil_name: string = "";
    profil_code: string = "";

    @JsonProperty()
    user!: Utilisateur;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    prenom: string = "";

    @JsonProperty()
    email: string = "";
    
    @JsonProperty({ field: "actif" })
    actif: boolean = true;
    statut: string = "";

    @JsonProperty()
    fullname: string = "";

    @JsonProperty()
    groupes: Groupe[] = [];
    groupes_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneePersonnel[] = [];

    @JsonProperty()
    approbations: Approbation[] = [];

    @JsonProperty()
    groupes_ids: number[] = [];

    @JsonProperty()
    is_admin: boolean = false;

    public constructor(init?: Partial<IPersonnel>) {
        super();
        Object.assign(this, init);
    }
}
