import { JsonProperty } from "@paddls/ts-serializer";
import { IMetadonnee, Metadonnee } from "./Metadonnee";
import { MetadonneeConfigurationPivot } from "./MetadonneeConfigurationPivot";

export interface IMetadonneeConfiguration extends IMetadonnee {
    pivot: MetadonneeConfigurationPivot;

    position: number;
    requis: boolean;
    alignement: string;
    visible: boolean;
}

export class MetadonneeConfiguration extends Metadonnee implements IMetadonneeConfiguration {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneeConfigurationPivot = new MetadonneeConfigurationPivot();

    @JsonProperty()
    position: number = 0;

    @JsonProperty()
    requis: boolean = true; 
    
    @JsonProperty()
    alignement: string = "";

    @JsonProperty()
    visible: boolean = true;

    public constructor(init?: Partial<IMetadonneeConfiguration>) {
        super();
        Object.assign(this, init);
    }
}
