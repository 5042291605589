import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
// import Typography from '@mui/material/Typography';
import React from 'react';
import { TIcon } from './MuiIconsPlus';

interface IProps {
    menuActions: Array<IMenuAction>;
    menuListener: (itemId: string, actionId: string) => void;
}

export interface IMuiMenu {
    open: (itemId: string, position: IMenuPosition) => void;
    close: () => void;
}

export interface IMenuPosition {
    mouseX: number;
    mouseY: number;
}

export interface IMenuAction {
    id: string;
    label: string;
    type: string | Array<string>;
    icon?: TIcon;
}

export interface IMenuRef {
    [key: string]: React.RefObject<IMuiMenu>;
}

const WrappedMuiMenu = React.forwardRef(
    function MuiMenu(props: IProps, ref: React.ForwardedRef<IMuiMenu>) {
        // Handlers
        const open = (itemId: string, position: IMenuPosition) => {
            setItemId(itemId);

            setContextMenu(
                contextMenu === null
                ? position
                :   // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        }
        
        const close = () => {
            setContextMenu(null);
        };
        
        const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, itemId: string, actionId: string) => {
            // console.log({source: "MuiMenu->handleMenuItemClick", event, itemId, actionId});
            event.stopPropagation();
            close();
            props.menuListener(itemId, actionId);
        };

        const handleClose = (event: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
            // console.log({source: "MuiMenu->handleClose", event, reason});
            event.stopPropagation();
            close();
        };
        



        // Hooks
        const [itemId, setItemId] = React.useState("");
        const [contextMenu, setContextMenu] = React.useState<{
            mouseX: number;
            mouseY: number;
        } | null>(null);

        React.useImperativeHandle(ref, () => ({
            open, close
        }));




        // Renders
        const render_menu_item_icon = (action: IMenuAction) => {
            if (!action.icon) return null;
            return (
                <ListItemIcon>
                    <action.icon fontSize="small" />
                </ListItemIcon>
            )
        };
        
        const render_menu_item = (action: IMenuAction) => {
            return (
                <MenuItem key={action.id} onClick={(event) => handleMenuItemClick(event, itemId, action.id)}>
                    {render_menu_item_icon(action)}
                    <ListItemText>{action.label}</ListItemText>
                    {/* <Typography variant="inherit" noWrap>{action.label}</Typography> */}
                </MenuItem>
            );
        };
        
        return (
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                            e.preventDefault();
                            close();
                        },
                    },
                  }}
            >
                {
                    props.menuActions.length !== 0 && props.menuActions.map((action) => {
                        // return (
                        //     <MenuItem key={action.id} onClick={() => handleMenuItemClick(itemId, action.id)}>
                        //         {action.label}
                        //     </MenuItem>
                        // );
                        return render_menu_item(action);
                    })
                }
            </Menu>
        );
    }
);

export default WrappedMuiMenu;