import _ from "lodash";
import { JsonProperty } from "@paddls/ts-serializer";
import { stringToNumber } from "../../helpers/NumberHelper";
import Enumeration from "../../utils/Enumeration";
import { Base, IBase } from '../Base';
import { Confidentialite } from "../central/Confidentialite";
import { Utilisateur } from "../Utilisateur";
import { Approbation } from "./Approbation";
import { Document } from "./Document";
import { Dossier } from "./Dossier";
import { Groupe } from "./Groupe";
import { MetadonneeDocument } from "./MetadonneeDocument";
import { MetadonneeDossier } from "./MetadonneeDossier";
import { Personnel } from './Personnel';
import { Profil } from "./Profil";
import { Version } from "./Version";
import { Configuration } from "./Configuration";
import { Partage } from "./Partage";
import { Workflow } from "./Workflow";

export interface IFichier extends IBase {
    uid: number;
    
    id: number;
    user_id: number;
    confidentialite_id: number;
    dossier_id: number;
    configuration_id: number;
    nom: string;

    user: Utilisateur;
    user_name: string;

    personnel: Personnel;
    personnel_name: string;
    
    confidentialite: Confidentialite;
    confidentialite_name: string;
    confidentialite_code: string;

    dossier: Dossier;
    path_id: string;
    path_name: string;
    dossier_name: string;
    
    configuration: Configuration;
    configuration_name: string;

    metadonnees: MetadonneeDocument[] | MetadonneeDossier[];

    personnels: Personnel[];
    personnels_ids: number[];

    profils: Profil[];
    profils_ids: number[];

    groupes: Groupe[];
    groupes_ids: number[];

    is_owner: boolean;
    is_private: boolean;
    is_public: boolean;
    is_shared: boolean;


    // Dossiers
    dossiers: Dossier[];
    documents: Document[];


    // Documents
    version: Version;
    // hash_name: string;
    // extension: string;
    // size: string;
    // url: string;
    // contents: string;
    
    // size_display: string;
    // fichier: File;

    versions: Version[];
    partages: Partage[];
    approbations: Approbation[];

    workflows: Workflow[];
    workflow_statut_code: string;
    workflow_statut_label: string;


    is_document: boolean;
    type: string;
}

export class Fichier extends Base implements IFichier {
    [key: string]: any;

    @JsonProperty()
    uid: number = stringToNumber(_.uniqueId());

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    user_id: number = 0;
    
    @JsonProperty()
    confidentialite_id: number = 0;
    
    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    dossier_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    user!: Utilisateur;
    user_name: string = "";

    @JsonProperty()
    personnel!: Personnel;
    personnel_name: string = "";

    @JsonProperty()
    confidentialite!: Confidentialite;
    confidentialite_name: string = "";
    confidentialite_code: string = "";

    @JsonProperty()
    dossier!: Dossier;
    path_id: string = "";
    path_name: string = "";
    dossier_name: string = "";
    
    @JsonProperty()
    configuration!: Configuration;
    configuration_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeDocument[] | MetadonneeDossier[] = [];

    @JsonProperty()
    personnels: Personnel[] = [];
    personnels_ids: number[] = [];

    @JsonProperty()
    profils: Profil[] = [];
    profils_ids: number[] = [];

    @JsonProperty()
    groupes: Groupe[] = [];
    groupes_ids: number[] = [];

    is_owner: boolean = false;
    is_private: boolean = false;
    is_public: boolean = false;
    is_shared: boolean = false;


    // Dossiers
    @JsonProperty()
    dossiers!: Dossier[];
    
    @JsonProperty()
    documents!: Document[];


    // Documents
    @JsonProperty()
    version!: Version;
    // hash_name!: string;
    // extension!: string;
    // size!: string;
    // url!: string;
    // contents!: string;

    // size_display: string = "";
    // fichier: File;

    @JsonProperty()
    versions!: Version[];
    
    @JsonProperty()
    partages!: Partage[];
    
    @JsonProperty()
    approbations!: Approbation[];
    
    @JsonProperty()
    workflows!: Workflow[];
    workflow_statut_code: string = "";
    workflow_statut_label: string = "";


    is_document: boolean = this.version !== undefined;
    type: string = this.is_document ? Enumeration.NODE_DOCUMENT : Enumeration.NODE_DOSSIER;

    public constructor(init?: Partial<IFichier>) {
        super();
        Object.assign(this, init);
    }
}
