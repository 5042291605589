import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IPays extends IBase {
    id: number;
    nom_fr: string;
    nom_en: string;
    code_iso_2: string;
    code_iso_3: string;
    code_numerique: string;
    code_telephonique: string;
    actif: boolean;

    statut: string;
}

export class Pays extends Base implements IPays {
    [key: string]: any;
    
    @JsonProperty({ field: "id" })
    id: number = 0;

    @JsonProperty({ field: "nom_fr" })
    nom_fr: string = "";

    @JsonProperty({ field: "nom_en" })
    nom_en: string = "";
    
    @JsonProperty({ field: "code_iso_2" })
    code_iso_2: string = "";
    
    @JsonProperty({ field: "code_iso_3" })
    code_iso_3: string = "";
    
    @JsonProperty({ field: "code_numerique" })
    code_numerique: string = "";
    
    @JsonProperty({ field: "code_telephonique" })
    code_telephonique: string = "";
    
    @JsonProperty({ field: "actif" })
    actif: boolean = false;

    statut: string = "";
    
    public constructor(init?: Partial<IPays>) {
        super();
        Object.assign(this, init);
    }
}
