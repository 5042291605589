import React from 'react';

interface IProps {
    title: string,
    url: string,
    extension: string,
    embedded?: boolean,
    viewerStyle?: React.CSSProperties,
    frameStyle?: React.CSSProperties,
}

function FileViewer(props: IProps) {
    const googleViewerUrl = "https://docs.google.com/gview";

    const getGoogleViewerUrl = () => {
        const embedded = props.embedded !== undefined ? props.embedded : true;
        return `${googleViewerUrl}?url=${props.url}&embedded=${embedded}`;
    }

    const getViewerUrl = () => {
        switch(props.extension) {
            case 'doc':
            case 'docx':
            case 'ppt':
            case 'pptx':
            case 'xls':
            case 'xlsx':
                return getGoogleViewerUrl();
            default:
                return props.url;
        }
    }

    const render_file = () => {
        const defaultStyle = {
            // position: "absolute",
            // top: 0,
            // left: 0,
            // width: "75%",
            width: "100%",
            height: "100%"
        };
        switch(props.extension) {
            case 'bmp':
            case 'gif':
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'svg':
            case 'webp':
                return (
                    <img src={props.url} alt={props.title} width="100%" height="100%" />
                );
            default:
                return (
                    <iframe
                        title={props.title}
                        // frameBorder="0"
                        src={getViewerUrl()}
                        style={props.frameStyle ? props.frameStyle : defaultStyle}
                    />
                );
        }
    }

    return (
        <div style={props.viewerStyle}>
            { render_file() }
        </div>
    )
}

export default FileViewer;