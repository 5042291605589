import { DateConverter, JsonProperty } from "@paddls/ts-serializer";

export interface IBase {
    [key: string]: any;

    createdAt: Date;
    updatedAt: Date;

    type: string;
}

export abstract class Base implements IBase {
    [key: string]: any;
    
    @JsonProperty({
        field: 'created_at',
        customConverter: () => DateConverter
    })
    createdAt: Date = new Date();
    
    @JsonProperty({
        field: 'updated_at',
        customConverter: () => DateConverter
    })
    updatedAt: Date = new Date();
    
    type: string = "";
}
