import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { WorkflowConfiguration } from "./WorkflowConfiguration";

export interface IWorkflowType extends IBase {
    id: number;
    code: string;
    nom: string;
    description: string;

    workflow_configurations: WorkflowConfiguration[];
}

export class WorkflowType extends Base implements IWorkflowType {
    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    code: string = "";

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    workflow_configurations: WorkflowConfiguration[] = [];

    public constructor(init?: Partial<IWorkflowType>) {
        super();
        Object.assign(this, init);
    }
}
