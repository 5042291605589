import { JsonProperty } from "@paddls/ts-serializer";
import Enumeration from "../../utils/Enumeration";

export interface IMetadonneeConfigurationPivot {
    metadonnee_id: number;
    configuration_id: number;
    position: number;
    requis: boolean;
    alignement: string;
    visible: boolean;
}

export class MetadonneeConfigurationPivot implements IMetadonneeConfigurationPivot {
    [key: string]: any;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    position: number = 0;

    @JsonProperty()
    requis: boolean = true; 
    
    @JsonProperty()
    alignement: string = Enumeration.ALIGNEMENT_LEFT;

    @JsonProperty()
    visible: boolean = true;

    public constructor(init?: Partial<IMetadonneeConfigurationPivot>) {
        Object.assign(this, init);
    }
}
