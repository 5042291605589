import Autocomplete, { AutocompleteInputChangeReason, AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteValue } from '@mui/material/useAutocomplete';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

type Multiple = boolean | undefined;
type DisableClearable = boolean | undefined;
type FreeSolo = boolean | undefined;

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
    disabled?: boolean,
    id?: string,
    label?: React.ReactNode,
    multiple?: boolean,
    name?: string,
    placeholder?: string,
    required?: boolean,
    limitTags?: number,

    value?: AutocompleteValue<any, Multiple, DisableClearable, FreeSolo>,
    onChange?: (
        event: React.SyntheticEvent,
        value: AutocompleteValue<any, Multiple, DisableClearable, FreeSolo>,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any>
    ) => void,
    onInputChange?: (
        event: React.SyntheticEvent<Element, Event>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => void,
    onDeleteValue?: (
        event: any,
        value: string
    ) => void

    items: any[],

    dummyValue: any,

    valueField?: string,
    labelField?: string | string[],

    errorText?: string
}

function MuiAutocomplete(props: IProps) {
    // Variables
    const hasError = props.errorText !== undefined;
    
    // Renders
    const renderTags = (value: any) => {
        // console.log("renderTags", value);
        const isDeletable = props.multiple && !props.disabled;
        if (Array.isArray(value)) {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {
                        value.map.length !== 0 && value.map((elt: any) => {
                            const key = elt[props.valueField as string];
                            return (
                                <Chip key={key} label={getSelectedLabel(elt)} color='info'
                                    // disabled={props.disabled}
                                    onDelete={isDeletable ? (event: any) => deleteValue(event, elt) : undefined}
                                />
                            );
                        })
                    }
                </Box>
            );
        }
        else {
            return (
                // <Chip key={getItemId(value)} label={getItemLabel(value)} color='info' />
                <>
                    { getSelectedLabel(value) }
                </>
            );
        }
    }

    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: any, _state: AutocompleteRenderOptionState) => {
        // console.log("renderOption", option);
        return (
            <li {...props} key={getItemValue(option)}>
                <FormControlLabel
                    control={<Checkbox checked={getItemIsChecked(option)} />}
                    label={getItemLabel(option)}
                />
            </li>
        );
    }

    // Handlers
    const deleteValue = (event: any, value: any) => {
        // console.log("deleteValue", event, value);
        if (props.onDeleteValue === undefined) {
            throw new Error('Autocomplete must be used within a onDeleteValue handler')
        }
        props.onDeleteValue(event, value);
    }

    const getItemValue = (item: any): any => {
        return props.valueField !== undefined ? item[props.valueField] : item;
    }

    const getItemLabel = (item: any): string => {
        let label = "";
        if (!Array.isArray(props.labelField)) {
            label = props.labelField !== undefined ? item[props.labelField as string] : item;
        }
        else {
            const labelFields = props.labelField as string[];
            let labelField = labelFields[0], newLabel = "";

            label = props.labelField !== undefined ? item[labelField] : item;
            for (let i = 1; i < labelFields.length; i++) {
                labelField = labelFields[i];
                newLabel = props.labelField !== undefined ? item[labelField] : item;
                label = label !== "" ? `${label} - ${newLabel}` : newLabel;
            }
        }

        return label;
    }

    const getItemIsChecked = (item: any): boolean => {
        const value = props.value as any;
        if (!Array.isArray(value)) {
            return props.valueField !== undefined ? value === item[props.valueField] : value === item;
        }
        else {
            let index = value.findIndex(
                (elt: any) => props.valueField !== undefined ? elt === item[props.valueField as string] : elt === item
            );
            return index !== -1;
        }
    }

    const getSelectedLabel = (selected: any): string => {
        let index = props.items.findIndex(
            (item: any) => {
                if (props.valueField === undefined) return item === selected;
                return item[props.valueField as string] === selected[props.valueField as string];
            }
        );

        return index !== -1 ? getItemLabel(props.items[index]) : "";
    }

    const getValue = (selected: any): any => {
        // console.log("getValue", selected);
        if (selected === undefined) {
            return "";
        }
        else if (!Array.isArray(selected)) {
            return selected === props.dummyValue ? "" : selected;
        }
        else {
            return selected.length === 0 ? [] : selected;
        }
    }

    const isOptionEqualToValue = (option: any, value: any): any => {
        // console.log("isOptionEqualToValue", option, value);
        if (value === "") return false;
        return option[props.valueField as string] === value[props.valueField as string];
    }
    
    const getOptionLabel = (option: any) => {
        // console.log("getOptionLabel", option);
        return getSelectedLabel(option);
    }

    const filterOptions = (options: any[], state: any): any[] => {
        // console.log("filterOptions", options, state);
        let filteredOptions: any[] = [];
        let inputValue = state.inputValue as string;
        
        filteredOptions = options.filter(
            (option: any) => {
                let searchLabel = inputValue.toLowerCase();
                // let optionValue = getItemValue(option);
                // let optionLabel = getSelectedLabel(optionValue).toLocaleLowerCase();
                let optionLabel = getSelectedLabel(option).toLocaleLowerCase();

                // console.log("filterOptions", searchLabel, optionLabel);
                return optionLabel.includes(searchLabel);
            }
        );

        return filteredOptions;
    }

    const onChange = (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
        // console.log("onChange", event, value, reason, details);
        if (props.onChange === undefined) {
            throw new Error('Autocomplete must be used within a onChange handler')
        }
        props.onChange(event, value, reason, details);
    }

    // const onInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    //     // console.log("onInputChange", event, value, reason);
    //     if (props.onInputChange === undefined) {
    //         throw new Error('Autocomplete must be used within a onInputChange handler')
    //     }
    //     props.onInputChange(event, value, reason);
    // }

    // console.log("limitTags: ", props.limitTags);
    return (
        <Autocomplete
            multiple={props.multiple} disabled={props.disabled}
            options={props.items} fullWidth={true}
            disableCloseOnSelect={props.multiple}
            filterSelectedOptions
            limitTags={props.limitTags}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            // onInputChange={onInputChange}
            value={getValue(props.value)}
            isOptionEqualToValue={isOptionEqualToValue}
            renderTags={renderTags}
            renderOption={renderOption}
            renderInput={(inputProps) => (
                <TextField
                    {...inputProps} variant="outlined" fullWidth={true}
                    name={props.name} id={props.id}
                    label={props.label} placeholder={props.placeholder}
                    required={props.required} disabled={props.disabled}
                    error={hasError} helperText={hasError ? props.errorText : ''}
                />
            )}
        />
    );
}
export default MuiAutocomplete;
