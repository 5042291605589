import { JsonProperty } from "@paddls/ts-serializer";

export interface IPassword {
    old: string;
    new: string;
    confirm: string;
}

export class Password implements IPassword {
    @JsonProperty()
    old: string = "";

    @JsonProperty()
    new: string = "";

    @JsonProperty()
    confirm: string = "";

    public constructor(init?: Partial<IPassword>) {
        Object.assign(this, init);
    }
}
