import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import CommonEn from "./en/common.json";
import ValidationEn from "./en/validation.json";
import CommonFr from "./fr/common.json";
import ValidationFr from "./fr/validation.json";

const DETECTION_OPTIONS = {
    order: ['navigator']
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: DETECTION_OPTIONS,
        resources: {
            en: {
                common: CommonEn,
                validation: ValidationEn
            },
            fr: {
                common: CommonFr,
                validation: ValidationFr
            }
        },
        // Set default namespace
        defaultNS: "common",
        // debug: true,
        fallbackLng: 'en',
    });

export default i18n;

// For using outside of component
const t = i18n.t.bind(i18n);
const lng = i18n.language.split('-')[0];

export { t, lng };
