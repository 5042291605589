import moment from 'moment';
import { anyToBoolean, stringToNumber, valueIsBoolean } from './NumberHelper';
import { trans } from './TransHelper';
import Enumeration from '../utils/Enumeration';

export const formatISO = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";

export const timeFormat = "HH:mm:ss";
export const timeFormatShort = "mm:ss";

export const dateFormat = "dd/MM/yyyy";
export const dateTimeFormat = "dd/MM/yyyy HH:mm:ss";
export const dateTimeFormatTZ = "dd/MM/yyyy HH:mm:ssZZ";

export const dateFormatEn = "YYYY-MM-DD";
export const dateTimeFormatEn = "YYYY-MM-DD HH:mm:ss";

export const dateFormatMo = "DD/MM/YYYY";
export const dateTimeFormatMo = "DD/MM/YYYY HH:mm:ss";
export const dateTimeFormatMoTic = "DD MMMM HH:mm";
export const dateTimeFormatMoTicLong = "DD MMMM YYYY HH:mm";
export const dateTimeFormatMoTZ = "DD/MM/YYYY HH:mm:ssZZ";

export function parseInputValue(target: EventTarget & HTMLInputElement) : boolean | number | string | FileList {
    switch (target.type) {
        case 'checkbox':
            return target.checked;
        case 'number':
            return stringToNumber(target.value);
        case 'radio':
            return valueIsBoolean(target.value) ? anyToBoolean(target.value) : target.value;
        case 'file':
            return target.files ? target.files : "";
        default:
            return target.value ? target.value : "";
    }
}

export function stringToDate(str: string, format: string = dateTimeFormatMo) {
    return moment(str, format).toDate();
}

export function dateToString(date: Date, format: string = dateTimeFormatMo) {
    return moment(date).format(format);
}

export function formatDate(date: any, format: 'fr' | 'en' = 'fr') {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear(),
        formattedDate = "";

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (format) {
        case 'en':
            formattedDate = [year, month, day].join('-');
            break;
        case 'fr':
            formattedDate = [day, month, year].join('/');
            break;
        default:
            formattedDate = [day, month, year].join('/');
    }

    return formattedDate;
}

export function minDateHours(date: Date) {
    date.setHours(0, 0, 0, 0);
    return date;
}

export function maxDateHours(date: Date) {
    date.setHours(23, 59, 59, 59);
    return date;
}

export function dateToIsoString(date: Date) {
    return moment(date).format(formatISO);
}

export function timeSpanToDateTime(timeSpan: string, datetime: Date = new Date()) {
    let params = timeSpan.split(':').map((elt: string) => parseInt(elt));
    // let datetime = new Date();
    datetime.setHours(params[0], params[1], params[2]);

    return datetime;
}

export function utcToTime(time: number) {
    return moment.utc(time).format(timeFormatShort);
}

export function ticDateTime(datetime: Date) {
    return moment(datetime).format(dateTimeFormatMoTic);
}

export function ticDateTimeLong(datetime: Date) {
    return moment(datetime).format(dateTimeFormatMoTicLong);
}

export function formatDateTime(datetime: Date, format: string = dateTimeFormatMo) {
    return moment(datetime).format(format);
}

export function localFormat(datetime: Date) {
    return moment(datetime).format('LLLL');
}

export function dateTimeToTimeSpan(datetime: Date, format: string = timeFormat) {
    return moment(datetime).format(format);
}

export function dateTimeToDate(datetime: Date, format: string = dateFormatMo) {
    return moment(datetime).format(format);
}

export function dateTimeToIso(datetime: Date) {
    return moment(datetime).format(dateTimeFormatMoTZ);
}

export function dateTimeAddDays(datetime: Date, days: number = 30) {
    return moment(datetime).add(days, 'days').toDate();
}

export function dateTimeSubsDays(datetime: Date, days: number = 30) {
    return moment(datetime).subtract(days, 'days').toDate();
}

export function dateTimeSetSeconds(datetime: Date, seconds: number = 0) {
    return moment(datetime).second(seconds).toDate();
}

export function dateTimeStartOfMonth(datetime: Date | string | string[]) {
    return moment(datetime).startOf('day').toDate();
}

export function dateTimeEndOfMonth(datetime: Date | string | string[]) {
    return moment(datetime).endOf('day').toDate();
}

export function isValidDate(date: any) {
    return moment(date).isValid();
}

export function isSameDate(date1: Date, date2: Date, granularity?: moment.unitOfTime.StartOf) {
    // let newDate1 = cloneObject(date1);
    // let newDate2 = cloneObject(date2);

    // if (withoutTime) {
    //     newDate1.setHours(0, 0, 0, 0);
    //     newDate2.setHours(0, 0, 0, 0);
    // }

    // return newDate1.getTime() === newDate2.getTime();

    return moment(date1).isSame(date2, granularity);
}

export function cloneDate(date: Date | string) {
    return moment(date).toDate();
}

export function formatDateToDuration(date: Date | string, remaining: boolean = false) {
    let formatted = "";
    let urgency = "";
    
    const from = remaining ? moment() : moment(date);
    const to = remaining ? moment(date) : moment();
    
    const years = to.diff(from, 'years');
    const months = to.diff(from, 'months');
    const weeks = to.diff(from, 'weeks');
    const days = to.diff(from, 'days');
    const hours = to.diff(from, 'hours');
    const minutes = to.diff(from, 'minutes');
    const seconds = to.diff(from, 'seconds');

    if (years > 0) {
        urgency = Enumeration.BADGE_PRIMARY;
        formatted = `${years} ${years > 1 ? trans('years') : trans('year')}`.toLowerCase();
    }
    else if (months > 0) {
        urgency = Enumeration.BADGE_INFO;
        formatted = `${months} ${months > 1 ? trans('months') : trans('month')}`.toLowerCase();   
    }
    else if (weeks > 0) {
        urgency = Enumeration.BADGE_SUCCESS;
        formatted = `${weeks} ${weeks > 1 ? trans('weeks') : trans('week')}`.toLowerCase();
    }
    else if (days > 0) {
        urgency = Enumeration.BADGE_SUCCESS;
        formatted = `${days} ${days > 1 ? trans('days') : trans('day')}`.toLowerCase();
    }
    else if (hours > 0) {
        urgency = Enumeration.BADGE_WARNING;
        formatted = `${hours} ${hours > 1 ? trans('hours') : trans('hour')}`.toLowerCase();
    }
    else if (minutes > 0) {
        urgency = Enumeration.BADGE_DANGER;
        formatted = `${minutes} ${minutes > 1 ? trans('minutes') : trans('minute')}`.toLowerCase();
    }
    else if (seconds > 0) {
        urgency = Enumeration.BADGE_DANGER;
        formatted = `${seconds} ${seconds > 1 ? trans('seconds') : trans('second')}`.toLowerCase();
    }

    return {formatted, urgency};
}

export function arraySort<T extends {[key: string]: any}>(arr: Array<T>, field: string) {
    return arr.sort((a: T, b: T) => {
        const first = a[field].toLowerCase();
        const second = b[field].toLowerCase();
        
        return first.localeCompare(second);
    });
}

export const nest = (items: any[], id: any = null, idField = 'id', parentField = 'parentId'): any =>
    items.filter(item => item[parentField] === id)
        .map(item => ({ ...item, children: nest(items, item[idField]) }));

export const arrayToTree = (arr: any[], parent: number = 0): any =>
    arr.filter(item => item.parent === parent)
        .map(child => ({ ...child, children: arrayToTree(arr, child.index) }));
