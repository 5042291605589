import { DateConverter, JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from "./Base";

export interface IRecherche extends IBase {
    value: string;

    start_date: Date;
    end_date: Date;
    interval: boolean;
}

export class Recherche extends Base implements IRecherche {
    [key: string]: any;

    @JsonProperty()
    value: string = "";

    @JsonProperty({ customConverter: () => DateConverter })
    start_date: Date = new Date();

    @JsonProperty({ customConverter: () => DateConverter })
    end_date: Date = new Date();

    @JsonProperty()
    interval: boolean = false;

    public constructor(init?: Partial<IRecherche>) {
        super();
        Object.assign(this, init);
    }
}