import { DateConverter, JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from "./Base";
import { Utilisateur } from "./Utilisateur";

export interface IJournal extends IBase {
    id: number;
    user_id: number;
    objet_id: number;
    objet_type: string;
    operation: string;
    description: string;

    user: Utilisateur;
    user_nom: string;
    user_prenom: string;
    user_email: string;
    user_fullname: string;

    date_debut: Date;
    date_fin: Date;
}

export class Journal extends Base implements IJournal {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;

    @JsonProperty()
    user_id: number = 0;

    @JsonProperty()
    objet_id: number = 0;

    @JsonProperty()
    objet_type: string = "";

    @JsonProperty()
    operation: string = "";

    @JsonProperty()
    description: string = "";

    @JsonProperty()
    user!: Utilisateur;

    @JsonProperty()
    user_nom: string = "";

    @JsonProperty()
    user_prenom: string = "";

    @JsonProperty()
    user_email: string = "";

    @JsonProperty()
    user_fullname: string = "";

    @JsonProperty({ customConverter: () => DateConverter })
    date_debut: Date = new Date();

    @JsonProperty({ customConverter: () => DateConverter })
    date_fin: Date = new Date();

    public constructor(init?: Partial<IJournal>) {
        super();
        Object.assign(this, init);
    }
}