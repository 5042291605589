import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from './Base';

export interface IFileView extends IBase {
    id: number;
    nom: string;
    extension: string;
    mimeType: string;
    url: string;
    contents: any;

    file?: File;
    fileUrl: string;
}

export class FileView extends Base implements IFileView {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    extension: string = "";

    @JsonProperty()
    mimeType: string = "";

    @JsonProperty()
    url: string = "";

    @JsonProperty()
    contents: any;

    @JsonProperty()
    file?: File;

    @JsonProperty()
    fileUrl: string = "";

    public constructor(init?: Partial<IFileView>) {
        super();
        Object.assign(this, init);
    }

    revokeUrl() {
        if (this.fileUrl) {
            URL.revokeObjectURL(this.fileUrl);
        }
    }
}
