import { JsonProperty } from "@paddls/ts-serializer";
import { IPersonnel, Personnel } from "./Personnel";
import { PersonnelWorkflowConfigurationPivot } from "./PersonnelWorkflowConfigurationPivot";

export interface IPersonnelWorkflowConfiguration extends IPersonnel {
    pivot: PersonnelWorkflowConfigurationPivot;

    consigne: string;
    periode: string;
    duree: number;
    position: number;

    edition: boolean;
    periode_label: string;
}

export class PersonnelWorkflowConfiguration extends Personnel implements IPersonnelWorkflowConfiguration {
    @JsonProperty()
    pivot: PersonnelWorkflowConfigurationPivot = new PersonnelWorkflowConfigurationPivot();
    
    consigne: string = "";
    periode: string = "";
    duree: number = 0;
    position: number = 0;

    edition: boolean = false;
    periode_label: string = "";

    public constructor(init?: Partial<IPersonnelWorkflowConfiguration>) {
        super();
        Object.assign(this, init);
    }
}

export interface IPersonnelWorkflowConfigurationRequest {
    personnel_id: number;
    workflow_configuration_id: number;
    consigne: string;
    periode: string;
    duree: number;
    position: number;
}

export class PersonnelWorkflowConfigurationRequest implements IPersonnelWorkflowConfigurationRequest {
    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty()
    workflow_configuration_id: number = 0;
    
    @JsonProperty()
    consigne: string = "";
    
    @JsonProperty()
    periode: string = "";

    @JsonProperty()
    duree: number = 0;

    @JsonProperty()
    position: number = 0;

    public constructor(init?: Partial<IPersonnelWorkflowConfigurationRequest>) {
        Object.assign(this, init);
    }
}
