import { DateConverter, JsonProperty } from "@paddls/ts-serializer";
import { trans } from "../../helpers/TransHelper";
import Enumeration from "../../utils/Enumeration";
import { Personnel } from "./Personnel";
import { Workflow } from "./Workflow";

export interface IPersonnelWorkflowPivot {
    personnel_id: number;
    workflow_id: number;

    consigne: string;
    commentaire: string;
    
    delai: Date;
    delai_label: string;

    position: number;

    statut: string;
    statut_label: string;

    personnel: Personnel;
    workflow: Workflow;
    
    is_approved: boolean;
    is_consulted: boolean;
    is_expired: boolean;
    is_rejected: boolean;
    is_waiting: boolean;
}

export class PersonnelWorkflowPivot implements IPersonnelWorkflowPivot {
    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty()
    workflow_id: number = 0;
    
    @JsonProperty()
    consigne: string = "";
    
    @JsonProperty()
    commentaire: string = "";

    @JsonProperty({ customConverter: () => DateConverter })
    delai: Date = new Date();
    delai_label: string = "";

    @JsonProperty()
    position: number = 0;

    @JsonProperty()
    statut: string = Enumeration.STATUT_ATTENTE;
    statut_label: string = trans('waiting');
    
    @JsonProperty()
    personnel!: Personnel;
    
    @JsonProperty()
    workflow!: Workflow;

    is_approved: boolean = false;
    is_consulted: boolean = false;
    is_expired: boolean = false;
    is_rejected: boolean = false;
    is_waiting: boolean = false;

    public constructor(init?: Partial<IPersonnelWorkflowPivot>) {
        Object.assign(this, init);
    }
}
