export default class RouteName {

    // Central
    public static ACTION = 'action';
    public static CONFIDENTIALITE = 'confidentialite';
    public static METAELEMENT = 'metaelement';
    public static MODULE = 'module';
    public static ORGANISATION = 'organisation';
    public static PAYS = 'pays';
    public static RESSOURCE = 'ressource';

    // Root
    public static HOME = 'home';
    public static DASHBOARD = 'dashboard';
    public static JOURNAL = 'journal';
    public static LOGIN = 'login';
    public static NOTIFICATION = 'notification';
    public static SHARE = 'share';
    public static TEST = 'test';
    public static UTILISATEUR = 'utilisateur';
    
    // Tenant
    public static APPROBATION = 'approbation';
    public static BRANCHE = 'branche';
    public static CONFIGURATION = 'configuration';
    public static DEPARTEMENT = 'departement';
    public static EXTENSION = 'extension';
    public static FICHIER = 'fichier';
    public static GROUPE = 'groupe';
    public static HISTORIQUE_VERSION = 'historiqueVersion';
    public static JOURNAL_DOCUMENT = 'journalDocument';
    public static METADONNEE = 'metadonnee';
    public static METADONNEE_CONFIGURATION = 'metadonneeConfiguration';
    public static METAVALEUR = 'metavaleur';
    public static METAVALEUR_METADONNEE = 'metavaleurMetadonnee';
    public static PARTAGE = 'partage';
    public static PERIMETRE = 'perimetre';
    public static PERSONNEL = 'personnel';
    public static PROFIL = 'profil';
    public static RECHERCHE_DOCUMENT = 'rechercheDocument';
    public static ROLE = 'role';
    public static SERVEUR = 'serveur';
    public static SOUS_DOSSIER = 'sousDossier';
    public static TERMINOLOGIE = 'terminologie';
    public static VERSION = 'version';
    public static WORKFLOW = 'workflow';
    public static WORKFLOW_ACTIVITE = 'workflowActivite';
    public static WORKFLOW_CONFIGURATION = 'workflowCnfiguration';
    public static WORKFLOW_TYPE = 'workflowType';
    
}
