import { JsonProperty } from "@paddls/ts-serializer";

export interface IRoleProfilPivot {
    role_id: number;
    profil_id: number;
}

export class RoleProfilPivot implements IRoleProfilPivot {
    @JsonProperty()
    role_id: number = 0;

    @JsonProperty()
    profil_id: number = 0;

    public constructor(init?: Partial<IRoleProfilPivot>) {
        Object.assign(this, init);
    }
}
