import { JsonProperty } from "@paddls/ts-serializer";

export interface IBreadcrumb {
    name: string;
    path: string;
    icon: string;
}

export class Breadcrumb implements IBreadcrumb {
    @JsonProperty()
    name: string = "";

    @JsonProperty()
    path: string = "";

    @JsonProperty()
    icon: string = "";

    public constructor(init?: Partial<IBreadcrumb>) {
        Object.assign(this, init);
    }
}
