import { JsonProperty } from "@paddls/ts-serializer";
import { IMetadonnee, Metadonnee } from "./Metadonnee";
import { MetadonneeBranchePivot } from "./MetadonneeBranchePivot";

export interface IMetadonneeBranche extends IMetadonnee {
    pivot: MetadonneeBranchePivot;

    valeur: string;
}

export class MetadonneeBranche extends Metadonnee implements IMetadonneeBranche {
    [key: string]: any;
    
    @JsonProperty()
    pivot: MetadonneeBranchePivot = new MetadonneeBranchePivot();

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeBranche>) {
        super();
        Object.assign(this, init);
    }
}
