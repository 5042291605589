import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';

export interface IDomaine extends IBase {
    id: number;
    domain: string;
    tenant_id: number;
}

export class Domaine extends Base implements IDomaine {
    @JsonProperty({ field: "id" })
    id: number = 0;

    @JsonProperty({ field: "domain" })
    domain: string = "";

    @JsonProperty({ field: "tenant_id" })
    tenant_id: number = 0;

    public constructor(init?: Partial<IDomaine>) {
        super();
        Object.assign(this, init);
    }
}
