export default class ModelName {

    // Auth
    public static LOGIN_REQUEST = 'LoginRequest';
    public static LOGIN_RESPONSE = 'LoginResponse';
    public static TOKEN = 'Token';

    // Base
    public static ALIGNEMENT = 'Alignement';
    public static CIVILITE = 'Civilite';
    public static ENTITE = 'Entite';
    public static EXCEPTION = 'Exception';
    public static FILE_VIEW = 'FileView';
    public static OPERATION = 'Operation';
    public static TYPE = 'Type';

    // Central
    public static ACTION = 'Action';
    public static CONFIDENTIALITE = 'Confidentialite';
    public static DOMAINE = 'Domaine';
    public static METAELEMENT = 'Metaelement';
    public static MODULE = 'Module';
    public static MODULE_ORGANISATION = 'ModuleOrganisation';
    public static MODULE_ORGANISATION_PIVOT = 'ModuleOrganisationPivot';
    public static ORGANISATION = 'Organisation';
    public static PAYS = 'Pays';
    public static PAYS_ORGANISATION = 'PaysOrganisation';
    public static PAYS_ORGANISATION_PIVOT = 'PaysOrganisationPivot';
    public static RESSOURCE = 'Ressource';
    
    // Root
    public static BREADCRUMB = 'Breadcrumb';
    public static DASHBOARD = 'Dashboard';
    public static HEADER_NOTIFICATION = 'HeaderNotification';
    public static JOURNAL = 'Journal';
    public static NOTIFICATION = 'Notification';
    public static PASSWORD = 'Password';
    public static RECHERCHE = 'Recherche';
    public static UTILISATEUR = 'Utilisateur';

    // Tenant
    public static APPROBATION = 'Approbation';
    public static BRANCHE = 'Branche';
    public static CONFIGURATION = 'Configuration';
    public static DEPARTEMENT = 'Departement';
    public static DOCUMENT = 'Document';
    public static DOSSIER = 'Dossier';
    public static EXTENSION = 'Extension';
    public static FICHIER = 'Fichier';
    public static GROUPE = 'Groupe';
    public static GROUPE_PERSONNEL = 'GroupePersonnel';
    public static GROUPE_PERSONNEL_PIVOT = 'GroupePersonnelPivot';
    public static METADONNEE = 'Metadonnee';
    public static METADONNEE_BRANCHE = 'MetadonneeBranche';
    public static METADONNEE_BRANCHE_PIVOT = 'MetadonneeBranchePivot';
    public static METADONNEE_CONFIGURATION = 'MetadonneeConfiguration';
    public static METADONNEE_CONFIGURATION_PIVOT = 'MetadonneeConfigurationPivot';
    public static METADONNEE_DEPARTEMENT = 'MetadonneeDepartement';
    public static METADONNEE_DEPARTEMENT_PIVOT = 'MetadonneeDepartementPivot';
    public static METADONNEE_DOCUMENT = 'MetadonneeDocument';
    public static METADONNEE_DOCUMENT_PIVOT = 'MetadonneeDocumentPivot';
    public static METADONNEE_DOSSIER = 'MetadonneeDossier';
    public static METADONNEE_DOSSIER_PIVOT = 'MetadonneeDossierPivot';
    public static METADONNEE_PERSONNEL = 'MetadonneePersonnel';
    public static METADONNEE_PERSONNEL_PIVOT = 'MetadonneePersonnelPivot';
    public static METAVALEUR = 'Metavaleur';
    public static PARTAGE = 'Partage';
    public static PERIMETRE = 'Perimetre';
    public static PERSONNEL = 'Personnel';
    public static PERSONNEL_DOCUMENT = 'PersonnelDocument';
    public static PERSONNEL_DOCUMENT_PIVOT = 'PersonnelDocumentPivot';
    public static PERSONNEL_DOSSIER = 'PersonnelDossier';
    public static PERSONNEL_DOSSIER_PIVOT = 'PersonnelDossierPivot';
    public static PERSONNEL_WORKFLOW = 'PersonnelWorkflow';
    public static PERSONNEL_WORKFLOW_CONFIGURATION = 'PersonnelWorkflowConfiguration';
    public static PERSONNEL_WORKFLOW_CONFIGURATION_PIVOT = 'PersonnelWorkflowConfigurationPivot';
    public static PERSONNEL_WORKFLOW_PIVOT = 'PersonnelWorkflowPivot';
    public static PROFIL = 'Profil';
    public static ROLE = 'Role';
    public static ROLE_GROUPE = 'RoleGroupe';
    public static ROLE_GROUPE_PIVOT = 'RoleGroupePivot';
    public static ROLE_PROFIL = 'RoleProfil';
    public static ROLE_PROFIL_PIVOT = 'RoleProfilPivot';
    public static SERVEUR = 'Serveur';
    public static TERMINOLOGIE = 'Terminologie';
    public static VERSION = 'Version';
    public static WORKFLOW = 'Workflow';
    public static WORKFLOW_ACTIVITE = 'WorkflowActivite';
    public static WORKFLOW_CONFIGURATION = 'WorkflowConfiguration';
    public static WORKFLOW_TYPE = 'WorkflowType';
}