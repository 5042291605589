import { JsonProperty } from "@paddls/ts-serializer";
import { IModule, Module } from "./Module";
import { ModuleOrganisationPivot } from "./ModuleOrganisationPivot";

export interface IModuleOrganisation extends IModule {
    pivot: ModuleOrganisationPivot;
}

export class ModuleOrganisation extends Module implements IModuleOrganisation {
    @JsonProperty()
    pivot: ModuleOrganisationPivot = new ModuleOrganisationPivot();

    public constructor(init?: Partial<IModuleOrganisation>) {
        super();
        Object.assign(this, init);
    }
}
