import React from 'react';
import { trans } from './helpers/TransHelper';
import RouteName from './utils/RouteName';

export interface IRoute {
    path: string;
    name: string;
    label: string;
    element: any;
}

// Central
const ActionPage = React.lazy(() => import('./components/pages/central/ActionPage'));
const ConfidentialitePage = React.lazy(() => import('./components/pages/central/ConfidentialitePage'));
const MetaelementPage = React.lazy(() => import('./components/pages/central/MetaelementPage'));
const ModulePage = React.lazy(() => import('./components/pages/central/ModulePage'));
const OrganisationPage = React.lazy(() => import('./components/pages/central/OrganisationPage'));
const PaysPage = React.lazy(() => import('./components/pages/central/PaysPage'));
const RessourcePage = React.lazy(() => import('./components/pages/central/RessourcePage'));

// Root
const DashboardPage = React.lazy(() => import('./components/pages/DashboardPage'));
const HomePage = React.lazy(() => import('./components/pages/HomePage'));
const JournalPage = React.lazy(() => import('./components/pages/JournalPage'));
const LoginPage = React.lazy(() => import('./components/pages/LoginPage'));
const NotificationPage = React.lazy(() => import('./components/pages/NotificationPage'));
const SharePage = React.lazy(() => import('./components/pages/SharePage'));
const TestPage = React.lazy(() => import('./components/pages/TestPage'));
const UtilisateurPage = React.lazy(() => import('./components/pages/UtilisateurPage'));

// Tenant
const ApprobationPage = React.lazy(() => import('./components/pages/tenant/ApprobationPage'));
const BranchePage = React.lazy(() => import('./components/pages/tenant/BranchePage'));
const ConfigurationPage = React.lazy(() => import('./components/pages/tenant/ConfigurationPage'));
const DepartementPage = React.lazy(() => import('./components/pages/tenant/DepartementPage'));
const DossierGridPage = React.lazy(() => import('./components/pages/tenant/DossierGridPage'));
const ExtensionPage = React.lazy(() => import('./components/pages/tenant/ExtensionPage'));
const FichierTreePage = React.lazy(() => import('./components/pages/tenant/FichierTreePage'));
const GroupePage = React.lazy(() => import('./components/pages/tenant/GroupePage'));
const HistoriqueVersionPage = React.lazy(() => import('./components/pages/tenant/HistoriqueVersionPage'));
const JournalDocumentPage = React.lazy(() => import('./components/pages/tenant/JournalDocumentPage'));
const MetadonneeConfigurationPage = React.lazy(() => import('./components/pages/tenant/MetadonneeConfigurationPage'));
const MetadonneePage = React.lazy(() => import('./components/pages/tenant/MetadonneePage'));
const MetavaleurPage = React.lazy(() => import('./components/pages/tenant/MetavaleurPage'));
const PartagePage = React.lazy(() => import('./components/pages/tenant/PartagePage'));
const PerimetrePage = React.lazy(() => import('./components/pages/tenant/PerimetrePage'));
const PersonnelPage = React.lazy(() => import('./components/pages/tenant/PersonnelPage'));
const ProfilPage = React.lazy(() => import('./components/pages/tenant/ProfilPage'));
const RechercheDocumentPage = React.lazy(() => import('./components/pages/tenant/RechercheDocumentPage'));
const RolePage = React.lazy(() => import('./components/pages/tenant/RolePage'));
const ServeurPage = React.lazy(() => import('./components/pages/tenant/ServeurPage'));
const TerminologiePage = React.lazy(() => import('./components/pages/tenant/TerminologiePage'));
const VersionPage = React.lazy(() => import('./components/pages/tenant/VersionPage'));
const WorkflowActivitePage = React.lazy(() => import('./components/pages/tenant/WorkflowActivitePage'));
const WorkflowConfigurationPage = React.lazy(() => import('./components/pages/tenant/WorkflowConfigurationPage'));
const WorkflowPage = React.lazy(() => import('./components/pages/tenant/WorkflowPage'));
const WorkflowTypePage = React.lazy(() => import('./components/pages/tenant/WorkflowTypePage'));


export const loginRoute: IRoute = { path: '/login', name: RouteName.LOGIN, label: trans('login'), element: LoginPage };
export const shareRoute: IRoute = { path: '/share/:params', name: RouteName.SHARE, label: trans('share'), element: SharePage };
export const testRoute: IRoute = { path: '/test', name: RouteName.TEST, label: trans('test'), element: TestPage };

const routes: IRoute[] = [
    // Central
    { path: '/actions', name: RouteName.ACTION, label: trans('actions'), element: ActionPage },
    { path: '/confidentialites', name: RouteName.CONFIDENTIALITE, label: trans('confidentialities'), element: ConfidentialitePage },
    { path: '/journaux', name: RouteName.JOURNAL, label: trans('operations_log'), element: JournalPage },
    { path: '/metaelements', name: RouteName.METAELEMENT, label: trans('meta_elements'), element: MetaelementPage },
    { path: '/modules', name: RouteName.MODULE, label: trans('modules'), element: ModulePage },
    { path: '/organisations', name: RouteName.ORGANISATION, label: trans('organizations'), element: OrganisationPage },
    { path: '/pays', name: RouteName.PAYS, label: trans('countries'), element: PaysPage },
    { path: '/ressources', name: RouteName.RESSOURCE, label: trans('resources'), element: RessourcePage },
    { path: '/utilisateurs', name: RouteName.UTILISATEUR, label: trans('users'), element: UtilisateurPage },
    
    // Root
    { path: '/', name: RouteName.HOME, label: trans('home'), element: HomePage },
    { path: '/dashboard', name: RouteName.DASHBOARD, label: trans('dashboard'), element: DashboardPage },
    { path: '/notifications/:objet_type', name: RouteName.NOTIFICATION, label: trans('notification'), element: NotificationPage },

    // Tenant
    { path: '/approbations/:document_id', name: RouteName.APPROBATION, label: trans('approvals_document'), element: ApprobationPage },
    { path: '/branches', name: RouteName.BRANCHE, label: trans('branches'), element: BranchePage },
    { path: '/configurations', name: RouteName.CONFIGURATION, label: trans('configurations'), element: ConfigurationPage },
    { path: '/departements', name: RouteName.DEPARTEMENT, label: trans('departments'), element: DepartementPage },
    { path: '/fichiers', name: RouteName.FICHIER, label: trans('documentary_database'), element: FichierTreePage },
    { path: '/extensions', name: RouteName.EXTENSION, label: trans('extensions'), element: ExtensionPage },
    { path: '/groupes', name: RouteName.GROUPE, label: trans('groups'), element: GroupePage },
    { path: '/historique-versions', name: RouteName.HISTORIQUE_VERSION, label: trans('versions_history'), element: HistoriqueVersionPage },
    { path: '/journal-documents', name: RouteName.JOURNAL_DOCUMENT, label: trans('documents_traceability'), element: JournalDocumentPage },
    { path: '/metadonnees-configuration/:configuration_id', name: RouteName.METADONNEE_CONFIGURATION, label: trans('meta_datas_configuration'), element: MetadonneeConfigurationPage },
    { path: '/metadonnees', name: RouteName.METADONNEE, label: trans('meta_datas'), element: MetadonneePage },
    { path: '/metavaleurs', name: RouteName.METAVALEUR, label: trans('meta_values'), element: MetavaleurPage },
    { path: '/metadonnees/:metadonnee_id/metavaleurs', name: RouteName.METAVALEUR_METADONNEE, label: trans('meta_values'), element: MetavaleurPage },
    { path: '/partages', name: RouteName.PARTAGE, label: trans('shares'), element: PartagePage },
    { path: '/perimetres', name: RouteName.PERIMETRE, label: trans('perimeters'), element: PerimetrePage },
    { path: '/personnels', name: RouteName.PERSONNEL, label: trans('staffs'), element: PersonnelPage },
    { path: '/profils', name: RouteName.PROFIL, label: trans('profiles'), element: ProfilPage },
    { path: '/recherche-documents', name: RouteName.RECHERCHE_DOCUMENT, label: trans('documents_research'), element: RechercheDocumentPage },
    { path: '/roles', name: RouteName.ROLE, label: trans('roles'), element: RolePage },
    { path: '/serveurs', name: RouteName.SERVEUR, label: trans('servers'), element: ServeurPage },
    { path: '/sous-dossier/:dossier_id', name: RouteName.SOUS_DOSSIER, label: trans('documentary_database'), element: DossierGridPage },
    { path: '/terminologies', name: RouteName.TERMINOLOGIE, label: trans('terminologies'), element: TerminologiePage },
    { path: '/versions/:document_id', name: RouteName.VERSION, label: trans('versions_document'), element: VersionPage },
    { path: '/workflow-activites/:workflow_id/:personnel_id', name: RouteName.WORKFLOW_ACTIVITE, label: trans('activity'), element: WorkflowActivitePage },
    { path: '/workflow-configurations', name: RouteName.WORKFLOW_CONFIGURATION, label: trans('workflow_configurations'), element: WorkflowConfigurationPage },
    { path: '/workflow-types', name: RouteName.WORKFLOW_TYPE, label: trans('workflow_types'), element: WorkflowTypePage },
    { path: '/workflows/:document_id', name: RouteName.WORKFLOW, label: trans('workflows'), element: WorkflowPage },

];

export default routes;
