import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Pays } from "../central/Pays";
import { Departement } from "./Departement";
import { MetadonneeBranche } from "./MetadonneeBranche";

export interface IBranche extends IBase {
    id: number;
    pays_id: number;
    branche_id: number;
    configuration_id: number;
    nom: string;

    pays: Pays;
    pays_name: string;
    
    metadonnees: MetadonneeBranche[];
    
    departements: Departement[];
    departements_name: string;
}

export class Branche extends Base implements IBranche {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    pays_id: number = 0;
    
    @JsonProperty()
    branche_id: number = 0;
    
    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    pays!: Pays;
    pays_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeBranche[] = [];

    @JsonProperty()
    departements: Departement[] = [];
    departements_name: string = "";

    public constructor(init?: Partial<IBranche>) {
        super();
        Object.assign(this, init);
    }
}
