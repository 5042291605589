import { JsonProperty } from "@paddls/ts-serializer";
import Enumeration from "../../utils/Enumeration";

export interface IPersonnelWorkflowConfigurationPivot {
    personnel_id: number;
    workflow_configuration_id: number;
    
    consigne: string;
    periode: string;
    duree: number;
    position: number;
}

export class PersonnelWorkflowConfigurationPivot implements IPersonnelWorkflowConfigurationPivot {
    @JsonProperty()
    personnel_id: number = 0;

    @JsonProperty()
    workflow_configuration_id: number = 0;
    
    @JsonProperty()
    consigne: string = "";
    
    @JsonProperty()
    periode: string = Enumeration.PERIODE_JOUR;

    @JsonProperty()
    duree: number = 0;

    @JsonProperty()
    position: number = 0;

    public constructor(init?: Partial<IPersonnelWorkflowConfigurationPivot>) {
        Object.assign(this, init);
    }
}
