import { JsonProperty } from "@paddls/ts-serializer";
import { IPersonnel, Personnel } from "./Personnel";
import { PersonnelDocumentPivot } from "./PersonnelDocumentPivot";

export interface IPersonnelDocument extends IPersonnel {
    pivot: PersonnelDocumentPivot;
}

export class PersonnelDocument extends Personnel implements IPersonnelDocument {
    @JsonProperty()
    pivot: PersonnelDocumentPivot = new PersonnelDocumentPivot();

    public constructor(init?: Partial<IPersonnelDocument>) {
        super();
        Object.assign(this, init);
    }
}
