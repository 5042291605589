import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Confidentialite } from "../central/Confidentialite";
import { Utilisateur } from "../Utilisateur";
import { Approbation } from "./Approbation";
import { Configuration } from "./Configuration";
import { Dossier } from "./Dossier";
import { Groupe } from "./Groupe";
import { MetadonneeDocument } from "./MetadonneeDocument";
import { Partage } from "./Partage";
import { Personnel } from "./Personnel";
import { Profil } from "./Profil";
import { Version } from "./Version";
import { Workflow } from "./Workflow";

export interface IDocument extends IBase {
    id: number;
    user_id: number;
    confidentialite_id: number;
    dossier_id: number;
    configuration_id: number;
    nom: string;

    version: Version;
    // hash_name: string;
    // extension: string;
    // size: string;
    // url: string;
    // contents: string;
    // size_display: string;

    user: Utilisateur;
    user_name: string;

    personnel: Personnel;
    personnel_name: string;

    confidentialite: Confidentialite;
    confidentialite_name: string;
    confidentialite_code: string;

    dossier: Dossier;
    path_id: string;
    path_name: string;
    dossier_name: string;

    configuration: Configuration;
    configuration_name: string;

    metadonnees: MetadonneeDocument[];

    personnels: Personnel[];
    personnels_ids: number[];
    
    profils: Profil[];
    profils_ids: number[];
    
    groupes: Groupe[];
    groupes_ids: number[];

    versions: Version[];

    partages: Partage[];
    
    approbations: Approbation[];

    workflows: Workflow[];
    workflow_statut_code: string;
    workflow_statut_label: string;

    is_owner: boolean;
    is_private: boolean;
    is_public: boolean;
    is_shared: boolean;

    fichiers: File[];
}

export class Document extends Base implements IDocument {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    user_id: number = 0;
    
    @JsonProperty()
    confidentialite_id: number = 0;
    
    @JsonProperty()
    dossier_id: number = 0;

    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    version!: Version;
    // hash_name: string = "";
    // extension: string = "";
    // size: string = "";
    // url: string = "";
    // contents: string = "";
    // size_display: string = "";

    @JsonProperty()
    user!: Utilisateur;
    user_name: string = "";

    @JsonProperty()
    personnel!: Personnel;
    personnel_name: string = "";

    @JsonProperty()
    confidentialite!: Confidentialite;
    confidentialite_name: string = "";
    confidentialite_code: string = "";

    @JsonProperty()
    dossier!: Dossier;
    path_id: string = "0";
    path_name: string = "";
    dossier_name: string = "";

    @JsonProperty()
    configuration!: Configuration;
    configuration_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeDocument[] = [];

    @JsonProperty()
    personnels: Personnel[] = [];
    personnels_ids: number[] = [];

    @JsonProperty()
    profils: Profil[] = [];
    profils_ids: number[] = [];

    @JsonProperty()
    groupes: Groupe[] = [];
    groupes_ids: number[] = [];

    @JsonProperty()
    versions: Version[] = [];

    @JsonProperty()
    partages: Partage[] = [];
    
    @JsonProperty()
    approbations: Approbation[] = [];
    
    @JsonProperty()
    workflows: Workflow[] = [];
    workflow_statut_code: string = "";
    workflow_statut_label: string = "";

    is_owner: boolean = false;
    is_private: boolean = false;
    is_public: boolean = false;
    is_shared: boolean = false;

    fichiers: File[] = [];

    public constructor(init?: Partial<IDocument>) {
        super();
        Object.assign(this, init);
    }
}
