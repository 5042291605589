import { JsonProperty } from "@paddls/ts-serializer";
import { Base, IBase } from '../Base';
import { Confidentialite } from "../central/Confidentialite";
import { Utilisateur } from "../Utilisateur";
import { Configuration } from './Configuration';
import { Document } from "./Document";
import { Groupe } from './Groupe';
import { MetadonneeDossier } from "./MetadonneeDossier";
import { Personnel } from "./Personnel";
import { Profil } from './Profil';

export interface IDossier extends IBase {
    id: number;
    user_id: number;
    confidentialite_id: number;
    dossier_id: number;
    configuration_id: number;
    nom: string;

    user: Utilisateur;
    user_name: string;

    personnel: Personnel;
    personnel_name: string;
    
    confidentialite: Confidentialite;
    confidentialite_name: string;
    confidentialite_code: string;

    dossier: Dossier;
    path_id: string;
    path_name: string;
    dossier_name: string;
    
    configuration: Configuration;
    configuration_name: string;

    metadonnees: MetadonneeDossier[];

    dossiers: Dossier[];
    documents: Document[];

    personnels: Personnel[];
    personnels_ids: number[];

    profils: Profil[];
    profils_ids: number[];

    groupes: Groupe[];
    groupes_ids: number[];

    is_owner: boolean;
    is_private: boolean;
    is_public: boolean;
    is_shared: boolean;
}

export class Dossier extends Base implements IDossier {
    [key: string]: any;

    @JsonProperty()
    id: number = 0;
    
    @JsonProperty()
    user_id: number = 0;
    
    @JsonProperty()
    confidentialite_id: number = 0;
    
    @JsonProperty()
    dossier_id: number = 0;
    
    @JsonProperty()
    configuration_id: number = 0;

    @JsonProperty()
    nom: string = "";

    @JsonProperty()
    user!: Utilisateur;
    user_name: string = "";

    @JsonProperty()
    personnel!: Personnel;
    personnel_name: string = "";

    @JsonProperty()
    confidentialite!: Confidentialite;
    confidentialite_name: string = "";
    confidentialite_code: string = "";

    @JsonProperty()
    dossier!: Dossier;
    path_id: string = "0";
    path_name: string = "";
    dossier_name: string = "";

    @JsonProperty()
    configuration!: Configuration;
    configuration_name: string = "";

    @JsonProperty()
    metadonnees: MetadonneeDossier[] = [];

    @JsonProperty()
    dossiers: Dossier[] = [];

    @JsonProperty()
    documents: Document[] = [];

    @JsonProperty()
    personnels: Personnel[] = [];
    personnels_ids: number[] = [];

    @JsonProperty()
    profils: Profil[] = [];
    profils_ids: number[] = [];

    @JsonProperty()
    groupes: Groupe[] = [];
    groupes_ids: number[] = [];

    is_owner: boolean = false;
    is_private: boolean = false;
    is_public: boolean = false;
    is_shared: boolean = false;

    public constructor(init?: Partial<IDossier>) {
        super();
        Object.assign(this, init);
    }
}
