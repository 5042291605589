import { JsonProperty } from "@paddls/ts-serializer";

export interface IMetadonneeDossierPivot {
    metadonnee_id: number;
    dossier_id: number;
    valeur: string;
}

export class MetadonneeDossierPivot implements IMetadonneeDossierPivot {
    [key: string]: any;
    
    @JsonProperty()
    metadonnee_id: number = 0;

    @JsonProperty()
    dossier_id: number = 0;

    @JsonProperty()
    valeur: string = "";

    public constructor(init?: Partial<IMetadonneeDossierPivot>) {
        Object.assign(this, init);
    }
}
