import { JsonProperty } from "@paddls/ts-serializer";

export interface IModuleOrganisationPivot {
    module_id: number;
    tenant_id: number;
}

export class ModuleOrganisationPivot implements IModuleOrganisationPivot {
    @JsonProperty()
    module_id: number = 0;

    @JsonProperty()
    tenant_id: number = 0;

    public constructor(init?: Partial<IModuleOrganisationPivot>) {
        Object.assign(this, init);
    }
}
