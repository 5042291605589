import { t } from "i18next";
import i18n from "../translations/i18n";

const trans = (key: string, options?: any) => {
    if (options)
        return t(key, options).toString();
    
    return t(key);
}

// For using outside of component
const language = i18n.language.split('-')[0];

export { trans, language };
